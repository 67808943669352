<div class="row">

  <div class="authfy-container">
    <!-- <a routerLink="/" class="arrow_homepage"><i class="bx bx-arrow-back "></i>&nbsp;&nbsp; Back to Homepage</a> -->
    <!-- <div class="col-sm-5 authfy-panel-left">
          <div class="brand-col">
            <div class="headline">

              <div class="brand-logo">
                <img src="../../../../assets/img/logo.png" width="150" alt="brand-logo">
              </div>
              <p>Signup using social media to get quick access</p>

              <div class="row social-buttons">
                <div class="col-xs-4 col-sm-4 col-md-12">
                  <a href="#" class="btn btn-block btn-facebook">
                    <i class="fa fa-facebook"></i> <span class="hidden-xs hidden-sm">Signup with facebook</span>
                  </a>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-12">
                  <a href="#" class="btn btn-block btn-twitter">
                    <i class="fa fa-twitter"></i> <span class="hidden-xs hidden-sm">Signup with twitter</span>
                  </a>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-12">
                  <a href="#" class="btn btn-block btn-google">
                    <i class="fa fa-google-plus"></i> <span class="hidden-xs hidden-sm">Signup with google</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> -->
    <div class="col-sm-12 authfy-panel-right">
      <!-- authfy-login start -->
      <div class="authfy-login">
        <!-- panel-login start -->
        <div class="authfy-panel panel-login text-center active">
          <div class="authfy-heading">
            <h3 class="auth-title">Signup to create your account</h3>
            <p>Already have an account? <a class="lnk-toggler" (click)="updateFlag()">Login Now!</a></p>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-12">
              <form name="loginForm" [formGroup]="signupForm" class="loginForm" autocomplete="off">
                <div class="form-group">
                  <input type="text" class="form-control " formControlName="customerName" name="username"
                    placeholder="Username">
                  <div class="text-danger text-left" *ngIf="f.customerName.errors && f.customerName.touched">
                    <small *ngIf="f.customerName.errors.required">*Username is required</small>
                  </div>
                </div>
                <div class="form-group">
                  <input type="email" class="form-control email" formControlName="customerEmail" name="username"
                    placeholder="Email address">
                  <div class="text-danger text-left" *ngIf="f.customerEmail.errors && f.customerEmail.touched">
                    <small *ngIf="f.customerEmail.errors.required">*Email is required</small>
                    <small *ngIf="f.customerEmail.errors.pattern">*Invalid email.</small>
                  </div>
                </div>
                <div class="form-group">
                  <input type="tel" class="form-control email" formControlName="customerMobileNumber" name="phone"
                    placeholder="Phone">
                  <div class="text-danger text-left"
                    *ngIf="f.customerMobileNumber.errors && f.customerMobileNumber.touched">
                    <small *ngIf="f.customerMobileNumber.errors.required">*Phone number is required</small>
                    <small *ngIf="f.customerMobileNumber.errors.pattern">*Invalid phone number.</small>
                  </div>
                </div>
                <div class="form-group">
                  <div class="pwdMask">
                    <input type="password" class="form-control password" formControlName="password" name="password"
                      placeholder="Password">
                    <span class="fa fa-eye-slash pwd-toggle"></span>
                    <div class="text-danger text-left" *ngIf="f.password.errors && f.password.touched">
                      <small *ngIf="f.password.errors.required">*Password is required</small>
                      <small *ngIf="f.password.errors.pattern">*Password must be atleast 8 characters including one
                        alphanumeric one special characters.
                        .</small>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="pwdMask">
                    <input type="password" class="form-control password" formControlName="confirmPassword"
                      name="password" placeholder="Confirm Password">
                    <span class="fa fa-eye-slash pwd-toggle"></span>
                    <div class="text-danger text-left" *ngIf="f.confirmPassword.errors && f.confirmPassword.touched ">
                      <small *ngIf="f.confirmPassword.errors.required">*Confirm Password is required</small>
                    </div>
                    <div class="text-danger text-left"
                      *ngIf="f.confirmPassword.value && signupForm.hasError('notSame')">
                      <small>*Password and confirm password must be same.</small>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <button href="" target="_blank" class="btn btn-lg btn-primary btn-block"
                    [disabled]="signupForm.invalid" (click)="signupCustomer()" type="button">Submit Now</button>

                </div>
              </form>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</div> <!-- ./row -->