<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <h1 title="Products Details">Products Details</h1>
        </div>
    </div>
</section>

<section class="products-details-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="row">
                    <!-- <div class="col-lg-6 col-md-6 col-6">
                        <div class="products-details-image">
                            <a href="assets/img/products-img1.jpg" data-fancybox="gallery">
                                <img [src]="assets/img/products-img1.jpg" alt="image">
                            </a>
                        </div>
                    </div> -->

                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="products-details-image">
                            <a [href]="productDetail?.productImage.url || 'assets/img/products-img2.jpg'" data-fancybox="gallery">
                                <img [src]="productDetail?.productImage.url || 'assets/img/products-img2.jpg'" alt="image">
                            </a>
                        </div>
                    </div>

                    <!-- <div class="col-lg-6 col-md-6 col-6">
                        <div class="products-details-image mb-0">
                            <a href="assets/img/products-img3.jpg" data-fancybox="gallery">
                                <img src="assets/img/products-img3.jpg" alt="image">
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-6">
                        <div class="products-details-image mb-0">
                            <a href="assets/img/products-img4.jpg" data-fancybox="gallery">
                                <img src="assets/img/products-img4.jpg" alt="image">
                            </a>
                        </div>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="products-details-desc">
                    <h3>{{productDetail?.productType?.productType}}</h3>

                    <div class="price">
                        <span class="new-price">AED {{price || '0'}}</span>
                        <span class="old-price">AED {{price || '0'}}</span>
                    </div>

                    <!-- <div class="products-review">
                        <div class="rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star-half'></i>
                        </div>
                        <a href="#" class="rating-count">3 reviews</a>
                    </div> -->

                    <p>{{productDetail?.description}}</p>

                    <div class="products-meta">
                        <span>Size: <span class="sku" *ngFor="let item of productDetail?.price_size_qunatity" (click)="selectSize(item)" [ngClass]="selectedSize == item?.size ? 'active' : ''"
                            >{{item?.size}}</span></span>
                        <span>Availability: <span class="in-stock">{{quantity > 0 ? 'In Stock' : 'Out of Stock'}}</span></span>
                        <span>Category: <a href="#">{{productDetail?.competitionDetail?._id ? 'Competition' : 'Membership'}}</a></span>
                        <!-- <span>Tag: <a href="#">Book</a></span> -->
                    </div>

                    <div class="products-add-to-cart">
                        <div class="input-counter">
                            <span class="minus-btn"  (click)="updateQuantity(1)"><i class='bx bx-minus'></i></span>
                            <input type="text"  [(ngModel)]="item_quantity" min="1" value="1">
                            <span class="plus-btn" (click)="updateQuantity(2)"><i class='bx bx-plus'></i></span>
                        </div>
                        <button type="submit" [disabled]="quantity <= 0" (click)="addToCart(productDetail?._id)" class="default-btn">Add to Cart</button>
                    </div>

                    <div class="products-share">
                        <ul class="social">
                            <li><span>Share:</span></li>
                            <li><a href="#" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="products-details-tabs">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item"><a class="nav-link active" id="description-tab" data-toggle="tab" href="#description" role="tab" aria-controls="description">Description</a></li>
                        <!-- <li class="nav-item"><a class="nav-link" id="reviews-tab" data-toggle="tab" href="#reviews" role="tab" aria-controls="reviews">Reviews (2)</a></li> -->
                    </ul>

                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="description" role="tabpanel">
                            <p>{{productDetail?.description}}</p>
                            <!-- <ul>
                                <li>Instant WideCademy bestseller</li>
                                <li>Translated into 18 languages</li>
                                <li>#1 Most Recommended Book of the year.</li>
                                <li>A neglected project, widely dismissed, its champion written off as unhinged.</li>
                                <li>Yields a negative result in an experiment because of a flaw in the design of the experiment.</li>
                                <li>An Amazon, Bloomberg, Financial Times, Forbes, Inc., Newsweek, Strategy + Business, Tech Crunch, Washington Post Best Business Book of the year</li>
                            </ul> -->
                        </div>

                        <div class="tab-pane fade" id="reviews" role="tabpanel">
                            <div class="products-reviews">
                                <h3>Products Rating</h3>
                                <div class="rating">
                                    <span class="bx bxs-star checked"></span>
                                    <span class="bx bxs-star checked"></span>
                                    <span class="bx bxs-star checked"></span>
                                    <span class="bx bxs-star checked"></span>
                                    <span class="bx bxs-star"></span>
                                </div>
                                <div class="rating-count">
                                    <span>4.1 average based on 4 reviews.</span>
                                </div>
                                <div class="row">
                                    <div class="side">
                                        <div>5 star</div>
                                    </div>
                                    <div class="middle">
                                        <div class="bar-container">
                                            <div class="bar-5"></div>
                                        </div>
                                    </div>
                                    <div class="side right">
                                        <div>02</div>
                                    </div>
                                    <div class="side">
                                        <div>4 star</div>
                                    </div>
                                    <div class="middle">
                                        <div class="bar-container">
                                            <div class="bar-4"></div>
                                        </div>
                                    </div>
                                    <div class="side right">
                                        <div>03</div>
                                    </div>
                                    <div class="side">
                                        <div>3 star</div>
                                    </div>
                                    <div class="middle">
                                        <div class="bar-container">
                                            <div class="bar-3"></div>
                                        </div>
                                    </div>
                                    <div class="side right">
                                        <div>04</div>
                                    </div>
                                    <div class="side">
                                        <div>2 star</div>
                                    </div>
                                    <div class="middle">
                                        <div class="bar-container">
                                            <div class="bar-2"></div>
                                        </div>
                                    </div>
                                    <div class="side right">
                                        <div>05</div>
                                    </div>
                                    <div class="side">
                                        <div>1 star</div>
                                    </div>
                                    <div class="middle">
                                        <div class="bar-container">
                                            <div class="bar-1"></div>
                                        </div>
                                    </div>
                                    <div class="side right">
                                        <div>00</div>
                                    </div>
                                </div>
                            </div>
                            <div class="products-review-comments">
                                <h3>3 Reviews</h3>
                                <div class="user-review">
                                    <img src="assets/img/user1.jpg" alt="image">
                                    
                                    <div class="review-rating">
                                        <div class="review-stars">
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                        </div>

                                        <span class="d-inline-block">James Anderson</span>
                                    </div>

                                    <span class="d-block sub-comment">Excellent</span>
                                    <p>Very well built theme, couldn't be happier with it. Can't wait for future updates to see what else they add in.</p>
                                </div>
                                <div class="user-review">
                                    <img src="assets/img/user2.jpg" alt="image">
                                    <div class="review-rating">
                                        <div class="review-stars">
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                        </div>

                                        <span class="d-inline-block">Sarah Taylor</span>
                                    </div>
                                    <span class="d-block sub-comment">Video Quality!</span>
                                    <p>Was really easy to implement and they quickly answer my additional questions!</p>
                                </div>
                                <div class="user-review">
                                    <img src="assets/img/user3.jpg" alt="image">
                                    <div class="review-rating">
                                        <div class="review-stars">
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                        </div>
                                        <span class="d-inline-block">David Warner</span>
                                    </div>
                                    <span class="d-block sub-comment">Perfect Coding!</span>
                                    <p>Stunning design, very dedicated crew who welcome new ideas suggested by customers, nice support.</p>
                                </div>
                                <div class="user-review">
                                    <img src="assets/img/user4.jpg" alt="image">
                                    <div class="review-rating">
                                        <div class="review-stars">
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star'></i>
                                        </div>
                                        <span class="d-inline-block">King Kong</span>
                                    </div>
                                    <span class="d-block sub-comment">Perfect Video!</span>
                                    <p>Stunning design, very dedicated crew who welcome new ideas suggested by customers, nice support.</p>
                                </div>
                            </div>
                            <div class="review-form-wrapper">
                                <h3>Add a review</h3>
                                <p class="comment-notes">Your email address will not be published. Required fields are marked <span>*</span></p>

                                <form>
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12">
                                            <div class="rating">
                                                <input type="radio" id="star5" name="rating" value="5" /><label for="star5"></label>
                                                <input type="radio" id="star4" name="rating" value="4" /><label for="star4"></label>
                                                <input type="radio" id="star3" name="rating" value="3" /><label for="star3"></label>
                                                <input type="radio" id="star2" name="rating" value="2" /><label for="star2"></label>
                                                <input type="radio" id="star1" name="rating" value="1" /><label for="star1"></label>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Name *">
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <input type="email" class="form-control" placeholder="Email *">
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                                <textarea placeholder="Your review" class="form-control" cols="30" rows="6"></textarea>
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12">
                                            <p class="comment-form-cookies-consent">
                                                <input type="checkbox" id="test1">
                                                <label for="test1">Save my name, email, and website in this browser for the next time I comment.</label>
                                            </p>
                                        </div>

                                        <div class="col-lg-12 col-md-12">
                                            <button type="submit">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="products-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Related Products</h2>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6" *ngFor="let item of productsList">
                <div class="single-products-item">
                    <div class="products-image">
                        <div class="bg-image">
                            <img [src]="item?.productImage.url || 'assets/img/products-shape.png'" alt="image">
                        </div>

                        <img [src]="item?.productImage.url || 'assets/img/products-shape.png'" alt="image" class="main-image">

                        <a (click)="addToCart(item?._id)"  class="add-to-cart-btn">Add To Cart</a>
                    </div>

                    <!-- <span class="hot">Hot!</span> -->

                    <div class="products-content">
                        <h3><a routerLink="/single-products">{{item?.productType?.productType}}</a></h3>
                        <span class="price">${{item?.price_size_qunatity[0].price}}</span>
                    </div>
                </div>
            </div>

            <!-- <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-products-item">
                    <div class="products-image">
                        <div class="bg-image">
                            <img src="assets/img/products-shape.png" alt="image">
                        </div>

                        <img src="assets/img/products-img2.png" alt="image" class="main-image">

                        <a  class="add-to-cart-btn">Add To Cart</a>
                    </div>

                    <span class="sale">Sale!</span>

                    <div class="products-content">
                        <h3><a routerLink="/single-products">Gaming Chair</a></h3>
                        <span class="price">$199.00</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-products-item">
                    <div class="products-image">
                        <div class="bg-image">
                            <img src="assets/img/products-shape.png" alt="image">
                        </div>

                        <img src="assets/img/products-img3.png" alt="image" class="main-image">

                        <a routerLink="/cart" class="add-to-cart-btn">Add To Cart</a>
                    </div>

                    <div class="products-content">
                        <h3><a routerLink="/single-products">Gaming Mouse</a></h3>
                        <span class="price">$69.00</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-products-item">
                    <div class="products-image">
                        <div class="bg-image">
                            <img src="assets/img/products-shape.png" alt="image">
                        </div>

                        <img src="assets/img/products-img4.png" alt="image" class="main-image">

                        <a routerLink="/cart" class="add-to-cart-btn">Add To Cart</a>
                    </div>

                    <div class="products-content">
                        <h3><a routerLink="/single-products">Gaming Pad</a></h3>
                        <span class="price">$299.00</span>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>

<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>