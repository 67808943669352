<div class="table-responsive">
    <table class="table table-bordered">
        <thead>
            <tr class="table-head-bg">
              <th>Lane</th>
              <th>Place</th>
              <th>Team</th>
              <th>Player First Name</th>
              <th>Player Last Name</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of swimmingGameDetailsData?.teamDeatailsOne;let i = index">
              <td>{{data?.scoreData[0]?.lane}}</td>
              <td>{{data?.scoreData[0]?.place}}</td>
              <td>{{data?.teamDetails[0]?.teamName}}</td>
              <td>{{data?.playersDetails[0]?.firstName}}</td>
              <td>{{data?.playersDetails[0]?.lastName}}</td>
              <td>{{data?.scoreData[0]?.time}}</td>
            </tr>
            <tr *ngFor="let data of swimmingGameDetailsData?.teamDeatailsTwo;let i = index">
              <td>{{data?.scoreData[0]?.lane}}</td>
              <td>{{data?.scoreData[0]?.place}}</td>
              <td>{{data?.teamDetails[0]?.teamName}}</td>
              <td>{{data?.playersDetails[0]?.firstName}}</td>
              <td>{{data?.playersDetails[0]?.lastName}}</td>
              <td>{{data?.scoreData[0]?.time}}</td>
            </tr>
     
          </tbody>
    </table>
</div>


