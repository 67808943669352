import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/providers/main-service.service';
import { Router} from '@angular/router'
@Component({
  selector: 'app-membership-list',
  templateUrl: './membership-list.component.html',
  styleUrls: ['./membership-list.component.scss']
})
export class MembershipListComponent implements OnInit {

  memberList : any = []
  constructor(public service : MainService,public router : Router) { }
  isLoginVistUser:any
  widgets:any
  ngOnInit(): void {
    this.isLoginVistUser = this.service.isLoginVisitUser()
    console.log('UUYD',this.isLoginVistUser )
    this.membersListApi(this.service.isRolePlayer)
    this.widgets =  JSON.parse(localStorage.getItem("widgets"))
  }
  membersListApi(isPlayer){
    let input;
    let url;
  if(isPlayer){
    input =  {
      "playerId":this.isLoginVistUser?._id,"page":1,"limit":100}
      url = `membership/getMembership`
  }else{
    let userId = localStorage.getItem('userId')
    url = `membership/getListOfMembership?organizerId=`+userId
  }
   this.service.postApi(url,input,1).subscribe(response => {
    if(response.responseCode == 200) {
     this.memberList = response.result.docs;
    } 
  });
  }

  viewServices(data){
    let  compId = data._id
    var orgId =  data.organizerId[0]._id ?  data.organizerId[0]._id : data.organizerId
      this.router.navigate(['/service-list', compId, orgId])
  }

}
