<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <h1 title="{{'Evaluation'}} ">{{'Evaluation'}} </h1>
        </div>
    </div>
</section>

<main class="main-container pt0 mt-min60">
    <div class="container">
        <div class="content-box bg-gray">
            <h2 class="content-box-head"></h2>
            <div class="content-box-filter">
                <form class="filter-form fullwidth" [formGroup]="filterForm">
                    <div class="row align-items-center">
                        <div class="col-md-2">
                            <div class="filter-box ">
                                <label class="control-label">MemberShip Name</label>
                                <select class="form-control select-style1" formControlName="membershipName" (change)="getMembershipId($event)">
                               <option value="">All</option>
                               <option  *ngFor="let i of memberList"  value="{{i?.membershipName}}">{{i?.membershipName}}</option>
                          </select>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="filter-box">
                                <label class="control-label">Service Name</label>
                                <select class="form-control select-style1" formControlName="serviceName" (change)="getServiceId($event)">
                          <option value="">Select Service</option>
                           <option  *ngFor="let i of servicelist"  value="{{i?.serviceName}}">{{i?.serviceName}}</option>
                         </select>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="filter-box">
                                <label class="control-label">Month Filter</label>
                                <select   class="form-control select-style1" formControlName="serviceDate" (change)="onChangeMonth($event)">
                          <option value="">Select month</option>
                           <option  *ngFor="let i of serviceMonth"  [value]="i?.val">{{i?.name}}</option>
                         </select>
                            </div>
                          
                        </div>
                         <div class="col-md-2">
                            <div class="filter-box">
                                <label class="control-label">Year</label>
                          <select class="form-control select-style1" formControlName="selectYear" (change)="onChangeyear($event)">
                          <option value="">Select year</option>
                           <option  *ngFor="let i of serviceYear "   [value]="i">{{i}}</option>
                         </select>
                            </div>
                        </div>
                        <div class="col-md-2" >
                            <div class="filter-box">
                                <label class="control-label">Player Name</label>
                                <select class="form-control select-style1" formControlName="playerName" (change)="getPlayerId($event)">
                            <option value="">Select Player</option>
                             <option  *ngFor="let i of evaluationList"  value="{{i?.playerId?.firstName}}">{{i?.playerId?.firstName}}</option>
                           </select>
                            </div>
                        </div>
                        <div style="margin-top: 44px;" class="col-md-2">
                            <a  (click)="getLeaderBoard()"  class="small-btn">Apply</a>
                        </div>
                    </div>
                    
                </form>
            </div>
            <div class="table-block mt10 white-bg">
                <div class="table-responsive" style="overflow-x: hidden;">
                    <form class="filter-form fullwidth" [formGroup]="evaluateForm">
                        <div class="row align-items-center">
                            <div class="col-md-4">
                                <div class="filter-box ">
                                    <label class="control-label">Bad</label>
                                    <input type="text" class="form-control" disabled  formControlName="bad">
                                </div>
                              
                            </div>
                            <div class="col-md-4">
                                <div class="filter-box">
                                    <label class="control-label">Pass</label>
                                    <input type="text" class="form-control" disabled formControlName="pass">
                                </div>
                              
                            </div>
                            <div class="col-md-4">
                                <div class="filter-box">
                                    <label class="control-label">Shooting</label>
                                    <input type="text" class="form-control" disabled formControlName="shooting">
                                </div>
                           
                            </div>
                            <div class="col-md-4">
                                <div class="filter-box">
                                    <label class="control-label">Strenght</label>
                                    <input type="text" class="form-control" disabled formControlName="strenght">
                                </div>
                              
                            </div>
                        </div>
                        <div class="row align-items-center">
                            <div class="col-md-4">
                                <div class="filter-box ">
                                    <label class="control-label">Speed</label>
                                    <input type="text" class="form-control" disabled formControlName="speed">
                                </div>
                           </div>
                            <div class="col-md-4">
                                <div class="filter-box">
                                    <label class="control-label">Flexibility</label>
                                    <input type="text" class="form-control" disabled formControlName="flexibility">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="filter-box">
                                    <label class="control-label">Deicison</label>
                                    <input type="text" class="form-control" disabled formControlName="decision">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="filter-box">
                                    <label class="control-label">Offensive</label>
                                    <input type="text" class="form-control" disabled formControlName="offensive">
                                </div>
                            </div>
                        </div>
                        <div class="row align-items-center">
                            <div class="col-md-4">
                                <div class="filter-box ">
                                    <label class="control-label">Concentration</label>
                                    <input type="text" class="form-control" disabled formControlName="concentration">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="filter-box">
                                    <label class="control-label">Competitive</label>
                                    <input type="text" class="form-control" disabled formControlName="competitivenedd">
                                </div>
                           </div>
                            <div class="col-md-4">
                                <div class="filter-box ">
                                    <label class="control-label">SelfConfidence</label>
                                    <input type="text" class="form-control" disabled formControlName="selfConfidence">
                                </div>
                            </div>
                            
                            <div class="col-md-4">
                                <div class="filter-box">
                                    <label class="control-label">Average</label>
                                    <input type="text" class="form-control" disabled formControlName="avg">
                                </div>
                            </div>
                          <div  *ngFor="let dyna of dynamicFormField; let i=index" class="col-md-4">
                                <div class="filter-box">
                                    <label class="control-label">{{dyna?.field}}</label>
                                    <input disabled [value]="dyna.value" type="text" name={{i}}  class="form-control" >
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</main>



<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>


<script type="text/javascript">
    $(function() {
        $('.date-picker').datepicker({
            changeMonth: true,
            changeYear: true,
            showButtonPanel: true,
            dateFormat: 'MM yy',
            onClose: function(dateText, inst) {
                $(this).datepicker('setDate', new Date(inst.selectedYear, inst.selectedMonth, 1));
            }
        });
    });
</script>