<app-navbar-style-one></app-navbar-style-one>
<section class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <h1 title="Media">  Media</h1>
        </div>
    </div>
</section>

<section class="match-details-area ptb-100">
 
<div class="container">
        <div class="row">
            <app-media-competition (DiocompetitionId)="competitionId"></app-media-competition>
          
        </div>
    </div> 
</section>

<section class="subscribe-area pb-100">
    <app-subscribe-style-one></app-subscribe-style-one>
</section>

<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>