import { Component, Input, OnInit } from '@angular/core';
import { MainService } from 'src/app/providers/main-service.service';

@Component({
  selector: 'basketball-report',
  templateUrl: './basketball-report.component.html',
  styleUrls: ['./basketball-report.component.scss']
})
export class BasketballReportComponent implements OnInit {
  @Input() team1Id : any
  @Input() team2Id : any
  @Input() _gameId : any
  constructor(private service : MainService) { }

  ngOnInit(): void {
    this.basketballGameDetails(this.team1Id,this.team2Id,this._gameId)
  }

basketballGameDetailsData : any
playerOne : any
playerTwo : any
finalScoreOne : any
finalScoreTwo : any
scoreArrayOne : any
scoreArrayTwo : any
basketballGameDetails(team1Id,team2Id,_gameId){
  
  this.service.spinnerShow();
  let apireq = {
       teams: [team1Id,team2Id] ,
       gameId: _gameId
  }
  this.service.postApi('game/basketballGameDetails', apireq , 0).subscribe(success => {
    if (success.responseCode === 200) {
      this.basketballGameDetailsData = success;
      this.scoreArrayOne =  this.basketballGameDetailsData.result.teamDeatailsOne[0].scoreData;
      this.scoreArrayTwo = this.basketballGameDetailsData.result.teamDeatailsTwo[0].scoreData;
      this.finalScoreOne = this.basketballGameDetailsData.result.teamDeatailsOne[0].finalScore;
      this.finalScoreTwo = this.basketballGameDetailsData.result.teamDeatailsTwo[0].finalScore;
      this.playerOne = this.basketballGameDetailsData.result.teamDeatailsOne[0].mvp;
      this.playerTwo = this.basketballGameDetailsData.result.teamDeatailsTwo[0].mvp;
      // console.log("success score of basketBallGameDetails ======>",this.scoreArrayOne,this.scoreArrayTwo);
      this.service.toastrSucc(success.responseMessage);
    } 
    this.service.spinnerHide();
  }, error => {
    this.service.spinnerHide();
    this.service.toastrErr(error);
  });
}

}
