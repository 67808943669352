<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <h1 title="Membership">Membership</h1>
        </div>
    </div>
</section>

<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="background">
                    <div class="">
                      <div class="panel membership-table">
                        
                        <div class="membership-plan">
                          <img src="../../../../assets/img/paper-plane.png" alt="" class="membership-img">
                          <h2 class="membership-header">Personal</h2>
                          <ul class="membership-features">
                            <li class="membership-features-item">Custom domains</li>
                            <li class="membership-features-item">Sleeps after 30 mins of inactivity</li>
                          </ul>
                          <span class="membership-price">Free</span>
                          <a routerLink="/cart" class="default-btn">Sign Up</a>
                        </div>
                        
                        <div class="membership-plan">
                          <img src="../../../../assets/img/plane.png" alt="" class="membership-img">
                          <h2 class="membership-header">Small team</h2>
                          <ul class="membership-features">
                            <li class="membership-features-item">Never sleeps</li>
                            <li class="membership-features-item">Multiple workers for more powerful apps</li>
                          </ul>
                          <span class="membership-price">$150</span>
                          <a routerLink="/cart" class="default-btn">Sign Up</a>
                        </div>
                        
                        <div class="membership-plan">
                          <img src="../../../../assets/img/space-ship.png" alt="" class="membership-img">
                          <h2 class="membership-header">Enterprise</h2>
                          <ul class="membership-features">
                            <li class="membership-features-item">Dedicated</li>
                            <li class="membership-features-item">Simple horizontal scalability</li>
                          </ul>
                          <span class="membership-price">$400</span>
                          <a routerLink="/cart" class="default-btn">Sign Up</a>
                        </div>
                        
                      </div>
                    </div>
                  </div>
            </div>

        
        </div>
    </div>
</section>

<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>