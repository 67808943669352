<div class="container">
    <div class="section-title">
        <span class="sub-title">Shop</span>
        <h2>Our Gaming Products</h2>
    </div>

    <div class="products-slides owl-carousel owl-theme">
        <div class="single-products-item">
            <div class="products-image">
                <div class="bg-image">
                    <img src="assets/img/products-shape.png" alt="image">
                </div>

                <img src="assets/img/products-img1.png" alt="image" class="main-image">

                <a routerLink="/" class="add-to-cart-btn">Add To Cart</a>
            </div>

            <div class="products-content">
                <h3><a routerLink="/single-products">Gaming Headphone</a></h3>
                <span class="price">$99.00</span>
            </div>
        </div>

        <div class="single-products-item">
            <div class="products-image">
                <div class="bg-image">
                    <img src="assets/img/products-shape.png" alt="image">
                </div>

                <img src="assets/img/products-img2.png" alt="image" class="main-image">

                <a routerLink="/" class="add-to-cart-btn">Add To Cart</a>
            </div>

            <div class="products-content">
                <h3><a routerLink="/single-products">Gaming Chair</a></h3>
                <span class="price">$199.00</span>
            </div>
        </div>

        <div class="single-products-item">
            <div class="products-image">
                <div class="bg-image">
                    <img src="assets/img/products-shape.png" alt="image">
                </div>

                <img src="assets/img/products-img3.png" alt="image" class="main-image">

                <a routerLink="/" class="add-to-cart-btn">Add To Cart</a>
            </div>

            <div class="products-content">
                <h3><a routerLink="/single-products">Gaming Mouse</a></h3>
                <span class="price">$69.00</span>
            </div>
        </div>

        <div class="single-products-item">
            <div class="products-image">
                <div class="bg-image">
                    <img src="assets/img/products-shape.png" alt="image">
                </div>

                <img src="assets/img/products-img4.png" alt="image" class="main-image">

                <a routerLink="/" class="add-to-cart-btn">Add To Cart</a>
            </div>

            <div class="products-content">
                <h3><a routerLink="/single-products">Gaming Pad</a></h3>
                <span class="price">$299.00</span>
            </div>
        </div>

        <div class="single-products-item">
            <div class="products-image">
                <div class="bg-image">
                    <img src="assets/img/products-shape.png" alt="image">
                </div>

                <img src="assets/img/products-img6.png" alt="image" class="main-image">

                <a routerLink="/" class="add-to-cart-btn">Add To Cart</a>
            </div>

            <div class="products-content">
                <h3><a routerLink="/single-products">Real Headphone</a></h3>
                <span class="price">$59.00</span>
            </div>
        </div>
    </div>
</div>