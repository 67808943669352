<div class="container">
    <div class="section-title">
        <span class="sub-title">Shop</span>
        <h2>Our Products</h2>
    </div>

    <div class="row">

        <div *ngFor="let item of productsList" class="col-lg-3 col-md-4 col-sm-6">
            <div class="single-products-box">
                <div class="products-image">
                    <a routerLink="/single-products" [queryParams]="{'_id' : item?._id}" class="d-block">
                        <img src="{{ item?.productImage?.url }}" alt="image" class="img-responsive"
                        style="max-height: 250px;min-height: 250px;"></a>
                    <a (click)="addToCart(item?._id,item?.price_size_qunatity[0].size,item?.price_size_qunatity[0].price)"  class="add-to-cart-btn">Add To Cart</a>
                </div>
                <div class="products-content">
                    <h3><a routerLink="/single-products">{{ item?.productType?.productType }}</a></h3>
                    <span class="price">AED {{ item?.price_size_qunatity[0]?.price }}</span>
                </div>
            </div>
        </div>   
        <div style="width: 100%" *ngIf="!productsList?.length">
            <h3 class="text-center">No Products Found</h3>
        </div>

    </div>

</div>