<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <h1 title="Membership List">Membership List</h1>
        </div>
    </div>
</section>

<section class="fixtures-area pt-100 pb-70">
    <div class="container">
        <div class="table-responsive">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Sr.No.</th>
                        <!-- <th>MemberShip Image</th> -->
                      
                        <th>MemberShip Name</th>
                        <th>Club Name</th>
                        <th>Status</th>
                        <th>Action</th>
          
                 
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of memberList;let i = index" >
                        <td>{{i+1}}</td>
                        <!-- <td><img [src]="item?.imageURL" style="height: 98px;" alt="MemberShip Image" /></td> -->
        
                        <td>{{item?.membershipName}}</td>
                        <td>{{item?.clubName}}</td>
                        <!-- <td>{{item?.status}}</td> -->
                        <td>{{item?.playerFollowStatus!='NOT FOLLOWED' ? 'Following' : 'Follow'}}</td>
                        <td >
                         
                            <ng-container *ngIf="widgets?.services">
                                <a *ngIf="item?.playerFollowStatus!='NOT FOLLOWED'"  (click)="viewServices(item)"  class="small-btn">View Service</a>
                                <a  *ngIf="item?.playerFollowStatus == 'NOT FOLLOWED'"  class="small-btn">NOT FOLLOWED</a>
                            </ng-container>
                          
                        </td>


                    </tr>
                </tbody>
            </table>
          </div>
    </div>
</section>



<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>