import { Component, Input,OnInit } from '@angular/core';
import { MainService } from 'src/app/providers/main-service.service';

@Component({
  selector: 'app-partner-cmp',
  templateUrl: './partner-cmp.component.html',
  styleUrls: ['./partner-cmp.component.scss']
})
export class PartnerForCMPComponent implements OnInit {
  @Input() compID : any
  constructor(private service: MainService) { }

  userId: any;
  sponsorsList: any = [];

  ngOnInit(): void {
    this.getSponsorsList();
  }

  getSponsorsList(){
    this.userId = this.service.getStorage('userId');
    this.service.getApi(`data/listOfSponsorByUserId?userId=${this.userId}`,0).subscribe(response => {
      if(response.responseCode == 200 && response.result.length>0) {    
        if(this.compID){
          let sponList =[]
          response.result.forEach(iok => {
            if(iok.visibleIn.length>0){
               let d = iok.visibleIn.filter(such=>this.compID === such._id)
               if(d.length>0){
                sponList.push(iok)
               }
            }
          });
          this.sponsorsList = sponList
        }else{
        this.sponsorsList = response.result;

        }
      }
    })
  }
  openTab(link){
    window.open(link,'_blank')
  }

}
