import { MainService } from 'src/app/providers/main-service.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input ,EventEmitter , Output} from '@angular/core';
// import { t } from '@angular/core/src/render3';

@Component({
  selector: 'app-group-report-soccer',
  templateUrl: './match-report-soccer.component.html',
  styleUrls: ['./match-report-soccer.component.css']
})
export class GroupReportSoccerComponent implements OnInit {

  @Output() MessageEvent = new EventEmitter<string>();
  @Input() gameData: any;

  @Input() team1Id : any
  @Input() team2Id : any
  @Input() _gameId : any

  @Input() groupId: any;
  @Input() roundId: any;
  paramData: any;
  soccerGameDetailsData: any;
  currentUrl: string;
  observation: any;
  observationMessage: string;
  messageFromObservation: any;
  // _id: any;

  constructor(
    public service: MainService,
    public activatedRoute: ActivatedRoute
  ) { 
    this.currentUrl = window.location.href;
  }

  ngOnInit() {
    this.service.isSingalMatchUpdateSubject.subscribe(isUpdate=>{
      this.soccerEditGame()
    })
    setInterval(()=>{
      this.observation =  JSON.parse(localStorage.getItem('observation'));
    },1000)

    setTimeout(() => {
      console.log('gameData  From soccer page soccer ===>>>', this.gameData);
      this.service.spinnerHide();
      this.getObservation();
    }, 500);
  }


  //Get Observation
  getObservation(){
    this.service.spinnerShow();
    let apireq = {
      "gameId":this.soccerGameDetailsData.result.teamDeatailsOne[0].gameId,
    }

    this.service.postApi('game/getObservationGame',apireq,1).subscribe(success=>{
      if(success.responseCode == 200 && success.result && success.result.observation){
         this.messageFromObservation = success.result.observation
         this.observationMessage = success.result.observation;
        //  this._id = success.result.docs[0]._id;
        this.service.spinnerHide();
        this.service.toastrSucc(success.responseMessage);
      }
    },error=>{
      this.service.spinnerHide();
      this.service.toastrErr(error);
    })
  }

  //Add Observation or Upload Observation 
  addObservation(){
    this.service.spinnerShow();
    let apireq = {
      "gameId": this.soccerGameDetailsData.result.teamDeatailsOne[0].gameId,
      "observation":this.observationMessage
    }
    this.service.postApi('game/soccerGameObservationGame',apireq,1).subscribe(success=>{
      if( success.responseCode == 200 ){
        this.service.spinnerHide();
        this.getObservation();
        this.service.toastrSucc(success.responseMessage);
      }
    },error=>{
      this.service.spinnerHide();
      this.service.toastrErr(error);
    })
  } 


  //Update Observation
  updateObservation(){
    this.service.spinnerShow()
    let apireq = {
          "gameId": this.soccerGameDetailsData.result.teamDeatailsOne[0].gameId,
          "observation":this.observationMessage
    }
    this.service.postApi('game/updateObservationsGame ',apireq,1).subscribe(success=>{
      if( success.responseCode == 200){
        this.service.spinnerHide();
        this.service.toastrSucc(success.responseMessage);
        this.getObservation();
      }
    },error=>{
      this.service.spinnerHide();
      this.service.toastrErr(error);      
    })
  }


   // Soccer game details by suraj
   soccerEditGame() {
    this.service.spinnerShow();
    let apireq = {
         teams: [this.team1Id,this.team2Id] ,
         gameId: this._gameId,
         type:1,
         groupId: this.groupId,
         roundId: this.roundId,
    };
    // console.log("apireq======>",apireq);
    this.service.postApi('game/soccerEditGame_group', apireq , 0).subscribe(success => {
      if (success.responseCode === 200) {
        this.soccerGameDetails()
      } 
      this.service.spinnerHide();
    }, error => {
      this.service.spinnerHide();
      this.service.toastrErr(error);
    });
  }

 sendUrl(){
   this.MessageEvent.emit(this.currentUrl)
 }

  soccerGameDetails(){
    this.service.spinnerShow();
    let apireq = {
         teams: [this.team1Id,this.team2Id] ,
         gameId: this._gameId,
         groupId: this.groupId,
         roundId: this.roundId,
    };
    this.service.postApi('game/soccerGameDetails_group', apireq , 0).subscribe(success => {
      if (success.responseCode === 200) {
        this.soccerGameDetailsData = success;
      } 
      this.service.spinnerHide();
    }, error => {
      this.service.spinnerHide();
      this.service.toastrErr(error);
    });
  }
  createTable1(){
    if(this.soccerGameDetailsData.result.teamOne.length==0){
          this.soccerGameDetailsData.result.teamOne[0]={
            'playerNumber':1,
            'goals':0,
            'shots':0,
            'yellowCards':0,
            'redCards':0,
          }
        }
  }
    createTable2(){
         if(this.soccerGameDetailsData.result.teamTwo.length==0){
          this.soccerGameDetailsData.result.teamTwo[0]={
            'playerNumber':1,
            'goals':0,
            'shots':0,
            'yellowCards':0,
            'redCards':0,
          }
        }
  }

  //update Team wise Game details
  updatedScore(){
   this.service.spinnerShow();
    let apireq ={
      "data1":{
        "finalScore": this.soccerGameDetailsData.result.teamDeatailsOne[0].finalScore ,
        "docId": this.soccerGameDetailsData.result.teamDeatailsOne[0]._id,
        "teamId": this.soccerGameDetailsData.result.teamDeatailsOne[0].teamId,
        "mvp": this.soccerGameDetailsData.result.teamDeatailsOne[0].mvp,
        "teamFoul":this.soccerGameDetailsData.result.teamDeatailsOne[0].teamFoul,
        "offSide":this.soccerGameDetailsData.result.teamDeatailsOne[0].offSide,
        "cornerkicks":this.soccerGameDetailsData.result.teamDeatailsOne[0].cornerkicks,
        "goalKeeperSaves":this.soccerGameDetailsData.result.teamDeatailsOne[0].goalKeeperSaves ,             
    },
      "data2":{

        "finalScore": this.soccerGameDetailsData.result.teamDeatailsTwo[0].finalScore ,
        "docId": this.soccerGameDetailsData.result.teamDeatailsTwo[0]._id,
        "teamId": this.soccerGameDetailsData.result.teamDeatailsTwo[0].teamId,
        "mvp": this.soccerGameDetailsData.result.teamDeatailsTwo[0].mvp,
        "teamFoul":this.soccerGameDetailsData.result.teamDeatailsTwo[0].teamFoul,
        "offSide":this.soccerGameDetailsData.result.teamDeatailsTwo[0].offSide,
        "cornerkicks":this.soccerGameDetailsData.result.teamDeatailsTwo[0].cornerkicks,
        "goalKeeperSaves":this.soccerGameDetailsData.result.teamDeatailsTwo[0].goalKeeperSaves ,
      }
    }
    this.service.postApi('game/updateSoccerTeamWise_group',apireq,1).subscribe(success=>{
      if( success.responseCode === 200){
        this.service.spinnerHide();
        this.soccerEditGame();
        this.service.toastrSucc(success.responseMessage)
      }
    },error=>{
      this.service.spinnerHide();
      this.service.toastrErr(error);
    })
  }

  //Update Score Player Wise
  updateScorePlayerWise(data){
    this.service.spinnerShow();
    let apireq:any;
    if(data == 'teamOne'){
      apireq = {
        "array": this.soccerGameDetailsData.result.teamOne,
      }
    }else if(data== 'teamTwo'){
       apireq = {
         "array": this.soccerGameDetailsData.result.teamTwo,
       }
    }
    
    this.service.postApi('game/updateSoccerPlayerWise_group',apireq,1).subscribe(success=>{
      if( success.responseCode === 200 ){
        this.service.spinnerHide();
        this.soccerEditGame();
        this.service.toastrSucc(success.responseMessage);
      }
    },error=>{
      this.service.spinnerHide();
      this.service.toastrErr(error);
    })

  }

//On Cancel the score updation
  onCancel(){
    this.soccerEditGame();
  }

 

}
