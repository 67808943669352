import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/providers/main-service.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-menu-section-details',
  templateUrl: './menu-section-details.component.html',
  styleUrls: ['./menu-section-details.component.scss']
})
export class MenuSectionDetailsComponent implements OnInit {
  menu : any
  subMenu : any
  gameDetail : any
  userId: string;
  sectionsList: any;
  id: any;
  sectionName:any;
  urlSafe: SafeResourceUrl;
  imgUrl: any;
  constructor(private service: MainService, private activatedRoute: ActivatedRoute,public sanitizer: DomSanitizer) {
    this.activatedRoute.queryParams.subscribe((res)=>{
      this.menu=''
      this.id = res.id
      this.getSecondaryMenus(this.id)
    })
   }
   linkSection:any
   linkSectionUrl:any
  ngOnInit(): void {
  }
  async getSecondaryMenus(id) {
    this.userId = localStorage.getItem("userId");
    let response = await this.service.getApi(`configuration_domain/getAllSectionWithoutPagination?userId=${this.userId}`, 0).toPromise()
    if (response.responseCode == 200 && response.success.length>0 ) {
          let dat = response.success.find(ita=> ita._id === id )
          this.sectionsList = dat.content
          console.log(dat)
          this.imgUrl = dat.image
          this.linkSection =dat.link=="" ? false : true
          this.linkSectionUrl =dat.link 
          this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.linkSectionUrl);
          this.sectionName = dat.menu ? dat.menu : dat.sectionName

    }

}
openSite(linkSectionUrl){
  window.open(linkSectionUrl,'_blank')
}
  
}
