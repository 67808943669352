import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/providers/main-service.service';

@Component({
  selector: 'app-fixtures-page',
  templateUrl: './fixtures-page.component.html',
  styleUrls: ['./fixtures-page.component.scss']
})
export class FixturesPageComponent implements OnInit {

  competitionList : any = []
  constructor(public service : MainService) { }
  ngOnInit(): void {
    this.getCompetitionList()
  }
  getCompetitionList() {
    const data = {
        filterFields : {
            followStatus : 'APPROVED'
        },
        userId : localStorage.getItem('currentId')
    }
    this.service.postApi(`player/competition/filterCompetitions`,data,1)
        .subscribe((response) => {
            if (response.responseCode == 200) {
                this.competitionList = response.result.docs;
            }
        });
}
 getDate(date,type){
  if(type == 'month'){
    return this.getMonthName(new Date(date).getMonth())
  }
  else if(type == 'date'){
    return new Date(date).getDate()
  }
  else if(type == 'hour'){
    return new Date(date).getHours()
  }
  else if(type == 'minute'){
    return new Date(date).getMinutes()

  }
  return 
}
getMonthName(num : number){
  if(num == 0){
    return 'Jan'
  }
  else if(num == 1){
    return 'Feb'
  }
  else if(num == 2){
    return 'Mar'
  }
  else if(num == 3){
    return 'Apr'
  }
  else if(num == 4){
    return 'May'
  }
  else if(num == 5){
    return 'June'
  }
  else if(num == 6){
    return 'Jul'
  }
  else if(num == 7){
    return 'Aug'
  }
  else if(num == 8){
    return 'Sept'
  }
  else if(num == 9){
    return 'Oct'
  }
  else if(num == 10){
    return 'Nov'
  }
  else if(num == 11){
    return 'Dec'
  }
 
}
}
