import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/providers/main-service.service';

@Component({
  selector: 'my-app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class signupComponent implements OnInit {
  @Output() flagType = new EventEmitter<any>()
  signupForm : FormGroup
  constructor(private service: MainService,private router : Router,private activatedRoute : ActivatedRoute) { }

  ngOnInit(): void { 
    this.validationForm()
  }
  validationForm(){
    this.signupForm = new FormGroup({
      customerName : new FormControl('Aditya',[Validators.required]),
      customerEmail : new FormControl('aditya@mailinator.com',[Validators.required,Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      customerMobileNumber : new FormControl('9717860774',[Validators.required,Validators.pattern('^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$')]),
      password :  new FormControl('Mobiloitte@1',[Validators.required,Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i)]),
      confirmPassword : new FormControl('Mobiloitte@1',[Validators.required,(c: AbstractControl) => Validators.required(c)]),
    }, { validators: this.checkPasswords })

 
    
  }
  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => { 
    let pass = group.get('password').value;
    let confirmPass = group.get('confirmPassword').value
    return pass === confirmPass ? null : { notSame: true }
  }
  get f() { return this.signupForm.controls; }
  signupCustomer(){
    let userId = this.service.getStorage('userId');
    let url = `users/customerSignup`
    const data = {
      primaryUserId : userId,
      customerName : this.signupForm.value.customerName,
      customerEmail : this.signupForm.value.customerEmail,
      customerMobileNumber : this.signupForm.value.customerMobileNumber,
      password : this.signupForm.value.password,
    }
    this.service.postApi(url,data,0).subscribe(response => {
      if(response.responseCode == 200) { 
        this.updateFlag()
       }
    }
    )}

    updateFlag(){
      this.flagType.emit('login')
    }
  
}
