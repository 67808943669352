<div class="" *ngIf="flag == 'login'">
  <div class="row">
    <div class="authfy-container">
      <div class="col-sm-12 authfy-panel-right">
        <!-- authfy-login start -->
        <div class="authfy-login">
          <!-- panel-login start -->
          <div class="authfy-panel panel-login text-center active">
            <div class="authfy-heading">
              <h3 class="auth-title">Login to your account</h3>
              <!-- <p>Don’t have an account? <a class="lnk-toggler" (click)="flag = 'signup'" data-panel="">Sign Up Free!</a></p> -->
            </div>
            <div class="row">
              <div class="col-xs-12 col-sm-12">
                <form [formGroup]="loginForm" class="loginForm">
                  <div class="form-group">
                    <input type="email" class="form-control email" formControlName="customerEmail" name="username"
                      placeholder="Email address">
                    <div class="text-danger text-left" *ngIf="f.customerEmail.errors && f.customerEmail.touched">
                      <small *ngIf="f.customerEmail.errors.required">*Email is required</small>
                      <small *ngIf="f.customerEmail.errors.pattern">*Invalid email.</small>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="pwdMask">
                      <input type="password" class="form-control password" formControlName="password" name="password"
                        placeholder="Password">
                      <span class="fa fa-eye-slash pwd-toggle"></span>
                      <div class="text-danger text-left" *ngIf="f.password.errors && f.password.touched">
                        <small *ngIf="f.password.errors.required">*Password is required</small>
                        <!-- <small *ngIf="f.password.errors.pattern">*Password must be atleast 8 characters including one
                          alphanumeric one special characters.
                          .</small> -->
                      </div>
                      <div class="text-danger text-left">
                        <small *ngIf="failleAattempts">{{failMsg}}</small>
                      </div>
                    </div>

                  </div>
                  <!-- start remember-row -->
                  <div class="row remember-row">
                    <div class="col-xs-6 col-sm-6 text-left">
                      <label class="checkbox text-left">
                        <input type="checkbox" formControlName="rememberMe" value="remember-me">
                        <span class="label-text">Remember me</span>
                      </label>
                    </div>
                    <div class="col-xs-6 col-sm-6">
                      <p class="forgotPwd">
                        <!-- <a class="lnk-toggler" (click)="flag = 'password'" data-panel=".panel-forgot">Forgot
                          password?</a> -->
                      </p>
                    </div>
                  </div> <!-- ./remember-row -->
                  <div class="form-group">
                    <button class="btn btn-lg btn-primary btn-block" [disabled]="loginForm.invalid" (click)="onLogin()"
                      type="button">Submit Now</button>

                  </div>
                </form>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div> 
</div>

<my-app-signup *ngIf="flag == 'signup'" (flagType)="flag = $event"></my-app-signup>
<app-forgot-password *ngIf="flag == 'password'" (flagType)="flag = $event"></app-forgot-password>

