import { Component, OnInit } from "@angular/core";
import { MainService } from "src/app/providers/main-service.service";

@Component({
    selector: "app-main-home",
    templateUrl: "./main-home.component.html",
    styleUrls: ["./main-home.component.scss"],
})
export class MainHomeComponent implements OnInit {
    userId: any;
    widgets: any;
    userDet: any = [];

    constructor(private service: MainService) {}

    ngOnInit(): void {
        this.userId = localStorage.getItem("userId");

        if (this.userId != null && this.userId != "") {
            this.service
                .getApi(`users/getUserDetailWithoutToken?_id=${this.userId}`, 0)
                .subscribe((response) => {
                    localStorage.setItem(
                        "userDetailYala",
                        JSON.stringify(response.result)
                    );
                    this.userDet = response.result;
                });

            this.service
                .getApi(
                    `configuration_domain/getWidgets?userId=${this.userId}`,
                    0
                )
                .subscribe((response) => {
                    localStorage.setItem(
                        "widgets",
                        JSON.stringify(response.result[0])
                    );
                    this.widgets = response.result[0];
                });
        }
    }
}
