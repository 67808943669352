<!-- <div class="home-slides owl-carousel owl-theme">
    
    <div class="single-banner-item banner-bg1" >
        <div class="container-fluid">
            <div class="row align-items-center" >
                <div class="col-lg-5 col-md-12">
                    <div class="main-banner-content">
                        <span class="sub-title">Enjoy The Game</span>
                       
                        <h2 class="main_text">Game Title</h2>
                        <h6>Available Now</h6>
                        <div class="btn-box">
                            <a routerLink="/" class="default-btn">Purchase Now</a>
                            <a routerLink="/contact" class="optional-btn">Free Trial</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="main-banner-image" [ngClass]="{active:isFirst}" *ngFor="let item of sliderArr;let i =index;first as isFirst">
                        <img [src]="item.image" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<div class="container1">
    <div class="row ">
      <div class="col-md-12 py-3" style="margin-top: -1rem;">
        <ngb-carousel *ngIf="sliderArr">
          <ng-template ngbSlide *ngFor="let event of sliderArr">
            <img [src]="event.image" alt="Random slide">
          </ng-template>
        </ngb-carousel>
      </div>
    </div>
  </div>