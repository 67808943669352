<ul class="nav nav-tabs mt-3" id="myTab" role="tablist">
    <li class="nav-item" role="presentation"><a class="nav-link" (click)="getActiveResult('result1')" [ngClass]="acctiveTab == 'result1'? 'active':''"
            id="result1-result-tab" >
            Match </a></li>
    <li class="nav-item" role="presentation"><a class="nav-link"
            data-toggle="tab"   [ngClass]="acctiveTab == 'result2'? 'active':''"
           >
            Statistics</a></li>
    <li class="nav-item" role="presentation"><a class="nav-link" [ngClass]="acctiveTab == 'result3'? 'active':''">
            Observation</a></li>
</ul>
<main *ngIf="acctiveTab === 'result1'" class="main-container pt0 mt-min60">
    <div class="container">
        <div class="content-box bg-gray">
            <div style="box-shadow: 0 4px 20px #929191;margin-top: 20px;display: flex;overflow: scroll;"
                id="tablerecords" *ngIf="grouporcup == 'cup'">

                <div class="horizontalClass0" *ngFor="let data of cupTeamData;let i = index">
                    <div [ngClass]="i==0 ? 'marginLeft30':'marginLeft60'" style="margin-top: 30px;">
                        <label *ngIf="data.length == 64">64th round</label>
                        <label *ngIf="data.length == 32">32th round</label>
                        <label *ngIf="data.length == 16">16th round</label>
                        <label *ngIf="data.length == 8">8th round</label>
                        <label *ngIf="data.length == 4">Quarter Final</label>
                        <label *ngIf="data.length == 2">Semi Final</label>
                        <label *ngIf="data.length == 1">Final</label>
                        <div
                            *ngIf="(data[0]?.startDate && data[0]?.startDate != '1970-01-01T05:30:00.000Z') || (data[0]?.endDate && data[0]?.endDate != '1970-01-01T05:30:00.000Z')">
                            (<span
                                *ngIf="data[0]?.startDate && data[0]?.startDate != '1970-01-01T05:30:00.000Z'">{{data[0]?.startDate
                                | date:'mediumDate'}}</span>
                            <span *ngIf="data[0]?.endDate && data[0]?.endDate != '1970-01-01T05:30:00.000Z'"> <span
                                    *ngIf="data[0]?.startDate && data[0]?.startDate != '1970-01-01T05:30:00.000Z'">-</span>
                                {{data[0]?.endDate | date:'mediumDate'}}</span>
                            )
                        </div>
                        <div>
                        </div>
                    </div>
                    <div class="verticalClass00" *ngFor="let item of data;let j= index"
                        [ngClass]="i==0? 'marginLeft30':'marginLeft60'" style="display: flex">
                        <div class="borderClass"
                            [ngClass]="i== 0 ? 'marginTop43' : (i==1 ? ((j==0) ? 'marginTop147' : 'marginTop243') : (i==2 ? (j==0 ? 'marginTop346' : 'marginTop642') : (i==3 ? (j==0? 'marginTop737' : 'marginTop1445') : (i==4 ? (j==0 ? 'marginTop1514': 'marginTop3047') : (i==5 ? (j==0 ? 'marginTop3336' : 'marginTop6150') : 'marginTop6200')))))">
                            <div
                                [ngClass]="cupTeamData[i+1] ? (j % 2 == 0 ? 'evenClass' + i : 'oddClass' + i) : (i>0 ? 'backlineclass' : '')">
                                <div
                                    [ngClass]="cupTeamData[i+1] ? j % 2 == 0 ? 'verticalevenClass' + i : 'verticaloddClass' + i : ''">
                                    <label class="classh">
                                        <!-- <i class="fa fa-plus left marginTop ml-1" style="cursor: pointer" (click)="addTeamToCupRound(item, 'team1')"></i> -->
                                        <span class="marginTop" *ngIf="item?.games[0]?.teamName1 == ''">Team not
                                            added</span>
                                        <span class="marginTop" *ngIf="item?.games[0]?.teamName1 != ''"
                                            [title]="item?.games[0]?.teamName1">{{item?.games[0]?.teamName1}}</span>
                                        <span *ngFor="let plus of item?.games">({{plus?.result1}})</span>
                                        <!-- <i class="fa fa-plus right marginTop mr-1" style="cursor: pointer" (click)="getGameData(plus)" *ngFor="let plus of item?.games"></i>
                                <i class="far fa-edit right marginTop mr-1" style="cursor: pointer" (click)="addTeamToCupRound(item, 'team1')"></i> -->
                                    </label>
                                    <label class="classh">
                                        <span class="marginTop" *ngIf="item?.games[0]?.teamName2 == ''"> Team not
                                            added</span>
                                        <span class="marginTop" *ngIf="item?.games[0]?.teamName2 != ''"
                                            [title]="item?.games[0]?.teamName2">{{sliceFunction(item?.games[0]?.teamName2)}}</span>
                                        <span *ngFor="let plus of item?.games">({{plus?.result2}})</span>
                                        <!-- <i class="fa fa-plus right marginTop mr-1" style="cursor: pointer" (click)="getGameData(plus)" *ngFor="let plus of item?.games"></i>
                                <i class="far fa-edit right marginTop mr-1" style="cursor: pointer" (click)="addTeamToCupRound(item, 'team2')"></i> -->
                                    </label>
                                    <label class="classh">Date</label>
                                    <div class="form-group clearfix">
                                        <!-- <span class="col-md-4" style="color: black;">date</span> -->
                                        <div class="col-md-12">
                                            {{item?.games[0]?.startDate | date:'mediumDate'}}
                                        </div>
                                    </div>
                                    <div class="text-center">

                                        <!-- <select name="" id=""></select> -->
                                        <div class="col-12">
                                          
                                            {{item?.games[0]?.refereeName}}
                                           
                                        </div>
                                        <!-- <button class="btn btn btn-dark-blue-s max-WT-150" (click)="addRefereeModal()">Add Referee
                                                        </button> -->
                                        <!-- <div class="dropdown">
                                                            <button style="background: #17a2b8;" class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            Referee
                                                            </button>
                                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                <a class="dropdown-item" (click)="addRefereeModal()">Add Referee</a>

                                                            </div>
                                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <div><button href="#result2" role="tab" aria-controls="result2-result"
                                aria-selected="false"  (click)="basketballGameDetails(item?.games[0]?.team1,item?.games[0]?.team2,item?.games[0]?._id );getActiveResult('result2')">Statistics</button>
                                &nbsp;&nbsp;&nbsp;
                                <button (click)="getObservation([item?.games[0]?.team1,item?.games[0]?.team2 ]);getActiveResult('result3')">Observation</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div id="tablerecords"
                style="box-shadow: 0 4px 20px #929191;margin-top: 20px;display: flex;overflow: scroll;"
                *ngIf="grouporcup == 'cup' && thirdAndForthPlace.length>0">


                <div class="horizontalClass0" style="margin-left: 32px;">
                    <div style="margin-top: 24px;">
                        <label>Third and fourth place</label>
                        <div
                            *ngIf="(thirdAndForthPlace[0]?.startDate && thirdAndForthPlace[0]?.startDate != '1970-01-01T05:30:00.000Z') || (thirdAndForthPlace[0]?.endDate && thirdAndForthPlace[0]?.endDate != '1970-01-01T05:30:00.000Z')">
                            (<span
                                *ngIf="thirdAndForthPlace[0]?.startDate && thirdAndForthPlace[0]?.startDate != '1970-01-01T05:30:00.000Z'">{{thirdAndForthPlace[0]?.startDate
                                | date:'mediumDate'}}</span>
                            <span
                                *ngIf="thirdAndForthPlace[0]?.endDate && thirdAndForthPlace[0]?.endDate != '1970-01-01T05:30:00.000Z'">
                                <span
                                    *ngIf="thirdAndForthPlace[0]?.startDate && thirdAndForthPlace[0]?.startDate != '1970-01-01T05:30:00.000Z'">-</span>
                                {{thirdAndForthPlace[0]?.endDate | date:'mediumDate'}}
                            </span> )
                        </div>
                        <div style="margin-top: 11px;">

                        </div>
                    </div>
                    <div class="verticalClass00" style="margin-top: 30px;margin-bottom: 23px;">
                        <div class="borderClass">
                            <label class="classh">
                                <span *ngIf="thirdAndForthPlace[0]?.games[0]?.teamName1 == ''" class="marginTop">Team
                                    not added</span>
                                <span class="marginTop" *ngIf="thirdAndForthPlace[0]?.games[0]?.teamName1 != ''"
                                    [title]="thirdAndForthPlace[0]?.games[0]?.teamName1">{{sliceFunction(thirdAndForthPlace[0]?.games[0]?.teamName1)}}</span>
                                <span *ngFor="let plus of thirdAndForthPlace[0]?.games">({{plus?.result1}})</span>
                            </label>
                            <label class="classh">
                                <!-- <i class="fa fa-plus left marginTop" style="cursor: pointer"
                                                    (click)="addTeamToCupRound(thirdAndForthPlace[0], 'team2')"></i> -->
                                <span class="marginTop" *ngIf="thirdAndForthPlace[0]?.games[0]?.teamName2 == ''">Team
                                    not added</span>
                                <span class="marginTop" *ngIf="thirdAndForthPlace[0]?.games[0]?.teamName2 != ''"
                                    [title]="thirdAndForthPlace[0]?.games[0]?.teamName2">{{sliceFunction(thirdAndForthPlace[0]?.games[0]?.teamName2)}}</span>
                                <span *ngFor="let plus of thirdAndForthPlace[0]?.games">({{plus?.result2}})</span>
                            </label>
                            <label class="classh">Date</label>
                            <div class="form-group clearfix">
                                <div class="col-md-12">
                                    {{thirdAndForthPlace[0]?.games[0]?.startDate | date:'mediumDate'}}
                                </div>
                            </div>
                            <div class="text-center">
                                <div class="col-12">
                                    {{thirdAndForthPlace[0]?.games[0]?.refereeName}}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>



<ng-container *ngIf="acctiveTab === 'result2'">
    <basketball-report [team1Id]="team1Id" [team2Id]="team2Id" [_gameId]="_gameId" *ngIf="competitionList.sports == 'Basketball'"></basketball-report>
    <volleyball-report [team1Id]="team1Id" [team2Id]="team2Id" [_gameId]="_gameId" *ngIf="competitionList.sports == 'Volleyball'"></volleyball-report>
    <swiming-report [team1Id]="team1Id" [team2Id]="team2Id" [_gameId]="_gameId" *ngIf="competitionList.sports == 'Swimming'"></swiming-report>
    <cricket-report [team1Id]="team1Id" [team2Id]="team2Id" [_gameId]="_gameId"  *ngIf="competitionList.sports == 'Cricket'"></cricket-report>
    <badminton-report [team1Id]="team1Id" [team2Id]="team2Id" [_gameId]="_gameId" [competitionList]="competitionList" *ngIf="competitionList.sports == 'Badminton'"></badminton-report>
    <soccer-report [team1Id]="team1Id" [team2Id]="team2Id" [_gameId]="_gameId" [competitionList]="competitionList" *ngIf="competitionList.sports == 'Soccer'"></soccer-report>
    <tennis-report [team1Id]="team1Id" [team2Id]="team2Id" [_gameId]="_gameId" [competitionList]="competitionList" *ngIf="competitionList.sports == 'Table Tennis'"></tennis-report>
    <generic-report [team1Id]="team1Id" [team2Id]="team2Id" [_gameId]="_gameId" [competitionList]="competitionList" *ngIf="competitionList.sports == 'Generic'"></generic-report>
</ng-container>

<div id="Information" *ngIf="acctiveTab === 'result3'">
    <div class="tab-inner-text-block">
        <div class="col-md-12 col-lg-12 marginzero">
            <textarea disabled class="form-control common-textarea" [(ngModel)]="messageFromObservation"></textarea>
            <div class=" text-right mt10">
            </div>
        </div>
    </div>
</div>
<!-- 642e9560218b2f21ab226ebc
                 642e94ab218b2f21ab226eb3 -->