import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { timeout } from 'rxjs-compat/operator/timeout';
import { MainService } from 'src/app/providers/main-service.service';
//l
@Component({
  selector: 'app-match-details-page',
  templateUrl: './match-details-page.component.html',
  styleUrls: ['./match-details-page.component.scss']
})
export class MatchDetailsPageComponent implements OnInit {
//jjja
  gameId : any
  team1Id:any
  team2Id:any
  _gameId:any
  compId:any
  groupId:any
  roundId:any

  competitionId : any
  gameDetail : any
  messageFromObservation: any;
  isUpdated: boolean;
  constructor(private service: MainService, private activatedRoute: ActivatedRoute,private router: Router) {
    this.activatedRoute.queryParams.subscribe((res)=>{
      this.gameId = res.gameId
      this.competitionId = res.competitionId
      this.compId = res.iscompids
      this.groupId = res.groupId
      this._gameId = res.gameId
      this.roundId =res.roundId
      this.getGameDetail()
    })
   }

  ngOnInit(): void {
  }
  acctiveTab:any ='result2'
  getActiveResult(e){
    this.acctiveTab =e
    this.getObservation()
  }
  getGameDetail(){
    const data = {
      gameId : this.gameId,
      competitionId :this.competitionId
    }
    let url = `organizer/gameDetailById`
    this.service.postApi(url,data,0).subscribe((res)=>{
      if(res.responseCode == 200){
        this.gameDetail = res.result
        this.team1Id = this.gameDetail.competitionGameDetails.teamdata1._id
        this.team2Id = this.gameDetail.competitionGameDetails.teamdata2._id
      }
    })
  }
getObservation(){
  let apireq = {
    "gameId":this.gameId,
  }

  this.service.postApi('game/getObservationGame',apireq,1).subscribe(success=>{
    if(success.responseCode == 200 && success.result && success.result.observation){
      this.messageFromObservation = success.result.observation
    }
  },error=>{
    this.service.toastrErr(error);
  })
}
updatedNewMatchScore(item){
   this.getGameDetail()
   this.router.navigate(['/single-match'], { queryParams: { competitionId:this.competitionId, gameId:item._id,groupId:this.groupId,roundId:this.roundId,iscompids:this.compId} })
   this.service.isSingalMatchUpdateSubject.next(true)

  }

 
}
