<div class="preloader">
    <div class="loader">
        <div class="dot-container">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>  
        </div>
        <div class="dot-container">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>  
        </div>
        <div class="dot-container">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>  
        </div>
    </div>
</div>
<!-- added this consitin -->
<app-main-home *ngIf="userId!=null"></app-main-home>
