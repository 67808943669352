import { Component, OnInit } from "@angular/core";
import { MainService } from "src/app/providers/main-service.service";
@Component({
    selector: "profile",
    templateUrl: "./profile.component.html",
    styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
    userId: any;
    widgets: any;
    userDet: any = [];

    constructor(private service: MainService) {}

    ngOnInit(): void {
        this.userId = localStorage.getItem("userId");
        if (this.userId != null && this.userId != "") {
            this.service
                .getApi(`users/getUserDetailWithoutToken?_id=${this.userId}`, 0)
                .subscribe((response) => {
                    console.log("user details ", response.result);
                
                    // this.service.userDetail.next(response.result)
                    this.userDet = response.result;
                });

            this.service
                .getApi(
                    `configuration_domain/getWidgets?userId=${this.userId}`,
                    0
                )
                .subscribe((response) => {
                    localStorage.setItem(
                        "widgets",
                        JSON.stringify(response.result[0])
                    );
                    this.widgets = response.result[0];
                });
        }
    }
}
