import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import {
    AbstractControl,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { MainService } from "src/app/providers/main-service.service";

@Component({
    selector: "login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class loginComponent implements OnInit {
    @Output() navigate = new EventEmitter<any>();
    loginForm: FormGroup;
    flag: any = "login";
    failleAattempts: boolean = false;
    failMsg: any = "";
    constructor(
        private service: MainService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.validationForm();
    }
    validationForm() {
        var data: any = {};
        if (localStorage.getItem("loginData")) {
            data = JSON.parse(localStorage.getItem("loginData"));
            this.loginForm = new FormGroup({
                customerEmail: new FormControl(data.customerEmail || "", [
                    Validators.required,
                    Validators.pattern(
                        /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i
                    ),
                ]),
                password: new FormControl(atob(data.password) || "", [
                    Validators.required,
                ]),
                rememberMe: new FormControl(data.rememberMe || ""),
            });
        } else {
            this.loginForm = new FormGroup({
                customerEmail: new FormControl("", [
                    Validators.required,
                    Validators.pattern(
                        /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i
                    ),
                ]),
                password: new FormControl("", [Validators.required]),
                rememberMe: new FormControl(""),
            });
        }
    }
    get f() {
        return this.loginForm.controls;
    }

    onLogin() {
        let apiReqData = {
            currentDate: Date.parse(new Date().toISOString()),
            email: this.loginForm.value.customerEmail,
            password: this.loginForm.value.password,
        };
        let apiReqUrl = "users/login";
        this.service.postApi(apiReqUrl, apiReqData, 0).subscribe(
            (res: any) => {
                console.log(res);
                if (res.responseCode == 200) {
                    this.failMsg = "";
                    this.failleAattempts = false;
                    localStorage.setItem("token", res.token);
                    localStorage.setItem("userVisit",JSON.stringify(res.result)) ;
                    this.service.loginData.next(res.result);
                    this.service.toastrSucc(res.responseMessage);
                    localStorage.setItem("currentId", res.result._id);
                    this.router.navigate([""]);
                    // remember me
                    if (this.loginForm.value.rememberMe) {
                        let loginData = {
                            customerEmail: this.loginForm.value.customerEmail,
                            password: btoa(this.loginForm.value.password),
                            rememberMe: this.loginForm.value.rememberMe,
                        };
                        localStorage.setItem(
                            "loginData",
                            JSON.stringify(loginData)
                        );
                    } else {
                        localStorage.removeItem("loginData");
                    }
                    this.navigate.emit();
                } else {
                    this.failleAattempts = true;
                    this.failMsg = res.responseMessage;
                    this.service.toastrErr(res.responseMessage);
                }
            },
            (error: any) => {
                this.service.toastrErr(error.error.responseMessage);
            }
        );
    }
}
