import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainHomeComponent } from './components/pages/main-home/main-home.component';
import { EsportsHomeComponent } from './components/pages/esports-home/esports-home.component';
import { BostingHomeComponent } from './components/pages/bosting-home/bosting-home.component';
import { MagazineHomeComponent } from './components/pages/magazine-home/magazine-home.component';
import { TournamentsHomeComponent } from './components/pages/tournaments-home/tournaments-home.component';
import { StreamingHomeComponent } from './components/pages/streaming-home/streaming-home.component';
import { GalleryPageComponent } from './components/pages/gallery-page/gallery-page.component';
import { TeamPageComponent } from './components/pages/team-page/team-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { SponsorsPageComponent } from './components/pages/sponsors-page/sponsors-page.component';
import { FaqPageComponent } from './components/pages/faq-page/faq-page.component';
import { ComingSoonPageComponent } from './components/pages/coming-soon-page/coming-soon-page.component';
import { ErrorPageComponent } from './components/pages/error-page/error-page.component';
import { ProductsListPageOneComponent } from './components/pages/products-list-page-one/products-list-page-one.component';
import { ProductsListPageTwoComponent } from './components/pages/products-list-page-two/products-list-page-two.component';
import { CartPageComponent } from './components/pages/cart-page/cart-page.component';
import { CheckoutPageComponent } from './components/pages/checkout-page/checkout-page.component';
import { ProductsDetailsPageComponent } from './components/pages/products-details-page/products-details-page.component';
import { ProfileAuthenticationPageComponent } from './components/pages/profile-authentication-page/profile-authentication-page.component';
import { BlogGridTwoInRowPageComponent } from './components/pages/blog-grid-two-in-row-page/blog-grid-two-in-row-page.component';
import { BlogGridThreeInRowPageComponent } from './components/pages/blog-grid-three-in-row-page/blog-grid-three-in-row-page.component';
import { BlogMasonryPageComponent } from './components/pages/blog-masonry-page/blog-masonry-page.component';
import { BlogRightSidebarPageComponent } from './components/pages/blog-right-sidebar-page/blog-right-sidebar-page.component';
import { BlogLeftSidebarPageComponent } from './components/pages/blog-left-sidebar-page/blog-left-sidebar-page.component';
import { BlogDetailsPageComponent } from './components/pages/blog-details-page/blog-details-page.component';
import { PlayerDetailsPageComponent } from './components/pages/player-details-page/player-details-page.component';
import { TeamDetailsPageComponent } from './components/pages/team-details-page/team-details-page.component';
import { MatchDetailsPageComponent } from './components/pages/match-details-page/match-details-page.component';
import { TournamentsDetailsPageComponent } from './components/pages/tournaments-details-page/tournaments-details-page.component';
import { FixturesPageComponent } from './components/pages/fixtures/fixtures-page.component';
import { AboutComponent } from './components/pages/about/about.component';
import { MembershipComponent } from './components/pages/membership/membership.component';
import { MapComponent } from './components/common/map/map.component';
import { NewsDetailsComponent } from './components/pages/news-detail/news-detail-page.component';
import { VenuePageComponent } from './components/pages/venue/venue.component';
import { VenueDetailsPageComponent } from './components/pages/venue-detail/venue-detail.component';
import { loginComponent } from './components/pages/login/login.component';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { signupComponent } from './components/pages/signup/signup.component';
import { forgetPasswordComponent } from './components/pages/forgetPassword/forgetPassword.component';
import { CanActiveRouteGuard } from './providers/can-active-route.guard';
import { InitialLoaderComponent} from './components/initial-loader/initial-loader.component';
import { SuccessPaymentComponent } from './components/pages/payment-details/success-payment/success-payment.component';
import { TournamentsCompetitionRoundComponent } from './components/pages/tournaments-competition-round/tournaments-competition-round.component';
import { TournamentsGroupsComponent } from './components/pages/tournaments-groups/tournaments-groups.component';
import { MembershipListComponent } from './components/pages/membership-list/membership-list.component';
import { ServiceListComponent } from './components/pages/service-list/service-list.component';
import { MembershipDetailsPageComponent } from './components/pages/membership-details-page/membership-details-page.component';
import { VenueListComponent } from './components/pages/venue-list/venue-list.component';
import { MenuSectionDetailsComponent} from './components/pages/menu-section-details/menu-section-details.component'
import { MediaComponent } from './components/pages/media/media.component'
import { BookServiseComponent} from './components/pages/book-servise/book-servise.component'
import { VenueViewComponent } from './components/pages/venue-view/venue-view.component';
import { VenueSlotsPlayerComponent } from './components/pages/venue-slots-player/venue-slots-player.component';
import { VenueBookingFormComponent } from './components/pages/venue-booking-form/venue-booking-form.component';
import { MediaListPlayerComponent } from './components/pages/media-management/media-list-player/media-list-player.component';
import { PlayerVenueSponsorComponent } from './components/pages/media-management/player-venue-sponsor/player-venue-sponsor.component';
import { VenueSponsorComponent } from './components/pages/media-management/venue-sponsor/venue-sponsor.component';
import {LeaderBoardComponent} from './components/pages/service-list/Leaderboard/leaderboard.component'

import {LeaderComponentNew} from './components/pages/leaderboard/leader.component'
import {EvaluationComponent1} from './components/pages/evaluation/evaluation.component'
const routes: Routes = [
    {path: '',component:InitialLoaderComponent},
    {path: 'home', component: MainHomeComponent},
    {path: 'esports', component: EsportsHomeComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'bosting', component: BostingHomeComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'magazine', component: MagazineHomeComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'tournaments', component: TournamentsHomeComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'streaming', component: StreamingHomeComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'team', component: TeamPageComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'gallery', component: GalleryPageComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'sponsors', component: SponsorsPageComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'faq', component: FaqPageComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'coming-soon', component: ComingSoonPageComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'error', component: ErrorPageComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'fixtures', component: FixturesPageComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'single-tournament', component: TournamentsDetailsPageComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'tournament-rounds', component: TournamentsCompetitionRoundComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'tournament-groups', component: TournamentsGroupsComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'single-match', component: MatchDetailsPageComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'membership-media', component: MembershipDetailsPageComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'venue-media', component: MembershipDetailsPageComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'single-team', component: TeamDetailsPageComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'single-player', component: PlayerDetailsPageComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'products-list-1', component: ProductsListPageOneComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'products-list-2', component: ProductsListPageTwoComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'cart', component: CartPageComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'checkout', component: CheckoutPageComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'single-products', component: ProductsDetailsPageComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'my-account', component: ProfileAuthenticationPageComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'blog-1', component: BlogGridTwoInRowPageComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'blog-2', component: BlogGridThreeInRowPageComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'blog-3', component: BlogMasonryPageComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'blog-4', component: BlogRightSidebarPageComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'blog-5', component: BlogLeftSidebarPageComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'single-blog', component: BlogDetailsPageComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'contact', component: ContactPageComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'about', component: AboutComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'membership', component: MembershipComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'map-location', component: MapComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'news-detail', component: NewsDetailsComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'venue', component: VenuePageComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'venue-detail', component: VenueDetailsPageComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'venue-list', component: VenueListComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'login', component: loginComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'profile', component: ProfileComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'signup', component: signupComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'forgetPassword', component: forgetPasswordComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'successPayment', component: SuccessPaymentComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'membership-list', component: MembershipListComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'service-list/:compId/:orgId', component: ServiceListComponent, canActivate : [CanActiveRouteGuard]},
    {path: 'section', component: MenuSectionDetailsComponent},
    {path: 'mediaa', component: MediaComponent},
    {path: 'venue-view', component: VenueViewComponent, canActivate : [CanActiveRouteGuard]},
    { path:'book-membership/:serId/:id',component: BookServiseComponent},
    { path: 'venueSlots/:date/:sport/:id', component: VenueSlotsPlayerComponent, canActivate : [CanActiveRouteGuard] },
    { path: 'venueBookingForm/:id', component: VenueBookingFormComponent, canActivate: [CanActiveRouteGuard] },
    { path: 'media/:compId/:orgId/:num', component: MediaListPlayerComponent, canActivate: [CanActiveRouteGuard] },
    { path: 'media', component: MediaListPlayerComponent, canActivate: [CanActiveRouteGuard] },
    { path: 'playerVenueSponsor/:venId', component: PlayerVenueSponsorComponent, canActivate: [CanActiveRouteGuard]},
    { path: 'playerVenueSponsor', component: PlayerVenueSponsorComponent, canActivate: [CanActiveRouteGuard]},
    { path: 'venueSponsor/:id', component: VenueSponsorComponent, canActivate: [CanActiveRouteGuard] },

    { path:'leaderboard',component:LeaderComponentNew,canActivate: [CanActiveRouteGuard] },
    { path:'evalution',component:EvaluationComponent1,canActivate: [CanActiveRouteGuard] },
    {path: '**', component: ErrorPageComponent, canActivate : [CanActiveRouteGuard]}
    
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }