<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <h1 title="Booking">Booking </h1>
        </div>
    </div>
</section>
<section class="fixtures-area pt-100 pb-70">
    <div class="container">
        <div class="table-responsive">
<main class="main-container pt0 mt-min60">
    <div class="container">
        <div class="fixtures-section w-100 center-box">
            <div class="global-box grey-box">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="calender-block calendarpp">
                            <h6 class="pl-3 mb-4">Selected A Date</h6>
                            <form #myForm="ngForm" novalidate>
                                <my-date-picker name="mydate" [options]="myDatePickerOptions" [(ngModel)]="date.selectedDate" (dateChanged)="onDateSelect($event)" required></my-date-picker>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="card bd">
                            <div class="card-header mt-2 bd"><span *ngIf='date.isDateSelected'>Selected Date: {{date.selectedDate?.formatted}}</span></div>
                            <div class="card-body cord-det">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="member-img-box h-150">
                                            <img src="assets/img/evel-profile.png" class="w-100 h-100">
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="meber-detail">
                                            <div class="row">
                                                <div class="col-lg-3 col-md-4 col-sm-5"><label class="member-label">Service Name :</label></div>
                                                <div class="col-lg-9 col-md-8 col-sm-7">
                                                    <p class="member-text">{{serviceDetails?.serviceName}}</p>
                                                </div>
                                                <!-- <label class="col-12">{{serviceDetails?.serviceName}}</label> -->
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-3 col-md-4 col-sm-5"><label class="member-label">Price :</label></div>
                                                <div class="col-lg-9 col-md-8 col-sm-7">
                                                    <p class="member-text" *ngIf='serviceDetails?.serviceType != "free"'>AED {{serviceDetails?.amount}}</p>
                                                    <p class="member-text" *ngIf='serviceDetails?.serviceType == "free"'>Free</p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-3 col-md-4 col-sm-5"><label class="member-label">Duration :</label></div>
                                                <div class="col-lg-9 col-md-8 col-sm-7">
                                                    <p class="member-text">{{serviceDetails?.duration}} minutes</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <p class="pt20">{{serviceDetails?.description}} </p>
                                    </div>
                                    <div class="col-12" *ngIf='date.isDateSelected'>
                                        <ul class="time-table-list">
                                            <li (click)="timeSelected(times, i)" class="always" *ngFor='let times of serviceDetails?.slots; let i=index' [ngClass]="{'selected': times?.noOfSeats == 0}" id='{{i}}'>{{times.time}}</li>
                                            <!-- <li class="selected">09:AM</li>
                                            <li>09:AM</li>
                                            <li class="selected">09:AM</li>
                                            <li class="active">09:AM</li>-->
                                        </ul>
                                    </div>
                                </div>
                                <button class="btn btn-navy-blue max-WT-100" *ngIf='!date.isDateSelected' [disabled]='!date.isDateSelected'> Select Date</button>
                                <button class="btn btn-navy-blue max-WT-100" *ngIf='date.isDateSelected && !date.isSlotSelected' [disabled]='!date.isSlotSelected'> Select Slot</button>
                                <button class="btn btn-navy-blue max-WT-100" *ngIf='date.isDateSelected && date.isSlotSelected' [disabled]='!date.isSlotSelected && !date.isDateSelected' data-toggle="modal" data-target="#preview_booking_info"> Book Now</button>
                            </div>
                        </div>
                    </div>

                </div>
                <!-- <table style="width:100%">
                    <tr *ngFor='let times of serviceDetails?.slots'>
                        <td (click)="timeSelected(times)" style="background-color: aquamarine">{{times.time}}</td>
                    </tr>
                </table> -->
            </div>
        </div>
    </div>
</main>
</div>
</div>
</section>
<!-- Modal Start -->
<div class="modal fade" id="preview_booking_info" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog max-WT-800" style="max-width: 805px;"  role="document">
        <div class="modal-content">
            <form class="booking-proceed-form">
                <div class="modal-header d-block bdr-none pb0 clearfix">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body tab_pad_L60 tab_pad_R60">
                    <!-- <h5 class="modal-head mt0 mb20 text-center navy-blue font_uni_sans" id="exampleModalLabel">Booking Form</h5> -->
                    <div class="modal_booking_table">
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Time</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor='let slot of slots'>
                                        <td>{{date.selectedDate.formatted }}</td>
                                        <td>{{serviceDetails.endDate }}</td>
                                        <td> <span>{{slot.startTime}}</span></td>
                                        <td *ngIf="serviceDetails?.serviceType != 'free'">{{slot?.price}} </td>
                                        <td *ngIf="serviceDetails?.serviceType == 'free'"> 00.00</td>
                                    </tr>
                                    <tr>
                                        <td colspan="3">{{serviceDetails?.serviceName}}</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colspan="3">Total</td>
                                        <td *ngIf="serviceDetails?.serviceType != 'free'">AED {{amount}}</td>
                                        <td *ngIf="serviceDetails?.serviceType == 'free'"> Free </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <div class="center-box booking-field mb30">
                        <div class="row mb15">
                            <label class="control-label col-md-3 blk">First Name</label>
                            <div class="col-md-6">
                                <input type="text" class="form-control inputdoc" placeholder='First Name' [ngModelOptions]="{standalone: true}" [(ngModel)]="firstName" required/>
                            </div>
                        </div>
                        <div class="row mb15">
                            <label class="control-label col-md-3 blk">Last Name</label>
                            <div class="col-md-6">
                                <input type="text" class="form-control inputdoc" placeholder='Last Name' [ngModelOptions]="{standalone: true}" [(ngModel)]="lastName" required/>
                            </div>
                        </div>
                        <div class="row mb15" *ngFor="let form of regForm">
                            <label class="control-label col-md-3 blk">{{form.field}}</label>
                            <div class="col-md-6">
                                <input type="text" class="form-control inputdoc" *ngIf="form.importance == 'mandatory'" [ngModelOptions]="{standalone: true}" [(ngModel)]="form.itemValue" type=" {{form.fieldType}}" placeholder="Please enter {{form.field}}" required/>
                                <input type="text" class="form-control inputdoc" *ngIf="form.importance != 'mandatory'" [ngModelOptions]="{standalone: true}" [(ngModel)]="form.itemValue" type=" {{form.fieldType}}" placeholder="Please enter {{form.field}}" />
                            </div>
                        </div>
                        <div class="row mb15" *ngIf='serviceDetails.serviceType == "paid"'>
                            <label class="control-label col-md-3 blk">Payment method</label>
                            <div class="col-md-6">
                                <select class="form-control seleinput" [ngModelOptions]="{standalone: true}" [(ngModel)]="paymenVal" required>
                                    <option value="" >Select Payment Type</option>
                                    <option value="Online"> Online</option>
                                    <option value="Offline"> Offline </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix calendar-action">
                        <button (click)="resetItem()" type="button" class="mb25 btn btn-navy-blue max-WT-170 f-left" data-dismiss="modal" aria-label="Close">Back to calender</button>
                        <button style="    float: right;" type="submit" class="mb25 btn btn-navy-blue max-WT-170 f-right " (click)='saveRegister()'>Proceed</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- preview_booking_info End -->



<!-- payment -->
<div class="modal fade common-modal" id="paymentPlanChange" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog max-WT-600"  role="document">
        <div class="modal-content">
            {{list.cardList.length}}
            <div *ngIf="list.cardList.length" class="add-to-card-box">
                <table class="table table-bordered bor-table large-table">
                    <thead>
                        <tr class="table-head-bg">
                            <th>Card Number</th>
                            <th>Expiry Date</th>
                            <th>Choose</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let card of list.cardList">
                            <td>{{card.cardNumber}}</td>
                            <td>{{card.expiryDate}}</td>
                            <td class="action-td">
                                <span class="radio-checkbox"><input type="radio" id="check2" [value]="card._id" name="currCard" [(ngModel)]="currCard" (click)="selectCard(card)">
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <form class="modal-add-card-form" autocomplete="off" [formGroup]="cardForm">
                <div class="modal-body text-center">
                    <h5 class="modal-head mt15 mb5 navy-blue font_uni_sans  mfnaem">Payment</h5>
                    <div class="modal-content-body">
                        <p class="head_lagend mb20  text-center para blk">Please enter details for payment</p>
                        <div class="center-box">
                            <div class="form-group clearfix row">
                                <label class="col-md-3 label-blue blk">Card Number</label>
                                <div class="col-md-9">
                                    <input type="text" class="form-control seleinput" placeholder="Card number" maxlength="16" [formControl]="cf.card">
                                    <div class="errorMsg" style="float: left" *ngIf="cf.card.dirty && cf.card.invalid">
                                        <span *ngIf="cf.card.hasError('required')">*Please enter card no.</span>
                                        <span *ngIf="cf.card.hasError('pattern')">*Please enter valid card no.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group clearfix row">
                                <label class="col-md-3 label-blue blk">Expiry Date</label>
                                <div class="col-md-9">
                                    <input type="text" class="form-control seleinput" [formControl]="cf.expiryDate" placeholder="yyyy - mm" maxlength="7" />
                                    <div class="errorMsg" style="float: left;" *ngIf="cf.expiryDate.dirty && cf.expiryDate.invalid">
                                        <span *ngIf="cf.expiryDate.hasError('required')">*Please enter expiry date.</span>
                                        <span *ngIf="cf.expiryDate.hasError('pattern')">*Please enter valid expiry date(YYYY-MM).</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group clearfix row">
                                <label class="col-md-3 label-blue blk">CVV</label>
                                <div class="col-md-9">
                                    <input type="password" class="form-control seleinput" placeholder="CVV" maxlength="3" [formControl]="cf.cvv">
                                    <div class="errorMsg" style="float: left" *ngIf="cf.cvv.dirty && cf.cvv.invalid">
                                        <span *ngIf="cf.cvv.hasError('required')">*Please enter cvv.</span>
                                        <span *ngIf="cf.cvv.hasError('pattern')">*Please enter valid cvv.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix calendar-action text-center pL15 pR15 mt30">
                            <button (click)="resetItem()" type="button" class="mb25 btn btn-navy-blue max-WT-150" style="float: left;" data-dismiss="modal">Cancel</button>
                            <button type="button" class="mb25 btn btn-navy-blue max-WT-150" style="float: right;" (click)="pay()" [disabled]="cardForm.invalid">Pay</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- payment -->




<!-- This is confirmation message show -->

<div class="modal fade" id="success_confirmation_popup" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog max-WT-800" style="max-width: 805px;"  role="document">
        
        <div class="modal-content">
            <form class="booking-proceed-form">
                <div class="modal-header d-block bdr-none pb0 clearfix">
                    <h5 class="modal-title" id="exampleModalLabel">Booking confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body tab_pad_L60 tab_pad_R60">
                    <h5 style="color: black;" class="modal-head mt0 mb20 text-center navy-blue font_uni_sans" id="exampleModalLabel"> </h5>
                    <div class="modal_booking_table">
                        <div class="okcnf">{{isMessage}}</div>
                    </div>
                   
                    <div class="clearfix calendar-action">
                        <button (click)="closeSuccess()" type="button" class="mb25 btn btn-navy-blue max-WT-170 f-left" data-dismiss="modal" aria-label="Close">OK</button>
                        <!-- <button style="    float: right;" type="submit" class="mb25 btn btn-navy-blue max-WT-170 f-right " (click)='saveRegister()'>Proceed</button> -->
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- end confirmation  -->
<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>