<app-navbar-style-one></app-navbar-style-one>

<app-streaming-main-banner></app-streaming-main-banner>

<section class="fixtures-area pt-100 pb-70">
    <app-trending-streaming></app-trending-streaming>
</section>

<app-matches-style-two></app-matches-style-two>

<section class="fixtures-area pt-100 pb-70">
    <app-upcoming-streaming></app-upcoming-streaming>
</section>

<section class="top-team-area pb-70">
    <app-top-ranking-team-style-two></app-top-ranking-team-style-two>
</section>

<section class="subscribe-area">
    <app-subscribe-style-one></app-subscribe-style-one>
</section>

<section class="social-area pt-100 pb-70">
    <app-social></app-social>
</section>

<footer class="footer-area">
    <app-footer-style-one></app-footer-style-one>
</footer>