import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/providers/main-service.service';

@Component({
  selector: 'app-player-venue-sponsor',
  templateUrl: './player-venue-sponsor.component.html',
  styleUrls: ['./player-venue-sponsor.component.scss']
})
export class PlayerVenueSponsorComponent implements OnInit {
 userDetail: any;
  venId: any;
  pageNo: number=1;
  sponsorList: any=[];
  paginationData: any={};
  isLoginVistUser:any
  constructor(public route: ActivatedRoute, public service:MainService) { }

  ngOnInit() {
    this.isLoginVistUser = this.service.isLoginVisitUser()
    this.route.params.subscribe(params => {
      this.venId = params['venId'];
    })
    this.getSponsorList(this.pageNo);
  }

  // List Of Sponsor Functionality 
getSponsorList(page){
  this.pageNo = page;
  var apidoc = {
    "venueId":this.venId,
      "limit":4,
      "page":this.pageNo
    }
    
  console.log(`DATA B4 SEND-->${JSON.stringify(apidoc)}`)      
  this.service.postApi(`data/listOfSponsor`, apidoc, 1).subscribe(response => {
    if(response.responseCode == 200) {
      console.log('SERVICE LIST --> ', JSON.stringify(response));
    this.sponsorList = response.result.docs;
    this.paginationData = response.result;
    }
  })
}
}
