<div class="navbar-area">
    <div class="zinble-responsive-nav">
        <div class="container">
            <div class="zinble-responsive-menu">
                <div class="logo">
                    <a routerLink="/">
                        <img [src]="orgLogo" alt="logo">
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="zinble-nav">
        <div class="{{containerClass}}">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img [src]="orgLogo" alt="logo" style="height: 80px;">
                </a>
    

                <div class="collapse navbar-collapse mean-menu">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Home</a></li>
                        <!-- <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">About Us</a></li> -->


                        <li *ngIf="userDetails?.organizerType?.includes('COMPETITION') &&
                         (widgets.competitionList || widgets.standingAndFixtures || widgets.roundsAndGroupAndCup)" class="nav-item"><a
                                href="javascript:void(0)" class="nav-link">Competitions <i
                                    class="flaticon-down-arrow"></i></a>
                            <ul class="dropdown-menu">
                                <li *ngIf="widgets.competitionList" class="nav-item"><a routerLink="/tournaments"
                                        class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Competition List</a></li>

                                <!-- <li *ngIf="widgets.standingAndFixtures" class="nav-item"><a routerLink="/fixtures"
                                        class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Standings & Fixtures</a></li> -->

                                <!-- <li *ngIf="widgets.roundsAndGroupAndCup" class="nav-item"><a
                                        routerLink="/tournament-rounds" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Round/Group/Cups</a></li> -->

                            </ul>
                        </li>

                        <li *ngIf="userDetails?.organizerType?.includes('MEMBERSHIP') &&
                         (widgets.membershipList || widgets.services || widgets.professional || widgets.leaderboard || widgets.certificates)"
                            class="nav-item"><a href="javascript:void(0)" class="nav-link">Membership<i
                                    class="flaticon-down-arrow"></i></a>
                            <ul class="dropdown-menu">
                                <li *ngIf="widgets.membershipList" class="nav-item"><a routerLink="/membership-list"
                                        class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true} ">Membership List</a></li>

                                <!-- <li *ngIf="widgets.services" class="nav-item"><a routerLink="/service-list" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Service
                                        List</a></li> -->

                                <li *ngIf="widgets.professional" class="nav-item"><a routerLink="/evalution"
                                        class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}" >Evaluation</a></li>

                                <li *ngIf="widgets.leaderboard" class="nav-item"><a routerLink="/leaderboard"
                                        class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}" >Leaderboard</a></li>
                                <!-- <li *ngIf="widgets.certificates" class="nav-item"><a routerLink="/fixtures"
                                        class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Certificates</a></li> -->
                            </ul>
                        </li>

                        <li *ngIf="userDetails?.organizerType?.includes('VENUE') && (widgets?.venueList || widgets?.bookingCalendar)"
                            class="nav-item"><a href="javascript:void(0)" class="nav-link">Venue<i
                                    class="flaticon-down-arrow"></i></a>
                            <ul class="dropdown-menu">
                                <li *ngIf="widgets.venueList" class="nav-item"><a routerLink="/venue-list"
                                        class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Venue List</a></li>

                                <!-- <li *ngIf="widgets.bookingCalendar" class="nav-item"><a routerLink="/fixtures"
                                        class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Booking Calendar</a></li> -->

                            </ul>
                        </li>

                        <li *ngFor="let item of sectionsList" class="nav-item">
                            <a *ngIf="item.menu != ''"  class="nav-link">{{item.sectionName}}<i
                                class="flaticon-down-arrow"></i></a>
                        <a *ngIf="item.menu === ''" (click)="openMenu(item._id)"  target="_blank">{{item.sectionName}}</a>
                                
                        <ul class="dropdown-menu" *ngIf="item.menu != ''">
                            <li class="nav-link"><a  (click)="openMenu(item._id)" target="_blank">{{item.menu}}</a></li>
                        </ul>
                    </li>
                    <!-- href="{{item.image}}"/ -->

                        <!-- <li class="nav-item"><a routerLink="/membership" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Membership</a></li>
                        <li class="nav-item"><a routerLink="/venue" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Venues</a></li> -->
<!-- 
                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">Media</a></li> -->
                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Contact</a></li>

                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link"> Account <i
                                    class="flaticon-down-arrow"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/profile" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Organizer Profile</a></li>

                                <li class="nav-item"><a href="https://www.digixvsx.com/landing/login" target="_blank" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Admin Login </a></li>
                                        <li *ngIf="!service.isLogin()" class="nav-item"><a (click)="openLogin()" target="_blank" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"> Create Account</a></li>
                                            <li *ngIf="service.isLogin()" class="nav-item"><a target="_blank" (click)="logoutfromTemplate()" class="nav-link" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}"> Logout</a></li>



                            </ul>
                        </li>
                    </ul>

                    <div class="others-option d-flex align-items-center">
                        <div class="option-item">
                            <div class="search-box">
                                <i class="flaticon-search-1"></i>
                            </div>
                        </div>

                        <!-- <div class="option-item">
                            <div class="side-menu-btn">
                                <i class="flaticon-null-2" data-toggle="modal" data-target="#sidebarModal"></i>
                            </div>
                        </div> -->
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div class="others-option-for-responsive">
        <div class="container">


            <div class="container">
                <div class="option-inner">
                    <div class="others-option">
                        <div class="option-item">
                            <div class="search-box">
                                <i class="flaticon-search-1"></i>
                            </div>
                        </div>

                        <div class="option-item">
                            <div class="side-menu-btn">
                                <i class="flaticon-null-2" data-toggle="modal" data-target="#sidebarModal"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>

            <div class="search-overlay-close">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>

            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class="flaticon-search-1"></i></button>
                </form>
            </div>
        </div>
    </div>
</div>