<div id="Starts" class=" tab-pane active show">
    <div class="row">
       <div class="col-lg-12">
          <div class="scoreboard-table-block1">
             <div class="scoreboard-table-inner">
               <div class="status-content-block">
                <div>
                  <div>
                     <h3 style="text-align: center; margin-top: 10px;"> Competition Details  </h3>
                     <table class="table table-bordered large-table">
                        <thead>
                           <tr class="table-head-bg">
                              <th>Competition</th>
                              <th>Division</th>
                              <th>Period</th>
                              <th>Date</th>
                              <th>Venue</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr >
                              <td>{{gameData?.competitionData?.competitionName}}</td>
                              <td>{{ gameData?.competitionData?.division }}</td>
                              <td>{{ gameData?.competitionData?.period}}</td>
                              <td>{{ gameData?.competitionData?.startDate | date:'mediumDate' }}</td>
                              <td>{{ gameData?.competitionData?.venue }}</td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
               <h3 style="text-align: center;" > Statistics </h3>
               </div>
               <div class="table-scrool-box">
                 <div class="table-block mt30">
                    <div class="table-responsive table-responsive-edit">
                         <table class="table table-bordered text-center">
                           <thead>
                             <tr class="table-head-bg">
                               <th>Team</th>
                               <th>Final Score</th>
                               <th>Team Fouls</th>
                               <th>Offside</th>
                               <th>Corner Kicks</th>
                               <th>Goalkeeper Saves</th>
                               <th>MVP</th>
                             </tr>
                           </thead>
                           <tbody>
                             <tr >
                               <td>{{ soccerGameDetailsData?.result?.teamDeatailsOne.length>0 && soccerGameDetailsData?.result?.teamDeatailsOne[0].teamDetails[0].teamName}}</td>
                               <td>{{soccerGameDetailsData?.result?.teamDeatailsOne.length>0 &&  soccerGameDetailsData?.result?.teamDeatailsOne[0].finalScore }}</td>
                               <td>{{ soccerGameDetailsData?.result?.teamDeatailsOne.length>0 && soccerGameDetailsData?.result?.teamDeatailsOne[0].teamFoul }}</td>
                               <td>{{soccerGameDetailsData?.result?.teamDeatailsOne.length>0 &&  soccerGameDetailsData?.result?.teamDeatailsOne[0].offSide }}</td>
                               <td>{{soccerGameDetailsData?.result?.teamDeatailsOne.length>0 &&  soccerGameDetailsData?.result?.teamDeatailsOne[0].cornerkicks }}</td>
                               <td>{{soccerGameDetailsData?.result?.teamDeatailsOne.length>0 &&  soccerGameDetailsData?.result?.teamDeatailsOne[0].goalKeeperSaves}}</td>
                               <td *ngIf="!soccerGameDetailsData?.result?.teamDeatailsOne[0].mvp">MVP Player </td>
                               <td *ngIf="soccerGameDetailsData?.result?.teamDeatailsOne[0].mvp">{{soccerGameDetailsData?.result?.teamDeatailsOne[0].mvp}}</td>
                             </tr>
                             <tr >
                               <td>{{ soccerGameDetailsData?.result?.teamDeatailsTwo.length>0 &&  soccerGameDetailsData?.result?.teamDeatailsTwo[0].teamDetails[0].teamName }}</td>
                               <td>{{ soccerGameDetailsData?.result?.teamDeatailsTwo.length>0 &&   soccerGameDetailsData?.result?.teamDeatailsTwo[0].finalScore }}</td>
                               <td>{{ soccerGameDetailsData?.result?.teamDeatailsTwo.length>0 &&   soccerGameDetailsData?.result?.teamDeatailsTwo[0].teamFoul }}</td>
                               <td>{{ soccerGameDetailsData?.result?.teamDeatailsTwo.length>0 &&   soccerGameDetailsData?.result?.teamDeatailsTwo[0].offSide }}</td>
                               <td>{{ soccerGameDetailsData?.result?.teamDeatailsTwo.length>0 &&   soccerGameDetailsData?.result?.teamDeatailsTwo[0].cornerkicks }}</td>
                               <td>{{ soccerGameDetailsData?.result?.teamDeatailsTwo.length>0 &&   soccerGameDetailsData?.result?.teamDeatailsTwo[0].goalKeeperSaves}}</td>
                               <td *ngIf="!soccerGameDetailsData?.result?.teamDeatailsTwo[0].mvp">MVP Player</td>
                               <td *ngIf="soccerGameDetailsData?.result?.teamDeatailsTwo[0].mvp">{{soccerGameDetailsData?.result?.teamDeatailsTwo[0].mvp }}</td>
                             </tr>
                           </tbody>
                         </table>
                        
                         
                         <table class="table table-bordered large-table">
                           <thead>
                              <tr class="table-head-bg">
                                 <th colspan="7" class="custom-top-heading text-align-left">{{ soccerGameDetailsData?.result?.teamDeatailsOne.length>0 && soccerGameDetailsData?.result?.teamDeatailsOne[0].teamDetails[0].teamName }}</th>
                              </tr>
                              <tr class="table-head-bg">
                                 <th>Player No.</th>
                                 <th>Player First Name</th>
                                 <th>Player Last Name</th>
                                 <th>Goals</th>
                                 <th>Shots</th>
                                 <th>Yellow Cards</th>
                                 <th>Red Cards</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr *ngFor = "let data of soccerGameDetailsData?.result?.teamOne">
                                 <td>{{ data?.playerNumber }}</td>
                                 <td>{{ data?.userDetails[0]?.firstName }}</td>
                                 <td>{{ data?.userDetails[0]?.lastName }}</td>
                                 <td>{{ data?.goals }}</td>
                                 <td>{{ data?.shots }}</td>
                                 <td>{{ data?.yellowCards }}</td>
                                 <td>{{ data?.redCards }}</td>
                              </tr> 
                           </tbody>
                        </table>
                       


                     <table class="table table-bordered large-table">
                        <thead>
                           <tr class="table-head-bg">
                              <th colspan="7" class="custom-top-heading text-align-left">{{ soccerGameDetailsData?.result?.teamDeatailsTwo.length>0 &&  soccerGameDetailsData?.result?.teamDeatailsTwo[0].teamDetails[0].teamName }}</th>
                           </tr>
                           <tr class="table-head-bg">
                              <th>Player No.</th>
                              <th>Player First Name</th>
                              <th>Player Last Name</th>
                              <th>Goals</th>
                              <th>Shots</th>
                              <th>Yellow Cards</th>
                              <th>Red Cards</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr *ngFor = "let data of soccerGameDetailsData?.result?.teamTwo">
                              <td>{{ data?.playerNumber }}</td>
                              <td>{{ data?.userDetails[0]?.firstName }}</td>
                              <td>{{ data?.userDetails[0]?.lastName}}</td>
                              <td>{{ data?.goals }}</td>
                              <td>{{ data?.shots }}</td>
                              <td>{{ data?.yellowCards }}</td>
                              <td>{{ data?.redCards }}</td>
                           </tr>
                        </tbody>
                     </table>
                    
                  </div>
            </div>
         </div>
       </div>
     </div>
 </div>
</div>
</div>
<div id="Information" *ngIf="observation">
   <div class="tab-inner-text-block">
     <div class="col-md-12 col-lg-12 marginzero">
       <textarea class="form-control common-textarea" readonly [(ngModel)]="observationMessage"></textarea>
     </div>
   </div>
 </div>


