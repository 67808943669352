<app-navbar-style-one></app-navbar-style-one>
<div class="booking-carousel">
    <div id="demo" class="carousel slide" data-ride="carousel"> 
      <!-- Indicators -->
      <ul class="carousel-indicators">
        <li data-target="#demo" data-slide-to="i"  [ngClass]="{'active':  i == '0'}" *ngFor="let images of venueImages; let i = index"></li>
      </ul>
      <!-- The slideshow -->
      <div class="carousel-inner mx-WT-HT">
        <div class="carousel-item"  [ngClass]="{'active':  i == '0'}" *ngFor="let images of venueImages; let i = index">
          <img src="{{images}}" alt="stadium">
        </div>
      </div>
    </div>
  </div>
  <div class="custom-breadcrumb mt10 custom-breadcrumb1">
    <!-- <nav class="breadcrumb">
      <a class="breadcrumb-item active" [ngClass]="{'active': currUrl == 'venue-view'}" [routerLink]="['/venue-view']" 
      [queryParams]="{id : venueIdGet}">
        View </a>
      <a class="breadcrumb-item" [ngClass]="{'active': currUrl == 'media'}" [routerLink]="['/media', venueIdGet, loginId, 3]">Media</a>
      <a class="breadcrumb-item" [ngClass]="{'active': currUrl == 'venueSponsor'}" [routerLink]="['/venueSponsor',venueIdGet]">Sponsor</a>
    </nav> -->
    <div class="matches-tabs">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
       <li class="nav-item" role="presentation">
        <a class="nav-link active" 
        [ngClass]="{'active': currUrl == 'venue-view'}" 
        [routerLink]="['/venue-view']" 
        [queryParams]="{id : venueIdGet}"
        data-toggle="tab" 
        role="tab"
        aria-selected="true">
        View</a>
      </li>
      <li class="nav-item" role="presentation"><a class="nav-link" 
        [routerLink]="['/media', venueIdGet, loginId, 3]"
         (click)="getActiveResult('media')" 
                  id="latest-result-tab" data-toggle="tab" [ngClass]="{'active': currUrl == 'media'}" href="#latest-result" role="tab"
                  aria-controls="latest-result" aria-selected="false">
                  Media</a></li>
         <li class="nav-item" role="presentation"><a class="nav-link" [routerLink]="['/playerVenueSponsor',venueIdGet]"
                      (click)="getActiveResult('playerVenueSponsor')" 
                     id="Sponsar-result-tab" data-toggle="tab" [ngClass]="{'active': currUrl == 'playerVenueSponsor'}" href="#Sponsar-result" role="tab"
                     aria-controls="Sponsar-result" aria-selected="false">
                     Sponsers</a></li>
  </ul>
  </div>
  </div>
 