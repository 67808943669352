import { BrowserModule } from '@angular/platform-browser';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {MainService} from './providers/main-service.service'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { MainHomeComponent } from './components/pages/main-home/main-home.component';
import { AwardsComponent } from './components/common/awards/awards.component';
import { EsportsHomeComponent } from './components/pages/esports-home/esports-home.component';
import { FooterStyleOneComponent } from './components/common/footer-style-one/footer-style-one.component';
import { SocialComponent } from './components/common/social/social.component';
import { SubscribeStyleOneComponent } from './components/common/subscribe-style-one/subscribe-style-one.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { ShopStyleOneComponent } from './components/common/shop-style-one/shop-style-one.component';
import { TeamComponent } from './components/common/team/team.component';
import { NewsComponent } from './components/common/news/news.component';
import { MatchesStyleOneComponent } from './components/common/matches-style-one/matches-style-one.component';
import { MainBannerComponent } from './components/pages/main-home/main-banner/main-banner.component';
import { NavbarStyleOneComponent } from './components/common/navbar-style-one/navbar-style-one.component';
import { BlogComponent } from './components/common/blog/blog.component';
import { ShopStyleTwoComponent } from './components/common/shop-style-two/shop-style-two.component';
import { LiveStreamComponent } from './components/common/live-stream/live-stream.component';
import { TopRankingTeamStyleOneComponent } from './components/common/top-ranking-team-style-one/top-ranking-team-style-one.component';
import { MatchesStyleTwoComponent } from './components/common/matches-style-two/matches-style-two.component';
import { EsportsMainBannerComponent } from './components/pages/esports-home/esports-main-banner/esports-main-banner.component';
import { NavbarStyleTwoComponent } from './components/common/navbar-style-two/navbar-style-two.component';
import { BostingHomeComponent } from './components/pages/bosting-home/bosting-home.component';
import { FooterStyleTwoComponent } from './components/common/footer-style-two/footer-style-two.component';
import { HistoryComponent } from './components/common/history/history.component';
import { SubscribeStyleTwoComponent } from './components/common/subscribe-style-two/subscribe-style-two.component';
import { FeedbackComponent } from './components/common/feedback/feedback.component';
import { OurExpertsComponent } from './components/common/our-experts/our-experts.component';
import { GamesComponent } from './components/common/games/games.component';
import { FeaturedGamesComponent } from './components/common/featured-games/featured-games.component';
import { ServicesComponent } from './components/common/services/services.component';
import { BostingMainBannerComponent } from './components/pages/bosting-home/bosting-main-banner/bosting-main-banner.component';
import { MagazineHomeComponent } from './components/pages/magazine-home/magazine-home.component';
import { MagazineMainBannerComponent } from './components/pages/magazine-home/magazine-main-banner/magazine-main-banner.component';
import { MagazineNewsComponent } from './components/pages/magazine-home/magazine-news/magazine-news.component';
import { TournamentsHomeComponent } from './components/pages/tournaments-home/tournaments-home.component';
import { StreamingHomeComponent } from './components/pages/streaming-home/streaming-home.component';
import { TopRankingTeamStyleTwoComponent } from './components/common/top-ranking-team-style-two/top-ranking-team-style-two.component';
import { TrendingStreamsComponent } from './components/common/trending-streams/trending-streams.component';
import { UpcomingTournamentsComponent } from './components/common/upcoming-tournaments/upcoming-tournaments.component';
import { PopularLeaguesComponent } from './components/common/popular-leagues/popular-leagues.component';
import { TournamentsMainBannerComponent } from './components/pages/tournaments-home/tournaments-main-banner/tournaments-main-banner.component';
import { UpcomingStreamingComponent } from './components/common/upcoming-streaming/upcoming-streaming.component';
import { TrendingStreamingComponent } from './components/common/trending-streaming/trending-streaming.component';
import { StreamingMainBannerComponent } from './components/pages/streaming-home/streaming-main-banner/streaming-main-banner.component';
import { GalleryPageComponent } from './components/pages/gallery-page/gallery-page.component';
import { TeamPageComponent } from './components/pages/team-page/team-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { SponsorsPageComponent } from './components/pages/sponsors-page/sponsors-page.component';
import { FaqPageComponent } from './components/pages/faq-page/faq-page.component';
import { ComingSoonPageComponent } from './components/pages/coming-soon-page/coming-soon-page.component';
import { ErrorPageComponent } from './components/pages/error-page/error-page.component';
import { ProductsListPageOneComponent } from './components/pages/products-list-page-one/products-list-page-one.component';
import { ProductsListPageTwoComponent } from './components/pages/products-list-page-two/products-list-page-two.component';
import { CartPageComponent } from './components/pages/cart-page/cart-page.component';
import { CheckoutPageComponent } from './components/pages/checkout-page/checkout-page.component';
import { ProductsDetailsPageComponent } from './components/pages/products-details-page/products-details-page.component';
import { ProfileAuthenticationPageComponent } from './components/pages/profile-authentication-page/profile-authentication-page.component';
import { BlogGridTwoInRowPageComponent } from './components/pages/blog-grid-two-in-row-page/blog-grid-two-in-row-page.component';
import { BlogGridThreeInRowPageComponent } from './components/pages/blog-grid-three-in-row-page/blog-grid-three-in-row-page.component';
import { BlogMasonryPageComponent } from './components/pages/blog-masonry-page/blog-masonry-page.component';
import { BlogRightSidebarPageComponent } from './components/pages/blog-right-sidebar-page/blog-right-sidebar-page.component';
import { BlogLeftSidebarPageComponent } from './components/pages/blog-left-sidebar-page/blog-left-sidebar-page.component';
import { BlogDetailsPageComponent } from './components/pages/blog-details-page/blog-details-page.component';
import { FixturesPageComponent } from './components/pages/fixtures/fixtures-page.component';
import { TournamentsDetailsPageComponent } from './components/pages/tournaments-details-page/tournaments-details-page.component';
import { MatchDetailsPageComponent } from './components/pages/match-details-page/match-details-page.component';
import { TeamDetailsPageComponent } from './components/pages/team-details-page/team-details-page.component';
import { PlayerDetailsPageComponent } from './components/pages/player-details-page/player-details-page.component';
import { AboutComponent } from './components/pages/about/about.component';
import { MembershipComponent } from './components/pages/membership/membership.component';
import { MapComponent } from './components/common/map/map.component';
import { NewsDetailsComponent } from './components/pages/news-detail/news-detail-page.component';
import { VenuePageComponent } from './components/pages/venue/venue.component';
import { VenueDetailsPageComponent } from './components/pages/venue-detail/venue-detail.component';
import { loginComponent } from './components/pages/login/login.component';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { signupComponent } from './components/pages/signup/signup.component';
import { forgetPasswordComponent } from './components/pages/forgetPassword/forgetPassword.component';
import {MainInterceptor} from './main.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { RouterModule } from '@angular/router';
import {CommonModule} from '@angular/common';
import { InitialLoaderComponent } from './components/initial-loader/initial-loader.component';
import { OwlModule } from 'ngx-owl-carousel';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { SuccessPaymentComponent } from './components/pages/payment-details/success-payment/success-payment.component';
import { TournamentsCompetitionRoundComponent } from './components/pages/tournaments-competition-round/tournaments-competition-round.component';
import { TournamentsGroupsComponent } from './components/pages/tournaments-groups/tournaments-groups.component';
import { VenueListComponent } from './components/pages/venue-list/venue-list.component';
import { MembershipListComponent } from './components/pages/membership-list/membership-list.component';
import { MembershipDetailsPageComponent } from './components/pages/membership-details-page/membership-details-page.component';
import { ServiceListComponent } from './components/pages/service-list/service-list.component';
import { StandingComponent } from './components/pages/tournaments-groups/standing/standing.component';
import { NgxPaginationModule} from 'ngx-pagination';
import { MenuSectionDetailsComponent} from './components/pages/menu-section-details/menu-section-details.component'
import { MediaComponent } from './components/pages/media/media.component'
import { PartnerForCMPComponent} from './components/common/partner-cmp/partner-cmp.component';
import { BookServiseComponent} from './components/pages/book-servise/book-servise.component';
import { MyDatePickerModule } from 'mydatepicker';
import { VenueViewComponent } from './components/pages/venue-view/venue-view.component';
import { VenuePlayerHeaderComponent } from './components/pages/venue-player-header/venue-player-header.component';
import {RatingModule} from "ngx-rating";
import { VenueSlotsPlayerComponent } from './components/pages/venue-slots-player/venue-slots-player.component';
import { VenueBookingFormComponent } from './components/pages/venue-booking-form/venue-booking-form.component';
import { MediaListPlayerComponent } from './components/pages/media-management/media-list-player/media-list-player.component';
import { CompHeaderPlayerComponent } from './components/pages/media-management/comp-header-player/comp-header-player.component';
import { MembHeaderPlayerComponent } from './components/pages/media-management/memb-header-player/memb-header-player.component';
import { PlayerVenueSponsorComponent } from './components/pages/media-management/player-venue-sponsor/player-venue-sponsor.component';
import { VenueSponsorComponent } from './components/pages/media-management/venue-sponsor/venue-sponsor.component';
import { BasketballReportComponent } from './components/pages/tournaments-groups/basketball-report/basketball-report.component';
import { VolleyballReportComponent } from './components/pages/tournaments-groups/volleyball-report/volleyball-report.component';
import { SwimingReportComponent } from './components/pages/tournaments-groups/swiming-report/swiming-report.component';
import { CricketReportComponent } from './components/pages/tournaments-groups/cricket-report/cricket-report.component';
import { BadmintonReportComponent } from './components/pages/tournaments-groups/badminton-report/badminton-report.component';
import { SoccerReportComponent } from './components/pages/tournaments-groups/soccer-report/soccer-report.component';
import { TennisReportComponent } from './components/pages/tournaments-groups/tennis-report/tennis-report.component';
import { GenericReportComponent } from './components/pages/tournaments-groups/generic-report/generic-report.component';

import {GroupMatchReportComponent} from './components/pages/match-details-page/group-match-report/group-match-report.component';

import {GroupReportBadmintonComponent} from './components/pages/match-details-page/match-report-badminton/match-report-badminton.component';


import { GroupReportCricketComponent } from './components/pages/match-details-page/match-report-cricket/match-report-cricket.component';
import { GroupReportObservationComponent } from './components/pages/match-details-page/match-report-observation/match-report-observation.component';
import { GroupReportBasketBallComponent } from './components/pages/match-details-page/match-report-basket-ball/match-report-basket-ball.component';
import { GroupReportGenericTeamComponent } from './components/pages/match-details-page/match-report-generic-team/match-report-generic-team.component';
import { GroupReportGenericComponent } from './components/pages/match-details-page/match-report-generic/match-report-generic.component';
import { GroupReportSoccerComponent } from './components/pages/match-details-page/match-report-soccer/match-report-soccer.component';
import { GroupReportTableTennisComponent } from './components/pages/match-details-page/match-report-table-tennis/match-report-table-tennis.component';
import { GroupReportVoleyBallComponent } from './components/pages/match-details-page/match-report-voley-ball/match-report-voley-ball.component';
import { GroupReportSwimmingComponent } from './components/pages/match-details-page/match-report-swimming/match-report-swimming.component';
import {LeaderBoardComponent} from './components/pages/service-list/Leaderboard/leaderboard.component'
import { MediaMemberComponent} from './components/pages/service-list/media-member/media-member.component'
import  {MediaVenueComponent} from './components/pages/media-management/media-venue/media-venue.component'
import  { MediaCompetitionComponent} from './components/pages/media/media-competition/media-competition.component'
import {LeaderComponentNew} from './components/pages/leaderboard/leader.component'
import {EvaluationComponent1} from './components/pages/evaluation/evaluation.component'

@NgModule({
  declarations: [
    AppComponent,
  
    PreloaderComponent,
    MediaMemberComponent,
    MediaCompetitionComponent,
    MediaVenueComponent,
    LeaderBoardComponent,
    MainHomeComponent,
    AwardsComponent,
    EsportsHomeComponent,
    FooterStyleOneComponent,
    SocialComponent,
    SubscribeStyleOneComponent,
    PartnerComponent,
    PartnerForCMPComponent,
    ShopStyleOneComponent,
    TeamComponent,
    NewsComponent,
    MatchesStyleOneComponent,
    MainBannerComponent,
    NavbarStyleOneComponent,
    BlogComponent,
    ShopStyleTwoComponent,
    LiveStreamComponent,
    TopRankingTeamStyleOneComponent,
    MatchesStyleTwoComponent,
    EsportsMainBannerComponent,
    NavbarStyleTwoComponent,
    BostingHomeComponent,
    FooterStyleTwoComponent,
    HistoryComponent,
    SubscribeStyleTwoComponent,
    FeedbackComponent,
    OurExpertsComponent,
    GamesComponent,
    FeaturedGamesComponent,
    ServicesComponent,
    BostingMainBannerComponent,
    MagazineHomeComponent,
    MagazineMainBannerComponent,
    MagazineNewsComponent,
    TournamentsHomeComponent,
    StreamingHomeComponent,
    TopRankingTeamStyleTwoComponent,
    TrendingStreamsComponent,
    UpcomingTournamentsComponent,
    PopularLeaguesComponent,
    TournamentsMainBannerComponent,
    UpcomingStreamingComponent,
    TrendingStreamingComponent,
    StreamingMainBannerComponent,
    GalleryPageComponent,
    TeamPageComponent,
    ContactPageComponent,
    SponsorsPageComponent,
    FaqPageComponent,
    ComingSoonPageComponent,
    ErrorPageComponent,
    ProductsListPageOneComponent,
    ProductsListPageTwoComponent,
    CartPageComponent,
    CheckoutPageComponent,
    ProductsDetailsPageComponent,
    ProfileAuthenticationPageComponent,
    BlogGridTwoInRowPageComponent,
    BlogGridThreeInRowPageComponent,
    BlogMasonryPageComponent,
    BlogRightSidebarPageComponent,
    BlogLeftSidebarPageComponent,
    BlogDetailsPageComponent,
    FixturesPageComponent,
    TournamentsDetailsPageComponent,
    MatchDetailsPageComponent,
    TeamDetailsPageComponent,
    PlayerDetailsPageComponent,
    AboutComponent,
    MembershipComponent,
    MapComponent,
    NewsDetailsComponent,
    VenuePageComponent,
    VenueDetailsPageComponent,
    loginComponent,
    ProfileComponent,
    signupComponent,
    forgetPasswordComponent,
    InitialLoaderComponent,
    SuccessPaymentComponent,
    TournamentsCompetitionRoundComponent,
    TournamentsGroupsComponent,
    MembershipListComponent,
    ServiceListComponent,
    MembershipDetailsPageComponent,
    
    VenueListComponent,
    
    StandingComponent,
    MenuSectionDetailsComponent,
    MediaComponent,
    // ProfessnalListComponent
    BookServiseComponent,
    VenueViewComponent,
    VenuePlayerHeaderComponent,
    VenueSlotsPlayerComponent,
    VenueBookingFormComponent,
    MediaListPlayerComponent,
    CompHeaderPlayerComponent,
    MembHeaderPlayerComponent,
    PlayerVenueSponsorComponent,
    VenueSponsorComponent,
    BasketballReportComponent,
    VolleyballReportComponent,
    SwimingReportComponent,
    CricketReportComponent,
    BadmintonReportComponent,
    SoccerReportComponent,
    TennisReportComponent,
    GenericReportComponent,

    GroupMatchReportComponent,
    GroupReportBadmintonComponent,
    GroupReportBasketBallComponent,
    GroupReportCricketComponent,
    GroupReportObservationComponent,
    GroupReportBadmintonComponent,
    GroupReportSwimmingComponent,
    GroupReportVoleyBallComponent,
    GroupReportTableTennisComponent,
    GroupReportSoccerComponent,
    GroupReportGenericComponent,
    GroupReportGenericTeamComponent,
    EvaluationComponent1,
    LeaderComponentNew,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      preventDuplicates: true
    }),
    RouterModule,
    AppRoutingModule,
    CommonModule,
    OwlModule,
    NgbModule,
    NgxPaginationModule,
    MyDatePickerModule,
    RatingModule
  ],
  providers: [
    MainService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MainInterceptor,
      multi: true
    }
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [AppComponent]
})
export class AppModule { }
