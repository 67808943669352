<div class="tab-content" *ngIf="!observation">
    <div id="Starts" class=" tab-pane active show">
       <div class="row">
          <div class="col-lg-12">
             <div class="scoreboard-table-block1">
                <div class="scoreboard-table-inner">
                  <div class="status-content-block">
                    <div>
                      <div>
                         <h3 style="text-align: center; margin-top: 10px;"> Competition Details </h3>
                         <table class="table table-bordered large-table">
                            <thead>
                               <tr class="table-head-bg">
                                  <th>Competition</th>
                                  <th>Division</th>
                                  <th>Period</th>
                                  <th>Date</th>
                                  <th>Venue</th>
                               </tr>
                            </thead>
                            <tbody>
                               <tr >
                                  <td>{{gameData?.competitionData?.competitionName}}</td>
                                  <td>{{ gameData?.competitionData?.division }}</td>
                                  <td>{{ gameData?.competitionData?.period}}</td>
                                  <td>{{ gameData?.competitionData?.startDate | date:'mediumDate' }}</td>
                                  <td>{{ gameData?.competitionData?.venue }}</td>
                               </tr>
                            </tbody>
                         </table>
                      </div>
                   </div>
                   <h3 style="text-align: center;" > Statistics </h3>
                   </div>
                    <div class="table-scrool-box">
                    <div class="table-block mt30">
                       <div class="table-responsive">
                           <table class="table table-bordered text-center">
                              <thead>
                                <tr class="table-head-bg">
                                  <th></th>
                                  <th>Total Score</th>
                                  <th>Total Outs</th>
                                  <th>Net Score</th>
                                  <th>Leading Skins</th>
                                  <th>Lagging Skins</th>
                                  <th>Best Batsman</th>
                                  <th>Best Bowler</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr >
                                  <td>{{ cricketGameDetailsData?.result?.teamDeatailsOne[0].teamDetails[0].teamName }}</td>
                                  <td>{{cricketGameDetailsData?.result?.teamDeatailsOne[0].totalScore }}</td>
                                  <td>{{cricketGameDetailsData?.result?.teamDeatailsOne[0].totalOut }}</td>
                                  <td>{{ cricketGameDetailsData?.result?.teamDeatailsOne[0].netScore }}</td>
                                  <td>{{cricketGameDetailsData?.result?.teamDeatailsOne[0].leadingSkins }}</td>
                                  <td>{{ cricketGameDetailsData?.result?.teamDeatailsOne[0].laggingSkins }}</td>
                                  <td *ngIf="!cricketGameDetailsData?.result?.teamDeatailsOne[0]?.bestBatsman">select from Team A - player names</td>
                                  <td *ngIf="cricketGameDetailsData?.result?.teamDeatailsOne[0]?.bestBatsman">{{ cricketGameDetailsData?.result?.teamDeatailsOne[0].bestBatsman }}</td>

                                  <td *ngIf="!cricketGameDetailsData?.result?.teamDeatailsOne[0]?.bestBowler">select from Team A - player names</td>
                                  <td *ngIf="cricketGameDetailsData?.result?.teamDeatailsOne[0]?.bestBowler">{{ cricketGameDetailsData?.result?.teamDeatailsOne[0].bestBowler}}</td>
                                </tr>
                                <tr >
                                  <td>{{cricketGameDetailsData?.result?.teamDeatailsTwo[0].teamDetails[0].teamName }}</td>
                                  <td>{{ cricketGameDetailsData?.result?.teamDeatailsTwo[0].totalScore }}</td>
                                  <td>{{ cricketGameDetailsData?.result?.teamDeatailsTwo[0].totalOut }}</td>
                                  <td>{{cricketGameDetailsData?.result?.teamDeatailsTwo[0].netScore }}</td>
                                  <td>{{ cricketGameDetailsData?.result?.teamDeatailsTwo[0].leadingSkins }}</td>
                                  <td>{{ cricketGameDetailsData?.result?.teamDeatailsTwo[0].laggingSkins }}</td>
                                  <td *ngIf="!cricketGameDetailsData?.result?.teamDeatailsTwo[0]?.bestBatsman">select from Team B - player names</td>
                                  <td *ngIf="cricketGameDetailsData?.result?.teamDeatailsTwo[0]?.bestBatsman">{{ cricketGameDetailsData?.result?.teamDeatailsTwo[0].bestBatsman }}</td>

                                  <td *ngIf="!cricketGameDetailsData?.result?.teamDeatailsTwo[0]?.bestBowler">select from Team B - player names</td>
                                  <td *ngIf="cricketGameDetailsData?.result?.teamDeatailsTwo[0]?.bestBowler">{{ cricketGameDetailsData?.result?.teamDeatailsTwo[0].bestBowler}}</td>
                                </tr>
                              </tbody>
                            </table>
                       </div>
                    </div>
              <!-- Button trigger Team Edit modal -->
              

                    <div class="table-block mt10"  >
                       <div class="table-responsive">
                           <table class="table table-bordered text-center">
                              <thead>
                                <tr class="table-head-bg">
                                  <th>{{ cricketGameDetailsData?.result?.teamDeatailsOne[0]?.teamDetails[0]?.teamName }}</th>
                                  <th colspan="7"> Batting Performance</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="strong-color">
                                  <th>Skin</th>
                                  <th>Player No.</th>
                                  <th>Player First Name</th>
                                  <th>Player Last Name</th>
                                  <th>Runs</th>
                                </tr>
                                <tr *ngFor = " let data of cricketGameDetailsData?.result?.teamOne">
                                 <td>{{ data?.skin}}</td>
                                  <td>{{ data?.playerNumber }}</td>
                                  <td>{{ data?.userDetails[0]?.firstName }}</td>
                                  <td>{{ data?.userDetails[0]?.lastName }}</td>
                                  <td>{{ data?.runs }}</td>
                                </tr> 
                              </tbody>
                            </table>
                       </div>
                       <!--Button Trigger Edit Player's performance Modal -->
                      
                    </div>



                    
                     <!-- Button trigger Edit Player's modal -->
                     


                      <div class="table-block mt20">
                       <div class="table-responsive">
                           <table class="table table-bordered text-center">
                              <thead>
                                <tr class="table-head-bg">
                                  <th>{{ cricketGameDetailsData?.result?.teamDeatailsTwo[0]?.teamDetails[0]?.teamName }}</th>
                                  <th colspan="7"> Batting Performance</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="strong-color">
                                  <th>Skin</th>
                                  <th>Player No.</th>
                                  <th>Player First Name</th>
                                  <th>Player Last Name</th>
                                  <th>Runs</th>
                                </tr>
                                <tr *ngFor = " let data of cricketGameDetailsData?.result?.teamTwo">
                                  <td>{{ data?.skin }}</td>
                                  <td>{{ data?.playerNumber }}</td>
                                  <td>{{ data?.userDetails[0]?.firstName }}</td>
                                  <td>{{ data?.userDetails[0]?.lastName }}</td>
                                  <td>{{ data?.runs }}</td>
                                </tr>
                               
                              </tbody>
                            </table>
                       </div>
                    </div>
                  </div>
               
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>

<!-- Observation -->
<div id="Information" *ngIf="observation">
  <div class="tab-inner-text-block">
    <div class="col-md-12 col-lg-12 marginzero">
      <textarea class="form-control common-textarea" readonly [(ngModel)]="observationMessage"></textarea>
    
    </div>
  </div>
</div>

