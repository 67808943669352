import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/providers/main-service.service';
import { Router,ParamMap,Params,ActivatedRoute} from '@angular/router'
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-media-venue',
  templateUrl: './media-venue.component.html',
  styleUrls: ['./media-venue.component.scss']
})
export class MediaVenueComponent implements OnInit {
  gameId : any
  competitionId : any
  gameDetail : any
  userDetail: any;
  membershipId: any;

  constructor(private service: MainService, private activatedRoute: ActivatedRoute,public sanitizer: DomSanitizer) {
    this.userDetail = this.service.isLoginVisitUser()
    this.activatedRoute.queryParams.subscribe((res)=>{
      this.gameId = res.gameId
    // this.competitionId = res['compId']
      // this.membershipId =res.membershipId
    })
    this.activatedRoute.params.subscribe(async params => {
    console.log("Params---->>>> ",JSON.stringify(params));
      this.competitionId = params['compId']
    })
   }

  ngOnInit(): void {
    this.getMediaApi()
    
  }

  getMediaApi(){
    let url = this.service.isRolePlayer ?  `media/mediaList?userId=${this.userDetail?._id}&venueId=${this.competitionId}` : `media/getListofMedia?userId=${this.userDetail._id}`
    this.service.postApi(url,this.bodyData,0).subscribe((res)=>{
      if(res.responseCode == 200){
        this.gameDetail = res.result.docs
        for (let i = 0; i < res.result.docs.length; i++) {
          if (res.result.docs[i].typeOfMedia == "VIDEO") {
            res.result.docs[i].securedMediaURL = this.sanitizer.bypassSecurityTrustResourceUrl(res.result.docs[i].youtubeUrls)
          }
          if (res.result.docs[i].typeOfMedia == "ALBUM") {
            var mediaUrls = res.result.docs[i].mediaUrls;
            if (mediaUrls.length > 4) {
              var newMediaUrls = []
              for (let i = 0; i < 4; i++) {
                newMediaUrls.push(mediaUrls[i])
              }
              res.result.docs[i].newMediaUrls = newMediaUrls;
              res.result.docs[i].newMediaUrlsLength = mediaUrls.length - 4;
            } else {
              res.result.docs[i].newMediaUrls = mediaUrls;
              res.result.docs[i].newMediaUrlsLength = 0;
            }
          }
        }
      }
    })
  }
  bodyData ={"page":1,"limit":5000,typeOfMedia: ""}
    typeOfMedia: string = 'ALL';
  allMedia() {
    this.typeOfMedia = 'ALL';
    this.bodyData ={"page":1,"limit":5000,typeOfMedia: ""}
    this.getMediaApi();
  }
  albumMedia() {
    this.typeOfMedia = 'ALBUM';
    this.bodyData ={"page":1,"limit":5000,typeOfMedia: "ALBUM"}
    this.getMediaApi();
  }
  videoMedia() {
    this.typeOfMedia = 'VIDEO';
    this.bodyData ={"page":1,"limit":5000,typeOfMedia: "VIDEO"}
    this.getMediaApi();
  }
  newsMedia() {
    this.typeOfMedia = 'NEWS';
    this.bodyData ={"page":1,"limit":5000,typeOfMedia: "NEWS"}
    this.getMediaApi();
  }
}