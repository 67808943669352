<div class="table-responsive">
    <table class="table table-bordered">
        <thead>
            <tr class="table-head-bg">
               <th class="empty-tb"></th>
               <th colspan="3" class="custom-top-heading">Q1</th>
               <th colspan="3" class="custom-top-heading">Q2</th>
               <th colspan="3" class="custom-top-heading">Q3</th>
               <th colspan="3" class="custom-top-heading">Q4</th>
               <th colspan="3" class="custom-top-heading">Extra Period</th>
               <th colspan="3" class="empty-tb"></th>

            </tr>
            <tr class="table-head-bg">
               <th class="custom-top-heading"></th>
               <th>Q1 Score</th>
               <th>Team Fouls</th>
               <th>Time Outs</th>
               <th>Q2 Score</th>
               <th>Team Fouls</th>
               <th>Time Outs</th>
               <th>Q3 Score</th>
               <th>Team Fouls</th>
               <th>Time Outs</th>
               <th>Q4 Score</th>
               <th>Team Fouls</th>
               <th>Time Outs</th>
               <th>Q5 Score</th>
               <th>Team Fouls</th>
               <th>Time Outs</th>
               <th>Final Score</th>
               <th>MVP</th>
            </tr>
         </thead>
        <tbody>
            <tr *ngFor="let data of basketballGameDetailsData?.result?.teamDeatailsOne ">
                <td>{{ data?.teamDetails[0]?.teamName}}</td>
                <td *ngFor=" let item of data?.scoreData" colspan="3">
                    <div style="display: flex;
                    justify-content: space-around;">
                        <span class="span">
                            {{item?.score}}
                         </span>
                         <span class="spanMiddle">
                            {{item?.fouls}}
                         </span>
                         <span>
                            {{item?.timeOut}}
                         </span>
                    </div>
                  
                </td>
                <td>{{ finalScoreOne }}</td>
                <td *ngIf="!playerOne">select from Team A - player names</td>
                <td *ngIf="playerOne">{{ playerOne }}</td>
             </tr>
             <tr *ngFor="let data of basketballGameDetailsData?.result?.teamDeatailsTwo;let i=index">
                <td>{{ data?.teamDetails[0]?.teamName }}</td>
                <td *ngFor=" let item of data?.scoreData" colspan="3">
                    <div style="display: flex;
                    justify-content: space-around;">
                        <span class="span">
                            {{item?.score}}
                         </span>
                         <span class="spanMiddle">
                            {{item?.fouls}}
                         </span>
                         <span>
                            {{item?.timeOut}}
                         </span>
                    </div>
                </td>
                <td>{{ finalScoreTwo }}</td>
                <td *ngIf="!playerTwo">select from Team B - player names</td>
                <td *ngIf="playerTwo">{{ playerTwo }}</td>
             </tr>
          
        </tbody>
    </table>
</div>


<div class="table-responsive mt-5">
    <table class="table table-bordered">
        <thead>
            <tr class="table-head-bg">
                <th colspan="5" class="custom-top-heading text-align-left">{{
                   basketballGameDetailsData?.result?.teamDeatailsOne[0]?.teamDetails[0]?.teamName }}</th>
             </tr>
             <tr class="table-head-bg">
                <th>Player No.</th>
                <th>Player First Name</th>
                <th>Player Last Name</th>
                <th>Baskets</th>
                <th>Fouls</th>
             </tr>
         </thead>
        <tbody>
            <tr *ngFor="let data of basketballGameDetailsData?.result?.teamOne; let i = index">
                <td>{{data?.playerNumber}}</td>
                <td>{{data?.userDetails[0]?.firstName}}</td>
                <td>{{data?.userDetails[0]?.lastName}}</td>
                <td>{{data?.baskets}}</td>
                <td>{{data?.fouls}}</td>
             </tr>
             <tr *ngIf="!basketballGameDetailsData?.result?.teamOne?.length">
                <td colspan="9">No Record Found</td>
             </tr>
          
        </tbody>
    </table>
</div>

<div class="table-responsive mt-5">
    <table class="table table-bordered">
        <thead>
            <tr class="table-head-bg">
                <th colspan="5" class="custom-top-heading text-align-left">{{
                   basketballGameDetailsData?.result?.teamDeatailsTwo[0]?.teamDetails[0]?.teamName }}</th>
             </tr>
             <tr class="table-head-bg">
                <th>Player No.</th>
                <th>Player First Name</th>
                <th>Player Last Name</th>
                <th>Baskets</th>
                <th>Fouls</th>
             </tr>
         </thead>
        <tbody>
            <tr *ngFor="let data of basketballGameDetailsData?.result?.teamTwo; let i = index">
                <td>{{data?.playerNumber}}</td>
                <td>{{data?.userDetails[0]?.firstName}}</td>
                <td>{{data?.userDetails[0]?.lastName}}</td>
                <td>{{data?.baskets}}</td>
                <td>{{data?.fouls}}</td>
             </tr>
             <tr *ngIf="!basketballGameDetailsData?.result?.teamTwo?.length">
                <td colspan="9">No Record Found</td>
             </tr>
          
        </tbody>
    </table>
</div>
