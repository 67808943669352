import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/providers/main-service.service';

@Component({
  selector: 'app-membership-details-page',
  templateUrl: './membership-details-page.component.html',
  styleUrls: ['./membership-details-page.component.scss']
})
export class MembershipDetailsPageComponent implements OnInit {
  gameId : any
  membershipId : any
  venueId : any
  gameDetail : any
  url = ``
  venueName: any;
  membershipName: any;
  headingName: any;
  constructor(private service: MainService, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe((res)=>{
      if('membershipId' in res){
        this.membershipId = res.membershipId
        this.url = `organizer/getMembershipMedia?membershipId=${this.membershipId}`
      }
      else {
        this.venueId = res.venueId
        this.url = `organizer/getVenueMedia?venueId=${this.venueId}`
      }
      
    })
   }

  ngOnInit(): void {
    this.getGameDetail()
  }

  getGameDetail(){
    this.service.getApi(this.url,0).subscribe((res)=>{
      if(res.responseCode == 200 && res.result.length>0){
        this.headingName =this.membershipId  ? res.result[0].membershipName : res.result[0].venueName
        this.gameDetail = res.result
      }
    })
  }
}
