import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/providers/main-service.service';

@Component({
  selector: 'app-products-details-page',
  templateUrl: './products-details-page.component.html',
  styleUrls: ['./products-details-page.component.scss']
})
export class ProductsDetailsPageComponent implements OnInit {

  productDetailId : any 
  productDetail : any 
  quantity = 0
  item_quantity = 1
  selectedSize : any
  price : any
  constructor(private service: MainService,private router : Router,private activatedRoute : ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe((res)=>{
      this.productDetailId = res._id
    })
   }

  ngOnInit(): void {
    this.getProductDetail()
  }
  getProductDetail(){
    let url = `product/getProductDetailByUserId?productId=${this.productDetailId}`
    this.service.postApi(url,{},0).subscribe(response => {
      if(response.responseCode == 200) {    
        this.productDetail = response.result   
        this.productDetail.quantity = this.productDetail.quantity || '1'
        this.quantity = this.productDetail.price_size_qunatity[0].quantity
        this.selectedSize = this.productDetail.price_size_qunatity[0].size
        this.price = this.productDetail.price_size_qunatity[0].price
        this.categoryType = this.productDetail.competitionDetail._id ? 'COMPETITION' : 'MEMBERSHIP'
        this.realatedProductList()
       this.service.toastrSucc(response.responseMessage)
      }
      else if(response.responseCode === 409 ){
      }
    })
  }

  selectSize(item){
    this.selectedSize = item.size
    this.price = item.price
    this.quantity = item.quantity
    this.item_quantity = 1      
  }
  userId : any
  addToCart(_id){
    this.userId = this.service.getStorage('userId');
    const data ={
      quantity : this.item_quantity,
      size : this.selectedSize,
      price : this.price,
    }
    let url = `product/addToCart?userId=${this.userId}&productId=${_id}`
    this.service.postApi(url,data,0).subscribe(response => {
      if(response.responseCode == 200) {       
       this.router.navigate(['/cart'])
       this.service.toastrSucc(response.responseMessage)
      }
      else if(response.responseCode === 409 ){
        this.router.navigate(['/cart'])
      }
    })
  }
  updateQuantity(e){
    if(Number(this.item_quantity) < 1 || Number(this.item_quantity) >=  Number( this.quantity)){
  
      return
    }
    
    if(e == 1){
      this.item_quantity = Number(this.item_quantity) - 1
    }
    else{
      this.item_quantity = Number(this.item_quantity) + 1
    }


  }
  productsList : any = []
  categoryType
  realatedProductList(){
    this.userId = this.service.getStorage('userId');
    let url = `product/realatedProductList?organizerId=${this.userId}&categoryType=${this.categoryType}`
    this.userId = this.service.getStorage('userId');
    this.service.getApi(url,0).subscribe(response => {
      if(response.responseCode == 200) {       
        this.productsList = response.arr;
        this.productsList = this.productsList.slice().reverse();
      }
    })
  }
}
