import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/providers/main-service.service';
declare var $
@Component({
  selector: 'app-cart-page',
  templateUrl: './cart-page.component.html',
  styleUrls: ['./cart-page.component.scss']
})
export class CartPageComponent implements OnInit {
  cartList : any = []
  userId : any
  subtotal : any
  isLoggedIn : boolean = false
  constructor(private service: MainService,private router : Router ) { }

  ngOnInit(): void {
    this.getcartlist()

  }
  getcartlist(){
    this.userId = this.service.getStorage('userId');
    let url = `product/cartList?userId=${this.userId}`
    
    this.service.postApi(url,{},0).subscribe(response => {
      if(response.responseCode == 200) {    
        this.cartList = response.result   
        this.subtotal = response.subtotal
       this.service.toastrSucc(response.responseMessage)
      }
      else if(response.responseCode === 409 ){
        this.cartList = []
      }
    })
  }
  removecart(id){
    let url = `product/removeFromCart?cartId=${id}`
    
    this.service.deleteAPI(url,0).subscribe(response => {
      if(response.responseCode == 200) {    
      this.getcartlist()
       this.service.toastrSucc(response.responseMessage)
      }
      else if(response.responseCode === 409 ){
      }
    })
  }

  totalQuantity(item) : number{
    console.log(item)
    let arr = item.productId.price_size_qunatity.find((res)=>{
      return res.size == item.size
    })
    console.log(arr);
    
    return Number(arr.quantity)
  }
  updateQuantity(e,item){
    if(e == 1) {
      if(Number(item.quantity) - 1 <= 0){
        return
      }
      item.quantity = Number(item.quantity) - 1

    }
    else{
      if(Number(item.quantity) + 1 >= this.totalQuantity(item)){
        return
      }
      item.quantity = Number(item.quantity) + 1
      
    }
    item.totalPrice = Number(item.quantity) * Number(item.price)
    // console.log(quantity);
    
  }
  openModal(){
    $('#myModal').on('shown.bs.modal', function () {
      
    })
  }
  updateAllCartValue(){
    let arr = []
    for(let item of this.cartList){
      let obj = {
        _id : item?._id,
        quantity : item.quantity,
        totalPrice : item.totalPrice,
      }
      arr.push(obj)
    }
    let data = {
      cart :  arr
    }
    let url = `product/updateAllCart`
    this.service.postApi(url,data,0).subscribe(response => {
      console.log(response);
      if(response.responseCode == 200) {    
       console.log(response);
       this.getcartlist()
       this.service.toastrSucc(response.responseMessage)
      }
      else if(response.responseCode === 409 ){
        this.cartList = []
      }
    })
    console.log(data);
    
  }
  navigate(){
     $('#exampleModalCenter').modal('hide')
    this.router.navigate(['/checkout'])
  }
}
