<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <h1 title="{{'Leaderboard'}} ">{{'Leaderboard'}} </h1>
        </div>
    </div>
</section>
<main class="main-container pt0 mt-min60">
    <div class="container">
        <div class="content-box bg-gray">
            <h2 class="content-box-head"></h2>
            <div class="content-box-filter">
                <form class="filter-form fullwidth" [formGroup]="filterForm">
                    <div class="row align-items-center">
                        <div class="col-md-3">
                            <div class="filter-box ">
                                <label class="control-label">MemberShip Name</label>
                                <select class="form-control select-style1" formControlName="membershipName" (change)="getMembershipId($event)">
                          <option value="">All</option>
                          <option  *ngFor="let i of memberList"  value="{{i?.membershipName}}">{{i?.membershipName}}</option>
                          </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="filter-box">
                                <label class="control-label">Service</label>
                                <select class="form-control select-style1" formControlName="serviceName" (change)="getServiceId($event)">
                          <option value="">Select Service</option>
                           <option  *ngFor="let i of servicelist"  value="{{i?.serviceName}}">{{i?.serviceName}}</option>
                         </select>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="filter-box">
                                <label class="control-label">Select month</label>
                                <select class="form-control select-style1" formControlName="serviceDate">
                          <option value="">Select month</option>
                           <option  *ngFor="let i of serviceMonth"  value="{{i?.val}}">{{i?.name}}</option>
                         </select>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="filter-box">
                                <label class="control-label">Year</label>
                          <select class="form-control select-style1" formControlName="selectYear" >
                          <option value="">Select year</option>
                           <option  *ngFor="let i of serviceYear "   [value]="i">{{i}}</option>
                         </select>
                            </div>
                        </div>
                       
                        <div style="margin-top: 52px;" class="col-md-2 ">
                            <!-- <button class="btn btn btn-dark-blue-s max-WT-150" [disabled]="!filterForm.valid" (click)="getLeaderBoard(filterForm.value)">Apply</button> -->
                            <a   (click)="getLeaderBoard(filterForm.value)"  class="small-btn">Apply</a>
                        </div>
                    </div>
                </form>
            </div>
            <div class="table-block mt10 white-bg">
                <div class="table-responsive" id="tablerecords">
                    <figure class="only_print">
                        <!-- <img [src]='profileData?.organiserLogo' alt="" srcset="" style="width: 100px;"> -->

                    </figure>
                    <table class="table table-bordered  text-center">
                        <thead>
                            <tr class="table-head-bg">
                                <th>Player Name</th>
                                <th>Points</th>
                               
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngIf="show == 'hide'">
                              <span style="color:white;">No Data Found Apply Filter To Get Result!!</span>  
                            </tr>
                            
                            <tr *ngFor="let item of leaderboardList">
                                <td>{{item?.playerId?.firstName}} {{item?.playerId?.lastName}}</td>
                                <td>{{item?.leaderBoard[searchYear][searchMonth]}}</td>
                               
                            </tr>
                           
                        </tbody>
                    </table>

                </div>
              
            </div>
        </div>
    </div>
</main>




<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>