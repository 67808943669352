import { Component, Input,OnInit } from '@angular/core';
import { MainService } from 'src/app/providers/main-service.service';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {
  @Input() compID : any
  constructor(private service: MainService) { }

  userId: any;
  sponsorsList: any = [];

  ngOnInit(): void {
    this.getSponsorsList();
  }

  getSponsorsList(){
    this.userId = this.service.getStorage('userId');
    this.service.getApi(`data/listOfSponsorByUserId?userId=${this.userId}`,0).subscribe(response => {
      if(response.responseCode == 200 && response.result.length>0) {    
        this.sponsorsList = response.result;    
        // if(this.compID){
        //   let Data =[]
        //   // let Data = response.result.find(iok=>  this.compID == iok.visibleIn.length>0 && iok.visibleIn[0]._id) 
        //   response.result.forEach(iok => {
        //     if(iok.visibleIn.length>0){
        //       if(this.compID ===  iok.visibleIn[0]._id){
        //         Data.push(iok)
        //       }
        //     }
        //   });
        //   console.log(Data)
        //   this.sponsorsList = Data
        // }else{
        // this.sponsorsList = response.result;

        // }
      }
    })
  }
  openTab(link){
    window.open(link,'_blank')
  }

}
