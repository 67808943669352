import { Component, OnInit } from "@angular/core";
import { MainService } from "../../providers/main-service.service";
import { ActivatedRoute, Router } from "@angular/router";
@Component({
    selector: "app-initial-loader",
    templateUrl: "./initial-loader.component.html",
    styleUrls: ["./initial-loader.component.scss"],
})
export class InitialLoaderComponent implements OnInit {
    newurl: string;
    userId: String;

    constructor(
        public service: MainService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.newurl = window.location.host
        // this.newurl ='http://www.yalasportsevents.com//'
        // this.newurl ='www.yalasportsevents.com'
        let local =window.location.href
        if(local.includes('172.16.6') || local.includes('localhost')){
            this.newurl ='admin.tanver.com'
            // this.newurl ='tanveer-new.com'
            // this.newurl ='mysports.com'

        }else{
            this.newurl = window.location.host
        }
        let reqbody = {
            url: this.newurl,
        };
        this.service
            .postApi(`configuration_domain/getUrDetails`, reqbody, 0)
            .subscribe((response) => {
                localStorage.setItem("userId", response.result[0].userId);
                this.userId = response.result[0].userId;
            });
    }
}
