import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/providers/main-service.service';
@Component({
  selector: 'app-tournaments-home',
  templateUrl: './tournaments-home.component.html',
  styleUrls: ['./tournaments-home.component.scss']
})
export class TournamentsHomeComponent implements OnInit {

  constructor(public service : MainService) { }

  ngOnInit(): void { 
   
  }
}
