<div class="container">
    <div class="section-title">
        <span class="sub-title">TOURNAMENTS</span>
        <h2>Ongoing Leagues</h2>
    </div>

 

<div *ngIf="allComp?.length>0">
    <div class="single-popular-leagues-box" *ngFor="let item of allComp" >
      <div class="popular-leagues-box">
          <div class="popular-leagues-image">
              <div class="image bg1">
                  <img routerLink="/tournament-groups" [queryParams]="{id : item?._id}" src="{{item.imageURL}}" alt="image">
              </div>
          </div>

          <div class="popular-leagues-content">
              <div class="content">
                  <h3><a routerLink="/tournament-groups" [queryParams]="{id : item?._id}">{{item.competitionName}}</a></h3>
                   <p>Sports : {{item?.sports}} ({{item?.sportType}})</p>
                   <p>Club : {{item?.club}}</p>
                  <ul class="info">
                      <li><i class="flaticon-location"></i>{{item.venue}}</li>
                  </ul>
                  <ul class="info">
                    <li><i class="flaticon-website"></i> {{datePipe.transform(item.startDate, "dd-MMM-yyyy")}} To {{datePipe.transform(item.endDate, "dd-MMM-yyyy")}}</li>
                </ul>
                  <a routerLink="/tournament-groups" [queryParams]="{id : item?._id}" class="join-now-btn">Join Now</a>
              </div>
          </div>

          <div class="popular-leagues-date">
              <div class="date">
                  <div class="d-table">
                      <div class="d-table-cell">
                          <span>{{item.startDate | date:'yyyy'}}</span>
                          <h3>{{item.startDate | date:'dd LLLL'}}</h3>
                          <!-- <p>14:30 PM</p> -->
                          <i class='bx bx-calendar'></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
    <div *ngIf="allComp.length==0" style="text-align: center;">
        <h3>No Ongoing Tournaments Found</h3>
    </div>
</div>