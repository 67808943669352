<div class="table-responsive">
    <table class="table table-bordered">
        <thead>
            <tr class="table-head-bg">
                <th>Team Name</th>
                <th colspan="2">Set 1 Score</th>
                <th colspan="2">Set 2 Score</th>
                <th colspan="2">Set 3 Score</th>
                <th colspan="2">Set 4 Score</th>
                <th colspan="2">Set 5 Score</th>
                <th>Final Score</th>
                <th>MVP</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th></th>
                <th>Set 1 Score</th>
                <th>Time Outs</th>
                <th>Set 2 Score</th>
                <th>Time Outs</th>
                <th>Set 3 Score</th>
                <th>Time Outs</th>
                <th>Set 4 Score</th>
                <th>Time Outs</th>
                <th>Set 5 Score</th>
                <th>Time Outs</th>
                <th></th>
                <th></th>
            </tr>
            <tr>
                <th>{{ voleyBallGameDetailsData?.result?.teamDeatailsOne[0]?.teamDetails[0]?.teamName }}</th>
                <td colspan="2" *ngFor="let data of teamOneScore">
                    <div style="display: flex;justify-content:space-around">
                        <span class="span">{{ data.score }}</span>
                        <span>{{ data.timeOut }}</span>
                    </div>
                   
                </td>
                <td> {{ finalScoreOne }} </td>
                <td *ngIf="!mvpOfTeamOne">Select Player From Team
                    {{this.voleyBallGameDetailsData?.result?.teamDeatailsOne[0]?.teamDetails[0]?.teamName}}</td>
                <td *ngIf="mvpOfTeamOne">{{mvpOfTeamOne}}</td>
            </tr>
            <tr>
                <th>{{ voleyBallGameDetailsData?.result?.teamDeatailsTwo[0]?.teamDetails[0]?.teamName }}</th>
                <td colspan="2" *ngFor=" let data of teamTwoScore">
                    <div style="display: flex;justify-content:space-around">
                        <span class="span">{{data.score}}</span>
                        <span> {{data.timeOut}} </span>
                    </div>
                   
                </td>
                <td>{{ finalScoreTwo }}</td>
                <td *ngIf="!mvpOfTeamTwo">Select Player From Team
                    {{this.voleyBallGameDetailsData?.result?.teamDeatailsTwo[0]?.teamDetails[0]?.teamName}}</td>
                <td *ngIf="mvpOfTeamTwo">{{mvpOfTeamTwo}}</td>
            </tr>
        </tbody>
    </table>
</div>


<div class="table-responsive mt-5">
    <table class="table table-bordered">
        <thead>
            <tr class="table-head-bg">
                <th colspan="4" class="text-align-left">{{
                    voleyBallGameDetailsData?.result?.teamDeatailsOne[0]?.teamDetails[0]?.teamName }}</th>

            </tr>
         
        </thead>
        <tbody>
            <tr class="strong-color">
              <th>Player No</th>
              <th>Player First Name</th>
              <th>Player Last Name</th>
              <th>Points</th>
            </tr>
            <tr *ngFor=" let data of voleyBallGameDetailsData?.result?.teamOne">
              <td>{{ data.playerNumber }}</td>
              <td>{{ data.userDetails[0].firstName }}</td>
              <td>{{ data.userDetails[0].lastName }}</td>
              <td>{{ data.points }}</td>
            </tr>
          </tbody>
    </table>
</div>

<div class="table-responsive mt-5">
    <table class="table table-bordered">
        <thead>
            <tr class="table-head-bg">
              <th colspan="4"  class="text-align-left">{{ voleyBallGameDetailsData?.result?.teamDeatailsTwo[0]?.teamDetails[0]?.teamName }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="strong-color">
              <th>Player No</th>
              <th>Player First Name</th>
              <th>Player Last Name</th>
              <th>Points</th>
            </tr>
            <tr *ngFor=" let data of voleyBallGameDetailsData?.result?.teamTwo">
              <td>{{ data.playerNumber }}</td>
              <td>{{ data.userDetails[0].firstName }}</td>
              <td>{{ data.userDetails[0].lastName }}</td>
              <td>{{ data.points }}</td>
            </tr>
          </tbody>
    </table>
</div>