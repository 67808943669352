<div class="table-responsive">
    <table class="table table-bordered">
        <thead>
            <tr class="table-head-bg">
               <th>Player Name</th>
               <th>Team Name</th>
               <th>Set 1 score</th>
               <th>Set 2 score</th>
                <th>Set 3 score</th>
                <th>Set 4 score</th>
                <th>Set 5 score</th>
                <th>Set 6 score</th>
                <th>Set 7 score</th>
                <th>Full Score</th>
            </tr>
         </thead>
         <tbody>
            <tr>
               <td>
                 <p>{{basketballGameDetailsData?.teamDeatailsOne[0]?.playersDetails[0]?.firstName}}</p>
                 <p *ngIf="sportType == 'double'">{{basketballGameDetailsData?.teamDeatailsOne[0]?.playersDetails[0]?.lastName}}</p>
                </td>
               <td>{{basketballGameDetailsData?.teamDeatailsOne[0]?.teamDetails[0]?.teamName}}</td>
               <td *ngFor="let data of basketballGameDetailsData?.teamDeatailsOne[0]?.scoreData">{{ data.score }}</td>
               <td>{{ this.finalScoreOne }}</td>
            </tr>
            <tr>
               <td>
                 <p>{{basketballGameDetailsData?.teamDeatailsTwo[0]?.playersDetails[0]?.firstName}}</p>
                 <p *ngIf="sportType == 'double'">{{basketballGameDetailsData?.teamDeatailsTwo[0]?.playersDetails[0]?.lastName}}</p>
               </td>
               <td>{{basketballGameDetailsData?.teamDeatailsTwo[0]?.teamDetails[0]?.teamName}}</td>
               <td *ngFor="let item of basketballGameDetailsData?.teamDeatailsTwo[0]?.scoreData">{{ item.score }}</td>
               <td>{{ this.finalScoreTwo }}</td>
            </tr>
         </tbody>
    </table>
</div>


