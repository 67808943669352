import { Component, Input, OnInit } from '@angular/core';
import { MainService } from 'src/app/providers/main-service.service';

@Component({
  selector: 'tennis-report',
  templateUrl: './tennis-report.component.html',
  styleUrls: ['./tennis-report.component.scss']
})
export class TennisReportComponent implements OnInit {

  @Input() team1Id: any
  @Input() team2Id: any
  @Input() _gameId: any
  @Input() competitionList: any
  sportType: any;
  tableTennisGameDetailsData: any;
  tableTennisDetailsSingle: any;
  itemArray: any;
  itemArrayTwo: any;
  finalScoreOfOne: any;
  finalScoreOfTwo: any;
  tableTennisDetailsDouble: any;


  constructor(private service: MainService) { }

  ngOnInit(): void {
    setTimeout(() => {

      this.sportType = this.competitionList.sportType
      if(this.sportType == 'double'){
        this.tableTennisDoubleGameDetails(this.team1Id, this.team2Id, this._gameId)
      }
      else{
        this.gameDetail(this.team1Id, this.team2Id, this._gameId)
      }

    }, 100);
  }




gameDetail(team1Id, team2Id, _gameId) {
  this.service.spinnerShow();
  let apireq = {
    teams: [team1Id, team2Id],
    gameId: _gameId
  };
  // console.log("apireq======>",apireq);
  this.service.postApi('game/tableTennisSingleGameDetails', apireq, 0).subscribe(success => {

    if (success.responseCode === 200) {
      this.tableTennisGameDetailsData = success;
      this.tableTennisDetailsSingle = success;
      this.itemArray = this.tableTennisGameDetailsData.teamDeatailsOne[0].scoreData;
      this.itemArrayTwo = this.tableTennisGameDetailsData.teamDeatailsTwo[0].scoreData;
      this.finalScoreOfOne = this.tableTennisGameDetailsData.teamDeatailsOne[0].finalScore;
      this.finalScoreOfTwo = this.tableTennisGameDetailsData.teamDeatailsTwo[0].finalScore;
      console.log("Table Tennis Single Game Details====>", this.tableTennisGameDetailsData);
      this.service.toastrSucc(success.responseMessage);

    } else {
      // this.service.toastrErr(success.responseMessage);
      console.log("error======>");
    }
    this.service.spinnerHide();
  }, error => {
    this.service.spinnerHide();
    console.log("error of Table Tennis game details");
  });
}


tableTennisDoubleGameDetails(team1Id, team2Id, _gameId){
  this.service.spinnerShow();
  let apireq = {
    teams: [team1Id, team2Id],
    gameId: _gameId
  };
  // console.log("apireq======>",apireq);
  this.service.postApi('game/tableTennisDoubleGameDetails', apireq, 0).subscribe(success => {

    if (success.responseCode === 200) {
      this.tableTennisGameDetailsData = success;
      this.tableTennisDetailsDouble = success
      this.itemArray = this.tableTennisGameDetailsData.teamDeatailsOne[0].scoreData;
      this.itemArrayTwo = this.tableTennisGameDetailsData.teamDeatailsTwo[0].scoreData;
      this.finalScoreOfOne = this.tableTennisGameDetailsData.teamDeatailsOne[0].finalScore;
      this.finalScoreOfTwo = this.tableTennisGameDetailsData.teamDeatailsTwo[0].finalScore;
      console.log("Table Tennis Double Game Details====>", this.tableTennisGameDetailsData);
      this.service.toastrSucc(success.responseMessage);

    } else {
      // this.service.toastrErr(success.responseMessage);
      console.log("error======>");
    }
    this.service.spinnerHide();
  }, error => {
    this.service.spinnerHide();
    console.log("error of Table Tennis game details");
  });
}
}
