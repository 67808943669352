import { Component, Input, OnInit } from '@angular/core';
import { MainService } from 'src/app/providers/main-service.service';

@Component({
  selector: 'swiming-report',
  templateUrl: './swiming-report.component.html',
  styleUrls: ['./swiming-report.component.scss']
})
export class SwimingReportComponent implements OnInit {

  @Input() team1Id: any
  @Input() team2Id: any
  @Input() _gameId: any
  teamOneLane: any;
  teamOnePlace: any;
  teamOneTime: any;
  teamTwoLane: any;
  teamTwoPlace: any;
  teamTwoTime: any;
  constructor(private service: MainService) { }

  ngOnInit(): void {
    this.gameDetail(this.team1Id, this.team2Id, this._gameId)
  }

  swimmingGameDetailsData: any

  gameDetail(team1Id, team2Id, _gameId) {

    this.service.spinnerShow();
    let apireq = {
         teams: [team1Id,team2Id] ,
         gameId: _gameId
    };
    this.service.postApi('game/swimmingGameDetails',apireq,0).subscribe(success => {
      
      if (success.responseCode === 200) {
        this.swimmingGameDetailsData = success;
        this.teamOneLane = this.swimmingGameDetailsData.teamDeatailsOne[0].scoreData[0].lane,
        this.teamOnePlace =  this.swimmingGameDetailsData.teamDeatailsOne[0].scoreData[0].place,
        this.teamOneTime = this.swimmingGameDetailsData.teamDeatailsOne[0].scoreData[0].time,

        this.teamTwoLane = this.swimmingGameDetailsData.teamDeatailsTwo[0].scoreData[0].lane,
        this.teamTwoPlace =  this.swimmingGameDetailsData.teamDeatailsTwo[0].scoreData[0].place,
        this.teamTwoTime = this.swimmingGameDetailsData.teamDeatailsTwo[0].scoreData[0].time,
        console.log("success of Swimming Details ======>",this.swimmingGameDetailsData);
        this.service.toastrSucc(success.responseMessage);
      } 
      this.service.spinnerHide();
    }, error => {
      this.service.spinnerHide();
      this.service.toastrErr(error);
    });
  }

}
