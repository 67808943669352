import { Component, OnInit } from '@angular/core';
// import { MainService } from '../../../../providers/mainService.service';
import { MainService } from 'src/app/providers/main-service.service';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IMyDpOptions } from 'mydatepicker';

@Component({
  selector: 'app-leader',
  templateUrl: './leader.component.html',
  styleUrls: ['./leader.component.css']
})
export class LeaderComponentNew implements OnInit {
  userid: any;
  memberList: any=[];
  membershipname: any;
  membershipId: any;
  servicelist: any=[];
  serviceMonth: any=[];

  servicename: any;
  serviceId: any;
  filterForm: FormGroup;
  disable:boolean = true;
  leaderList: any=[];
  searchYear: any;
  searchMonth: any;
  leaderboardList: any=[];
  profileData : any = [];
  pointForm: FormGroup;
  show : string ='hide';
  
  constructor(public service: MainService) { }
   serviceYear: any=[2100, 2099,2098,2097,2096,2095,2094,2093,2092,2091,
2090,2089,2088,2087,2086,2085,2084,2083,2082,2081,
2080,2079,2078,2077,2076,2075,2074,2073,2072,2071,
2070,2069,2068,2067,2066,2065,2064,2063,2062,2061,
2060,2059,2058,2057,2056,2055,2054,2053,2052,2051,
2050,2049,2048,2047,2046,2045,2044,2043,2042,2041,
2040,2039,2038,2037,2036,2035,2034,2033,2032,2031,
2030,2029,2028,2027,2026,2025,2024,2023,2022,2021,
2020,2019,2018,2017,2016,2015,2014,2013,2012,2011,
2010,2009,2008,2007,2006,2005,2004,2003,2002,2001]
  ngOnInit() {
    this.userid = JSON.parse(localStorage.getItem('userDetailYala') );
    console.log("User--> ",this.userid)
    console.log("User Id--> ",this.userid._id);
    this.downloadPdf();
    this.formValidation();
    this.membershipListApi();
    this.serviceMonth =[
    {name:'January',val:'01'},
    {name:'February',val:'02'},
    {name:'March',val:'03'},
    {name:'April',val:'04'},
    {name:'May',val:'05'}, 
    {name:'June',val:'06'},
    {name:'July',val:'07'}, 
    {name:'August',val:'08'},
    {name:'October',val:'09'},
    {name:'September',val:'10'},
    {name:'November',val:'11'},
    {name:'December',val:'12'}
    ]
    this.getReverseData()
  }
  getReverseData(){
    this.serviceYear.reverse()
  }
  /******************Form Validation ****************/
  formValidation(){
    this.filterForm = new FormGroup({
      'membershipName': new FormControl('',Validators.required),
      'serviceName':new FormControl('',Validators.required),
      'serviceDate': new FormControl('',Validators.required),
    'selectYear': new FormControl('',[Validators.required]),
      
    });
    // this.pointForm = new FormGroup({
    //   'points':new FormControl('')
    // })
  }
/*********** Get MemberShip List Api *********/
membershipListApi(){
  var url = `membership/selectMembership?organizerId=`+this.userid._id;
  this.service.getApi(url,1).subscribe(response => {
   if(response.responseCode == 200) {
   // console.log(JSON.stringify(response));
    this.memberList = response.result;
    console.log("Memember list--> ",this.memberList);
   } else if(response.responseCode == 402) {
     
   }
 });
}
/*********************** Get MembershipID ****************/
getMembershipId(event){
  console.log("MememberName-->  ",event.target.value);
 this.membershipname = event.target.value;
  for(var i= 0;i<this.memberList.length;i++){
    console.log("MememberName-->  ",this.membershipname);
    if(this.memberList[i].membershipName == this.membershipname){
      this.membershipId = this.memberList[i]._id;
      this.getServiceList();
      console.log("Membership Id--> ",this.membershipId);
    }
  }
}
/************************** Get List Of Service of Particular Membership *****************/
getServiceList(){
  var url = `membership/selectService?organizerId=`+this.userid._id+`&membershipId=`+this.membershipId;
  this.service.getApi(url,1).subscribe(response => {
   if(response.responseCode == 200) {
    console.log(JSON.stringify(response));
    this.servicelist = response.result;
    console.log("member list--> ",this.servicelist);
   } else if(response.responseCode == 402) {
     
   }
 });
}
/************************* Get Service Id *****************/
getServiceId(event){
  console.log("MememberName-->  ",event.target.value);
this.servicename = event.target.value;
  for(var i= 0;i<this.servicelist.length;i++){
    console.log("MememberName-->  ",this.servicename);
    if(this.servicelist[i].serviceName == this.servicename){
      this.serviceId = this.servicelist[i]._id;
      console.log("Membership Id--> ",this.serviceId);
    }
  }
  var url = `membership/getAService?organizerId=`+this.userid._id+`&serviceId=`+this.serviceId;
  this.service.getApi(url,1).subscribe(response => {
   if(response.responseCode == 200) {
    console.log(JSON.stringify(response));
    this.disable = false;
    console.log("Response---> ",response.result);
    // var startDate = response.result.startDate;
    // var isostart = new Date("2018-09-10T00:00:00.000Z");
    // var endDate = response.result.endDate;
    // var isoDate = new Date("2018-09-25T00:00:00.000Z");
    this.onDateChange(response.result.startDate,response.result.endDate);   
   } else if(response.responseCode == 402) {
     
   }
 });
}
/******************Date Validation  between the given Dates**************/
public myDatePickerOptions: IMyDpOptions = { 
  dateFormat: 'yyyy-mm-dd', 
  editableDateField:false, 
  openSelectorOnInputClick:false,
  disableUntil:{year: 0, month: 0, day: 0},
  disableSince: {year: 0, month: 0, day: 0}
  };
  onDateChange(startdate,enddate) {
    let startD = new Date(startdate);
    let endD = new Date(enddate)
    startD.setDate(startD.getDate() -1 );
    let copy1 = this.getCopyOfOptions();
    copy1.disableUntil = {
    year: startD.getFullYear(),
    month: startD.getMonth() + 1,
    day: startD.getDate()
    };
    this.myDatePickerOptions = copy1 
    endD.setDate(endD.getDate() +1);
    let copy2 = this.getCopyOfOptions();
    copy2.disableSince = {
    year: endD.getFullYear(),
    month: endD.getMonth() + 1,
    day: endD.getDate()
    };
    this.myDatePickerOptions = copy2
    }
 //Returns copy of myDatePickerOptions
getCopyOfOptions(): IMyDpOptions {
  return JSON.parse(JSON.stringify(this.myDatePickerOptions));
  }
 returnMonth(m){
   if(m==='11'){
   return  JSON.parse(m)
  }
   if(m==='12'){
   return  JSON.parse(m)
  }
   if(m==='10'){
   return  JSON.parse(m)
  }
  else{
     let n = m.split('')
    return  JSON.parse(n[1])
  }
 }
  /************** Get Leader Board Functionality *************/
  getLeaderBoard(formvalue){
    this.searchMonth = formvalue.serviceDate;
    let selectYear = formvalue.selectYear;
    const d = new Date();
   this.searchYear =  selectYear
  var date =  selectYear+'-'+this.searchMonth+'-'+'01'+'T00:00:00.000'
    var apiDoc = {
      "date":date,
      "month":  (this.returnMonth(this.searchMonth)-1).toString(),
      "membershipId":this.membershipId,
      "serviceId":this.serviceId
    }
   console.log("Api Doc===>>  ",apiDoc); 
  
   this.service.postApi(`membership/getListOfPlayersLeaderboard`,apiDoc,1).subscribe(response => {
    if(response.responseCode == 200) {
      console.log(JSON.stringify(response));
     this.leaderList = response.result;
    
     //this.leaderboardList = this.currentList.filter(x => x.leaderBoard.hasOwnProperty(this.currentYear))
     if(this.leaderList != []){
      this.show = 'show';
      this.leaderboardList = this.leaderList.filter(x => x.leaderBoard.hasOwnProperty(this.searchYear))
     }
    
    console.log("Leader Array----> ",this.leaderboardList);
  
    } else{
      
    }
  });
  }
  onSearchChange(val,id){
     for(var i=0;i<this.leaderboardList.length;i++){
       if(this.leaderboardList[i]._id == id){
         this.leaderboardList[i].leaderBoard[this.searchYear][this.searchMonth]  = val;
       }
     }
  }
  update(){
  var apiDoc = {
    "result": this.leaderboardList
    }
    console.log("Api Doc---> ",apiDoc);
    this.service.postApi(`membership/updateLeaderBoardPoint`,apiDoc,1).subscribe(response => {
      if(response.responseCode == 200) {
      //  this.leaderList = response.result;
      //  this.show = 'show'
      //  //this.leaderboardList = this.currentList.filter(x => x.leaderBoard.hasOwnProperty(this.currentYear))
      // this.leaderboardList = this.leaderList.filter(x => x.leaderBoard.hasOwnProperty(this.searchYear))
      // console.log("Leader Array----> ",this.leaderboardList);
    
      } else{
        
      }
    });
    }
   
    downloadPdf() {
      this.service.getApi(`users/getDetail?_id=${JSON.parse(localStorage.getItem('userDetailYala'))._id}`, 1).subscribe(response => {
        if (response.responseCode === 200) {
          this.profileData = response.result;
        }
      });
        }

      /**************** Print Function **********************/
      printTable(){
        let printContents = document.getElementById('tablerecords').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload()
      }
}
