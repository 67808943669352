import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgetPassword.component.html',
  styleUrls: ['./forgetPassword.component.scss']
})
export class forgetPasswordComponent implements OnInit {
  @Output() flagType = new EventEmitter<any>()
  constructor() { }

  ngOnInit(): void { 
  }
  updateFlag(){
    this.flagType.emit('login')
  }
}
