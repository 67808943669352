<div class="container">
    <div class="section-title">
        <span class="sub-title">Latest News</span>
        <h2>News And Updates</h2>
    </div>
</div>

<div class="container-fluid">
    <div class="blog-slides owl-carousel owl-theme">
        <div class="single-blog-post-item">
            <div class="row m-0">
                <div class="col-lg-6 p-0">
                    <div class="post-image bg-1">
                        <img src="assets/img/news1.jpg" alt="image">
                        <a routerLink="/news-detail" class="link-btn"></a>
                    </div>
                </div>

                <div class="col-lg-6 p-0">
                    <div class="post-content">
                        <span class="sub-title">Release Note</span>
                        <h3><a routerLink="/news-detail">ANDROID TOOLS FOR MOBILE GAME</a></h3>
                        <a routerLink="/news-detail" class="default-btn">Learn More</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="single-blog-post-item">
            <div class="row m-0">
                <div class="col-lg-6 p-0">
                    <div class="post-image bg-2">
                        <img src="assets/img/news1.jpg" alt="image">
                        <a routerLink="/news-detail" class="link-btn"></a>
                    </div>
                </div>

                <div class="col-lg-6 p-0">
                    <div class="post-content">
                        <span class="sub-title">Release Note</span>
                        <h3><a routerLink="/news-detail">CONVERSION RATE THE SALES FUNNEL</a></h3>
                        <a routerLink="/single-blog" class="default-btn">Learn More</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="single-blog-post-item">
            <div class="row m-0">
                <div class="col-lg-6 p-0">
                    <div class="post-image bg-3">
                        <img src="assets/img/news1.jpg" alt="image">
                        <a routerLink="/news-detail" class="link-btn"></a>
                    </div>
                </div>

                <div class="col-lg-6 p-0">
                    <div class="post-content">
                        <span class="sub-title">Release Note</span>
                        <h3><a routerLink="/news-detail">HOW TO HIT THE MARK WITH MOBILE GAMESddd</a></h3>
                        <a routerLink="/news-detail" class="default-btn">Learn More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>