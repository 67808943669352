<div class="container">
    <div class="footer-content">
        <div class="logo">
            <a routerLink="/" class="d-inline-block"><img src="{{orgLogo}}" alt="image" 
                style="height: 100px;"></a>
            <ul class="footer-menu">
                <!-- <li class="nav-item"><a class="nav-link" routerLink="/faq">Help</a></li>
                <li class="nav-item"><a class="nav-link" routerLink="/">Terms of Use</a></li>
                <li class="nav-item"><a class="nav-link" routerLink="/">Privacy policy</a></li>
                <li class="nav-item"><a class="nav-link" routerLink="/">Support center</a></li> -->
                <li *ngFor="let item of footerMenuList" class="nav-item">
                    <a class="nav-link" href="{{item?.link}}">{{item?.footerMenuName}}</a>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="copyright-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <p class="text-center"><i class='bx bx-copyright'></i>Copyright © {{getYear()}} Yala Sports</p>
            </div>           
        </div>
    </div>
</div>