<div class="main-container">
    <div class="container card">
        <!-- <h1>
            <strong>Welecome to Blue Collar
                Connect</strong>
            </h1> -->
        <div class="container" style="width: 40%;">
            <img *ngIf="paymentStatus == 'Success'" src="assets/gif/verify.gif"  width="100%"
                alt="">
            <img *ngIf="paymentStatus == 'Failed'" src="assets/gif/failed.gif"  width="100%"
                alt="">
            </div>
        <h2 *ngIf="paymentStatus == 'Success'" class="payment">Your Payment Has Sucessfully Accepted</h2>
        <h2 *ngIf="paymentStatus == 'Failed'">Your Payment Has Failed</h2>
        <!-- <p><a routerLink="/">Click here</a> to proceed login</p> -->
        <p class="text-black payment" *ngIf="paymentStatus == 'Success'">Redirecting to homepage in {{time}} second's</p>
        <p class="text-black" *ngIf="paymentStatus == 'Failed'">Redirecting to homepage in {{time}} second's</p>
        </div>
</div>