<div class="table-responsive">
    <table class="table table-bordered">
        <thead>
            <tr class="table-head-bg">
               <th>Player Name</th>
               <th>Team Name</th>
               <th *ngFor="let data of tableTennisGameDetailsData?.teamDeatailsOne[0]?.scoreData">{{
                  data.name}}</th>
               <th>Final Score</th>
            </tr>
         </thead>
         <tbody>
            <tr>
               <td>
                  <p>{{ tableTennisGameDetailsData?.teamDeatailsOne[0]?.playersDetails[0]?.firstName
                     }}</p>
                  <p *ngIf="sportType == 'double'">
                     {{tableTennisGameDetailsData?.teamDeatailsOne[0]?.playersDetailsTwo[0]?.firstName}}
                  </p>
               </td>
               <td>{{ tableTennisGameDetailsData?.teamDeatailsOne[0]?.teamDetails[0]?.teamName }}</td>
               <td *ngFor="let score of itemArray">{{ score.score}}</td>
               <td>{{ finalScoreOfOne }}</td>
            </tr>
            <tr>
               <td>
                  <p>{{ tableTennisGameDetailsData?.teamDeatailsTwo[0]?.playersDetails[0]?.firstName
                     }}</p>
                  <p *ngIf="sportType == 'double'">
                     {{tableTennisGameDetailsData?.teamDeatailsTwo[0]?.playersDetailsTwo[0]?.firstName}}
                  </p>
               </td>
               <td>{{ tableTennisGameDetailsData?.teamDeatailsTwo[0]?.teamDetails[0]?.teamName }}</td>
               <td *ngFor="let score of itemArrayTwo">{{ score.score}}</td>
               <td>{{ finalScoreOfTwo }}</td>
            </tr>
         </tbody>
    </table>
</div>


