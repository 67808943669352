<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <h1 title="3 In Row">3 In Row</h1>
        </div>
    </div>
</section>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box">
                    <div class="post-image">
                        <a routerLink="/single-blog">
                            <img src="assets/img/main-blog-img1.jpg" alt="image">
                        </a>
                    </div>

                    <div class="post-content">
                        <ul class="post-meta">
                            <li>By <a routerLink="/blog-2">John Smith</a></li>
                            <li>June 12, 2020</li>
                            <li><a routerLink="/blog-2">Game</a></li>
                        </ul>
                        <h3><a routerLink="/single-blog">Android tools for mobile game development</a></h3>
                        <a routerLink="/single-blog" class="read-more-btn">Read More <i class="flaticon-null"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box">
                    <div class="post-image">
                        <a routerLink="/single-blog">
                            <img src="assets/img/main-blog-img2.jpg" alt="image">
                        </a>
                    </div>

                    <div class="post-content">
                        <ul class="post-meta">
                            <li>By <a routerLink="/blog-2">John Smith</a></li>
                            <li>June 11, 2020</li>
                            <li>Programmer</li>
                        </ul>
                        <h3><a routerLink="/single-blog">Get a job: Disbelief is hiring a Programmer</a></h3>
                        <a routerLink="/single-blog" class="read-more-btn">Read More <i class="flaticon-null"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box">
                    <div class="post-image">
                        <a routerLink="/single-blog">
                            <img src="assets/img/main-blog-img3.jpg" alt="image">
                        </a>
                    </div>

                    <div class="post-content">
                        <ul class="post-meta">
                            <li>By <a routerLink="/blog-2">John Smith</a></li>
                            <li>June 10, 2020</li>
                            <li><a routerLink="/blog-2">Spider-Man</a></li>
                        </ul>
                        <h3><a routerLink="/single-blog">Jumping in with Spring Trap next</a></h3>
                        <a routerLink="/single-blog" class="read-more-btn">Read More <i class="flaticon-null"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box">
                    <div class="post-image">
                        <a routerLink="/single-blog">
                            <img src="assets/img/main-blog-img4.jpg" alt="image">
                        </a>
                    </div>

                    <div class="post-content">
                        <ul class="post-meta">
                            <li>By <a routerLink="/blog-2">John Smith</a></li>
                            <li>June 09, 2020</li>
                            <li><a routerLink="/blog-2">Mobile</a></li>
                        </ul>
                        <h3><a routerLink="/single-blog">How to hit the mark with mobile games development</a></h3>
                        <a routerLink="/single-blog" class="read-more-btn">Read More <i class="flaticon-null"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box">
                    <div class="post-image">
                        <a routerLink="/single-blog">
                            <img src="assets/img/main-blog-img5.jpg" alt="image">
                        </a>
                    </div>

                    <div class="post-content">
                        <ul class="post-meta">
                            <li>By <a routerLink="/blog-2">John Smith</a></li>
                            <li>June 06, 2020</li>
                            <li><a routerLink="/blog-2">Game</a></li>
                        </ul>
                        <h3><a routerLink="/single-blog">Zelda comes to Game Pass for its 10th anniversary</a></h3>
                        <a routerLink="/single-blog" class="read-more-btn">Read More <i class="flaticon-null"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box">
                    <div class="post-image">
                        <a routerLink="/single-blog">
                            <img src="assets/img/main-blog-img6.jpg" alt="image">
                        </a>
                    </div>

                    <div class="post-content">
                        <ul class="post-meta">
                            <li>By <a routerLink="/blog-2">John Smith</a></li>
                            <li>June 05, 2020</li>
                            <li><a routerLink="/blog-2">Hardware</a></li>
                        </ul>
                        <h3><a routerLink="/single-blog">What’s New In Matter of Hardware This Year?</a></h3>
                        <a routerLink="/single-blog" class="read-more-btn">Read More <i class="flaticon-null"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box">
                    <div class="post-image">
                        <a routerLink="/single-blog">
                            <img src="assets/img/main-blog-img7.jpg" alt="image">
                        </a>
                    </div>

                    <div class="post-content">
                        <ul class="post-meta">
                            <li>By <a routerLink="/blog-2">John Smith</a></li>
                            <li>June 09, 2020</li>
                            <li><a routerLink="/blog-2">Mobile</a></li>
                        </ul>
                        <h3><a routerLink="/single-blog">How to hit the mark with mobile games development</a></h3>
                        <a routerLink="/single-blog" class="read-more-btn">Read More <i class="flaticon-null"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box">
                    <div class="post-image">
                        <a routerLink="/single-blog">
                            <img src="assets/img/main-blog-img8.jpg" alt="image">
                        </a>
                    </div>

                    <div class="post-content">
                        <ul class="post-meta">
                            <li>By <a routerLink="/blog-2">John Smith</a></li>
                            <li>June 06, 2020</li>
                            <li><a routerLink="/blog-2">Game</a></li>
                        </ul>
                        <h3><a routerLink="/single-blog">Zelda comes to Game Pass for its 10th anniversary</a></h3>
                        <a routerLink="/single-blog" class="read-more-btn">Read More <i class="flaticon-null"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box">
                    <div class="post-image">
                        <a routerLink="/single-blog">
                            <img src="assets/img/main-blog-img9.jpg" alt="image">
                        </a>
                    </div>

                    <div class="post-content">
                        <ul class="post-meta">
                            <li>By <a routerLink="/blog-2">John Smith</a></li>
                            <li>June 05, 2020</li>
                            <li><a routerLink="/blog-2">Hardware</a></li>
                        </ul>
                        <h3><a routerLink="/single-blog">What’s New In Matter of Hardware This Year?</a></h3>
                        <a routerLink="/single-blog" class="read-more-btn">Read More <i class="flaticon-null"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area text-center">
                    <a routerLink="/blog-2" class="prev page-numbers"><i class='bx bx-chevrons-left'></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/blog-2" class="page-numbers">2</a>
                    <a routerLink="/blog-2" class="page-numbers">3</a>
                    <a routerLink="/blog-2" class="page-numbers">4</a>
                    <a routerLink="/blog-2" class="next page-numbers"><i class='bx bx-chevrons-right'></i></a>
                </div>
            </div>
        </div>
    </div>
</section>

<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>