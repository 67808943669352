<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <h1 title="Contact Us">Contact Us</h1>
        </div>
    </div>
</section>

<section class="contact-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Contact Details</span>
                    <h2>Get in Touch</h2>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra.</p> -->

                    <ul>
                        <li>
                            <div class="icon">
                                <i class="flaticon-location"></i>
                            </div>
                            <h3>Our Address</h3>
                            <p>{{userDetails.address}}, {{userDetails.nationality}}</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="flaticon-24-hours"></i>
                            </div>
                            <h3>Contact</h3>
                            <p>Mobile: <a href="tel:{{userDetails.countryCode}}{{userDetails.mobileNumber}}">({{userDetails.countryCode}}) - {{userDetails.mobileNumber}}</a></p>
                            <p>Mail: <a href="mailto:{{userDetails.email}}">{{userDetails.email}}</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="flaticon-network"></i>
                            </div>
                            <h3>Social</h3>
                            <div class="social-box">
                                
                                <a *ngIf="fbEnabled" href="{{fbUrl}}" target="_blank"><i class="bx bxl-facebook"></i></a>
                                <a *ngIf="twitterEnable" href="{{twitterUrl}}" target="_blank"><i class="bx bxl-twitter"></i></a>
                                
                                <a *ngIf="igEnabled" href="{{igUrl}}" target="_blank"><i class="bx bxl-instagram"></i></a>
                                <a *ngIf="googleAnalyticsEnabled" href="{{googleAnalyticsUrl}}" target="_blank"><i class="bx bxl-youtube"></i></a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div *ngIf="false" class="col-lg-6 col-md-12">
                <div class="contact-form">
                    <h2>Ready to Get Started?</h2>
                    <p>Your email address will not be published. Required fields are marked *</p>
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" required placeholder="Your name">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" required placeholder="Your email address">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text" name="phone_number" id="phone_number" required placeholder="Your phone number">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" id="message" cols="30" rows="5" required placeholder="Write your message..."></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>