<div id="Starts" class=" tab-pane active show">
    <div class="row">
       <div class="col-lg-12">
          <div class="scoreboard-table-block1">
             <div class="scoreboard-table-inner">
              <div class="status-content-block">
                <div>
                  <div>
                     <h3 style="text-align: center; margin-top: 10px;"> Competition Details </h3>
                     <table class="table table-bordered large-table">
                        <thead>
                           <tr class="table-head-bg">
                              <th>Competition</th>
                              <th>Division</th>
                              <th>Period</th>
                              <th>Date</th>
                              <th>Venue</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr >
                              <td>{{gameData?.competitionData?.competitionName}}</td>
                              <td>{{ gameData?.competitionData?.division }}</td>
                              <td>{{ gameData?.competitionData?.period}}</td>
                              <td>{{ gameData?.competitionData?.startDate | date:'mediumDate' }}</td>
                              <td>{{ gameData?.competitionData?.venue }}</td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
               <h3 style="text-align: center;" > Statistics </h3>
               </div>
                 <div class="table-scrool-box1">
                 <div class="table-block mt30">
                    <div class="table-responsive">
                        <table class="table table-bordered text-center">
                           <thead>
                             <tr class="table-head-bg">
                               <th>Lane</th>
                               <th>Place</th>
                               <th>Team</th>
                               <th>Player First Name</th>
                               <th>Player Last Name</th>
                               <th>Time</th>
                             </tr>
                           </thead>
                           <tbody>
                             <tr *ngFor="let data of swimmingGameDetailsData?.teamDeatailsOne;let i = index">
                               <td>{{data?.scoreData[0]?.lane}}</td>
                               <td>{{data?.scoreData[0]?.place}}</td>
                               <td>{{data?.teamDetails[0]?.teamName}}</td>
                               <td>{{data?.playersDetails[0]?.firstName}}</td>
                               <td>{{data?.playersDetails[0]?.lastName}}</td>
                               <td>{{data?.scoreData[0]?.time}}</td>
                             </tr>
                             <tr *ngFor="let data of swimmingGameDetailsData?.teamDeatailsTwo;let i = index">
                               <td>{{data?.scoreData[0]?.lane}}</td>
                               <td>{{data?.scoreData[0]?.place}}</td>
                               <td>{{data?.teamDetails[0]?.teamName}}</td>
                               <td>{{data?.playersDetails[0]?.firstName}}</td>
                               <td>{{data?.playersDetails[0]?.lastName}}</td>
                               <td>{{data?.scoreData[0]?.time}}</td>
                             </tr>
                                                      
                           </tbody>
                         </table>
                    </div>
                 </div>
               </div>
                  <div class=" text-right mt20">
                    <!-- <button class="btn btn btn-dark-blue-s" data-toggle="modal" data-target="#swimmingPlayerEditScoreOne" data-backdrop="static" data-keyboard="false" style="margin-bottom: 12px;margin-top: 13px;width: 164px;">Edit Team Score</button> -->
                   <!--  <button class="btn btn btn-dark-blue-s" data-toggle="modal" data-target="#socialSharing" (click)="send()">Publish Match</button> -->
                  </div>
           </div>
         </div>
       </div>
     </div>
 </div>

