<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <h1 title="Service List">Service List</h1>
        </div>
    </div>
</section>

<section class="fixtures-area pt-100 pb-70">
    <div class="container">
        <div class="table-responsive">
            <table class="table table-bordered">
                <thead>
                    <tr class="table-head-bg">
                        <th>Service Name</th>
                        <th>MemberShip Name</th>
                        <th>Price</th>
                        <th>Duration</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>MemberShip Type</th>
                        <th>Professionl Name</th>
                        <th>Action</th>
                      
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of servicelist ; let i = index">
                        <td >{{item.serviceName}}</td>
                        <td >{{item.membershipName}}</td>
                        <td >AED {{item.amount|| '0'}} </td>
                        <td >{{item.duration}}Minute(s)</td>
                        <td >{{item.startDate | date :'longDate'}} - {{item.endDate | date :'longDate'}} </td>
                        <td >{{item.endDuration}}</td>
                        <td >{{item.serviceType}}</td>
                        <td > <span *ngFor="let prod of item?.professionals;">{{prod?.professionalName}}</span> </td>
                        <td > 
                            <div>
                                <a (click)="openBooking(item, 2)"  class="small-btn">Book</a>
                            </div>
                            <div>
                                <a routerLink="/leaderboard" class="small-btn">Leaderboard</a>

                            </div>
                        </td>
                       
                    </tr>
                </tbody>
            </table>
          </div>
    </div>
</section>






<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>