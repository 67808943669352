import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/providers/main-service.service';
import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-shop-style-one',
  templateUrl: './shop-style-one.component.html',
  styleUrls: ['./shop-style-one.component.scss']
})
export class ShopStyleOneComponent implements OnInit {

  constructor(private service: MainService,private router : Router) { }

  userId: any;
  productsList: any[];

  ngOnInit(): void {    
    this.getProductsList();
  }

  getProductsList(){
    this.userId = this.service.getStorage('userId');
    this.service.getApi(`product/getAllTypeProducts?organizerId=${this.userId}`,0).subscribe(response => {
      if(response.responseCode == 200) {       
        this.productsList = response.result;
        // this.productsList = this.productsList.slice().reverse();
      }
    })
  }
  addToCart(_id,size,price){
    this.userId = this.service.getStorage('userId');
    let url = `product/addToCart?userId=${this.userId}&productId=${_id}`
    const body = {
      quantity : '1',
      size : size,
      price : price

    }
    this.service.postApi(url,body,0).subscribe(response => {
      if(response.responseCode == 200) {       
       this.router.navigate(['/cart'])
       this.service.toastrSucc(response.responseMessage)
      }
      else if(response.responseCode === 409 ){
        this.router.navigate(['/cart'])
      }
    })
  }


}
