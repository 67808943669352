<div class="container">
    <div class="section-title">
        <span class="sub-title">Showcase</span>
        <h2>Our featured games</h2>
    </div>

    <div class="featured-games-inner">
        <div class="row m-0 align-items-center">
            <div class="col-lg-7 col-md-12 p-0">
                <div class="featured-games-image text-center">
                    <img src="assets/img/featured-games-img.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-5 col-md-12 p-0">
                <div class="featured-games-content">
                    <div class="featured-games-slides owl-carousel owl-theme">
                        <div class="content">
                            <span class="sub-title">Available Now</span>
                            <h2><a href="#">Sekiro: Shadows Die Twice! PlayerUnknown's grounds</a></h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            <a routerLink="/single-tournament" class="read-more-btn">Read More <i class="flaticon-null"></i></a>
                        </div>

                        <div class="content">
                            <span class="sub-title">Available Now</span>
                            <h2><a href="#">The Legend of Zelda: Breath of the Wild Bloodborne</a></h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            <a routerLink="/single-tournament" class="read-more-btn">Read More <i class="flaticon-null"></i></a>
                        </div>

                        <div class="content">
                            <span class="sub-title">Available Now</span>
                            <h2><a href="#">Call of Duty: Modern Warfare Animal Crossing</a></h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            <a routerLink="/single-tournament" class="read-more-btn">Read More <i class="flaticon-null"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>