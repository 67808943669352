<div class="table-responsive">
    <table class="table table-bordered">
        <thead>
            <tr class="table-head-bg">
              <th></th>
              <th>Total Score</th>
              <th>Total Outs</th>
              <th>Net Score</th>
              <th>Leading Skins</th>
              <th>Lagging Skins</th>
              <th>Best Batsman</th>
              <th>Best Bowler</th>
            </tr>
          </thead>
          <tbody>
            <tr class="vertalgn">
              <td>{{genericTeamData?.result?.teamDeatailsOne[0]?.teamDetails[0]?.teamName}}</td>
              <td>{{genericTeamData?.result?.teamDeatailsOne[0]?.totalScore}}</td>
              <td>{{genericTeamData?.result?.teamDeatailsOne[0]?.totalOut}}</td>
              <td>{{genericTeamData?.result?.teamDeatailsOne[0]?.netScore}}</td>
              <td>{{genericTeamData?.result?.teamDeatailsOne[0]?.leadingSkins}}</td>
              <td>{{genericTeamData?.result?.teamDeatailsOne[0]?.laggingSkins}}</td>
              <td *ngIf="!bestBatsmanOfTeamTwo">select from Team A - player names</td>
              <td *ngIf="bestBatsmanOfTeamTwo">{{bestBatsmanOfTeamTwo}}</td>

              <td *ngIf="!bestBowlerOfTeamOne">select from Team A - player names</td>
              <td *ngIf="bestBowlerOfTeamOne">{{bestBowlerOfTeamOne}}</td>
            </tr>
            <tr class="vertalgn">
              <td>{{genericTeamData?.result?.teamDeatailsTwo[0].teamDetails[0].teamName}}</td>
              <td>{{genericTeamData?.result?.teamDeatailsTwo[0]?.totalScore}}</td>
              <td>{{genericTeamData?.result?.teamDeatailsTwo[0]?.totalOut}}</td>
              <td>{{genericTeamData?.result?.teamDeatailsTwo[0]?.netScore}}</td>
              <td>{{genericTeamData?.result?.teamDeatailsTwo[0]?.leadingSkins}}</td>
              <td>{{genericTeamData?.result?.teamDeatailsTwo[0]?.laggingSkins}}</td>
              <td *ngIf="!bestBatsmanOfTeamTwo">select from Team A - player names</td>
              <td *ngIf="bestBatsmanOfTeamTwo">{{bestBatsmanOfTeamTwo}}</td>

              <td *ngIf="!bestBowlerOfTeamTwo">select from Team A - player names</td>
              <td *ngIf="bestBowlerOfTeamTwo">{{bestBowlerOfTeamTwo}}</td>
            </tr>
          </tbody>
    </table>
</div>


<div class="table-responsive mt-5">
    <table class="table table-bordered">
        <thead>
            <tr class="table-head-bg">
              <th>{{genericTeamData?.result?.teamDeatailsOne[0].teamDetails[0].teamName}}</th>
              <th colspan="4"> Batting Performance</th>
              </tr>
            <tr class="table-head-bg">
              <th>Skin</th>
              <th>Player No.</th>
              <th>Player First Name</th>
              <th>Player Last Name</th>
              <th>Runs</th>
            </tr>
          </thead>
          <tbody>
            <tr class="vertalgn" *ngFor="let data of genericTeamData?.result?.teamOne">
              <td>{{data.skin}}</td>
              <td>{{data.playerNumber}}</td>
              <td>{{data.userDetails[0].firstName}}</td>
              <td>{{data.userDetails[0].lastName}}</td>
              <td>{{data.runs}}</td>
            </tr>
          </tbody>
    </table>
</div>


<div class="table-responsive mt-5">
    <table class="table table-bordered">
        <thead>
            <tr class="table-head-bg">
              <th>{{genericTeamData?.result?.teamDeatailsTwo[0]?.teamDetails[0]?.teamName}}</th>
              <th colspan="4"> Batting Performance</th>
              </tr>
            <tr class="table-head-bg" >
              <th>Skin</th>
              <th>Player No.</th>
              <th>Player First Name</th>
              <th>Player Last Name</th>
              <th>Runs</th>
            </tr>
          </thead>
          <tbody>
            <tr class="vertalgn" *ngFor="let data of genericTeamData?.result?.teamTwo">
              <td>{{data.skin}}</td>
              <td>{{data.playerNumber}}</td>
              <td>{{data.userDetails[0].firstName}}</td>
              <td>{{data.userDetails[0].lastName}}</td>
              <td>{{data.runs}}</td>
            </tr>
          </tbody>
    </table>
</div>