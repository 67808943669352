import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-magazine-main-banner',
  templateUrl: './magazine-main-banner.component.html',
  styleUrls: ['./magazine-main-banner.component.scss']
})
export class MagazineMainBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
