<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <div class="single-matches-box">
                <div class="banner-wrapper-area jarallax" data-jarallax='{"speed": 0.3}'>
                    <div class="container">
                        <div class="banner-wrapper-content">
                            <h1>{{headingName}}</h1>
                            <!-- <span class="sub-title">{{headingName}}</span> -->
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</section>

<section class="match-details-area ptb-100">
    <div class="container">
        <div *ngFor="let allItem of gameDetail;" class="row">
            <div class="col-lg-8 col-md-12">
                <div class="match-details-desc">
                    <div class="article-content">
                     <!-- <h3> {{allItem?.venueName}}</h3>   -->
                     <!-- <h3> {{allItem?.membershipName}}</h3>   -->
                     <hr>
                        <h3>{{allItem?.title}}</h3>
                        <section [innerHTML]="allItem?.description"></section>
                    </div>
                    <div class="article-footer">
                        <div class="article-share">
                            <!-- <ul class="social">
                                <li><span>Share:</span></li>
                                <li><a href="#" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" class="linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            </ul> -->
                        </div>
                    </div>
                    <div class="comments-area">
                        <h3 class="comments-title">{{allItem?.comments?.length || 0}} Comments:</h3>

                        <ol class="comment-list">
                            <li class="comment" *ngFor="let item of allItem?.comments">
                                <article class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img [src]="item?.commentImage || 'assets/img/user1.jpg'" class="avatar" alt="image">
                                            <b class="fn">{{item?.commentFirstName}} {{item?.commentLastName}}</b>
                                            <span class="says">says:</span>
                                        </div>

                                        <div class="comment-metadata">
                                            <a routerLink="/single-match">
                                                <span>{{item?.createdAt | date : 'dd - MMM,yyyy hh:mm a'}}</span>
                                            </a>
                                        </div>
                                    </footer>

                                    <div class="comment-content">
                                        <p>{{item?.text}}</p>
                                    </div>

                                 
                                </article>

                             
                            </li>

                        </ol>

                       
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <section class="widget widget_instagram">
                        <ul>
                            <li *ngFor="let item of allItem?.mediaUrls">
                                <div class="box">
                                    <img [src]="item?.url || 'assets/img/team-member1.jpg'" alt="image">
                                    <i class="bx bxl-instagram"></i>
                                    <!-- <a href="#" target="_blank" class="link-btn"></a> -->
                                </div>
                            </li>

                          
                        </ul>
                    </section>

                     
                </aside>
            </div>
        </div>
    </div>
</section>

<section class="subscribe-area pb-100">
    <app-subscribe-style-one></app-subscribe-style-one>
</section>

<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>