<div class="row">

  <div class="authfy-container">
    <a (click)="updateFlag()" class="arrow_homepage"><i class="bx bx-arrow-back "></i>&nbsp;&nbsp; Back to Login</a>

    <div class="col-sm-12 authfy-panel-right">
      <!-- authfy-login start -->
      <div class="authfy-login">
        <!-- panel-login start -->
        <div class="authfy-panel panel-login text-center active">
          <div class="authfy-heading">
            <h3 class="auth-title">Reset Your Password!</h3>
            <!-- <p>Already have an account? <a class="lnk-toggler" routerLink="/login" href="#">Login Now!</a></p> -->
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-12">
              <form name="loginForm" class="loginForm" action="#" method="POST">

                <div class="form-group">
                  <input type="email" class="form-control email" name="username" placeholder="Email address">
                </div>


                <div class="form-group">
                  <a href="" target="_blank" class="btn btn-lg btn-primary btn-block" type="button">Send request
                    link</a>

                </div>
              </form>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</div> <!-- ./row -->