<div class="container">
    <div class="section-title">
        <span class="sub-title">Live Stream</span>
        <h2>Upcoming Streaming</h2>
    </div>

    <div class="row">
        <div class="col-lg-6 col-md-12">
            <div class="single-fixtures-box">
                <span class="date">Oct <br> 23</span>
                <div class="content">
                    <span class="time">14:30 - 15:30 EST</span>
                    <h3>ARENA OF VALOR WORLD CUP</h3>
                </div>
                <a href="#" class="link-btn" target="_blank"></a>
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="single-fixtures-box not-streaming">
                <span class="date">Jun <br> 25</span>
                <div class="content">
                    <span class="time">Not Streaming</span>
                    <h3>CALL OF DUTY LEAGUE</h3>
                </div>
                <a href="#" class="link-btn" target="_blank"></a>
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="single-fixtures-box">
                <span class="date">Jan <br> 25</span>
                <div class="content">
                    <span class="time">11:30 - 12:30 EST</span>
                    <h3>ESL USA PREMIERSHIP</h3>
                </div>
                <a href="#" class="link-btn" target="_blank"></a>
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="single-fixtures-box">
                <span class="date">20 <br> June</span>
                <div class="content">
                    <span class="time">14:30 - 15:30 EST</span>
                    <h3>EUROPEAN GAMING LEAGUE</h3>
                </div>
                <a href="#" class="link-btn" target="_blank"></a>
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="single-fixtures-box not-streaming">
                <span class="date">Dec <br> 18</span>
                <div class="content">
                    <span class="time">Not Streaming</span>
                    <h3>APEX LEGENDS GLOBAL SERIES</h3>
                </div>
                <a href="#" class="link-btn" target="_blank"></a>
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="single-fixtures-box">
                <span class="date">Jan <br> 25</span>
                <div class="content">
                    <span class="time">11:30 - 12:30 EST</span>
                    <h3>HALO CHAMPIONSHIP SERIES</h3>
                </div>
                <a href="#" class="link-btn" target="_blank"></a>
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="single-fixtures-box">
                <span class="date">20 <br> June</span>
                <div class="content">
                    <span class="time">14:30 - 15:30 EST</span>
                    <h3>GLOBAL STARCRAFT II LEAGUE</h3>
                </div>
                <a href="#" class="link-btn" target="_blank"></a>
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="single-fixtures-box not-streaming">
                <span class="date">Dec <br> 18</span>
                <div class="content">
                    <span class="time">Not Streaming</span>
                    <h3>APEX LEGENDS GLOBAL SERIES</h3>
                </div>
                <a href="#" class="link-btn" target="_blank"></a>
            </div>
        </div>
    </div>
</div>