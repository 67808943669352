<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <h1 title="Admin Profile">Admin Profile</h1>
        </div>
    </div>
</section>

<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                   

                    <div class="article-author">
                        <div class="author-profile-header"></div>
                        <div class="author-profile">
                            <div class="author-profile-title">
                                <img src="{{userDet?.image ? userDet?.image : 'assets/img/user1.jpg'}}" class="shadow-sm" alt="image">
                                <div class="article-content">
                                    <div class="entry-meta">
                                        <ul>
                                            <li>
                                                <i class='bx bx-phone'></i>
                                                <span>Phone</span>
                                                <a routerLink=""> {{userDet?.countryCode}} {{userDet?.mobileNumber}}</a>
                                            </li>
                                            <li>
                                                <i class='bx bx-envelope'></i>
                                                <span>Email</span>
                                                <a routerLink="">{{userDet?.email}}</a>
                                            </li>
                                            <li>
                                                <i class='bx bx-calendar'></i>
                                                <span>Member since</span>
                                                <a routerLink="">{{userDet?.memberCardStartDate}}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <h4 class="mt-4">{{userDet?.firstName}} {{userDet?.lastName}}</h4>
                                <!-- <span class="d-block">Photographer, Author, Writer</span> -->
                                <span class="d-block">{{userDet?.nationality}}</span>

                                
                                <p>{{userDet?.description}}</p>
                            </div>
                            <div class="article-footer">
                                <div class="article-tags">
                                 
                                </div>
        
                                <div class="article-share">
                                    <ul class="social">
                                        
                                        <li *ngIf="userDet?.facebook_Link" ><a [attr.href]="userDet?.facebook_Link" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                        <li  *ngIf="userDet?.twitterLink" ><a [attr.href]="userDet?.twitterLink"  class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                        <li  *ngIf="userDet?.linkedinLink" ><a [attr.href]="userDet?.linkedinLink"  class="linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                        <li  *ngIf="userDet?.instagramLink" ><a [attr.href]="userDet?.instagramLink"  class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                 </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                  

               

                  

                  

                  
                </aside>
            </div>
        </div>
    </div>
</section>

<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>