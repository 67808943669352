import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
// import domtoimage from 'dom-to-image';
// import { FacebookService, UIParams, UIResponse, InitParams, } from 'ngx-facebook';
import { MainService } from 'src/app/providers/main-service.service';
declare var $: any;
//Match Highlights 
@Component({
  selector: 'app-group-match-report-player',
  templateUrl: './group-match-report.component.html',
  styleUrls: ['./group-match-report.component.css']
})
export class GroupMatchReportComponent implements OnInit {
  
  @Input() team1Id: any
  @Input() team2Id: any
  @Input() _gameId: any
  @Input() iscompId: any

  @Input() groupId: any
  @Input() isroundId: any;
  @Input() isUpdated:any
  compId:any 
  paramData: any;
  roundId:any
  compititionData: any;
  editGameForm: FormGroup;
  gameData: any;
  runSelector: any = false;
  genericCompetitionData: any;
  childURL: string = null
  observation: boolean;

  resultdata: any = [];
  cupId: any;
  mResult: any = [];
  isLoginVistUser: any;
  messageFromObservation: any;


  constructor(
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public service: MainService,
    private _location: Location,

  ) {

    this.shareFb()

  }

  acctiveTab:any ='result2'
  getActiveResult(e){
    this.acctiveTab =e
    this.getObservation1()
  }

  imageUrl12: any = ''
  navUrlfb: any
  isShareImaege: any = false;
  imgMyLevel = new Image();
  yimg: any = {}
  shareImage() {
    // this.isShareImaege = false
    // let node = document.getElementById('my-node');
    // domtoimage.toPng(node)
    //   .then(function (dataUrl) {
    //     var img = new Image()
    //     img.src = dataUrl;
    //     localStorage.setItem('postImg', dataUrl)
    //     $('#send').modal('show')
    //     document.body.appendChild(img);
    //   })
  }
  imageConvert() {
    this.isShareImaege = false
    this.service.spinnerShow();
    let img = localStorage.getItem('postImg')
    let apireq = { image: img }
    this.service.postApi('users/uploadImage', apireq, 0).subscribe(resp => {
      if (resp.responseCode == 200) {
        this.service.spinnerHide();
        this.childURL = resp.result
        this.isShareImaege = true
        localStorage.removeItem('postImg')
      }
    })
  }
  shareFb(image?) {
    let searchParams = new URLSearchParams()
    if (image) {
      // searchParams.set('u', image);
      this.navUrlfb = 'https://www.facebook.com/sharer/sharer.php?u=' + image;
      window.open(this.navUrlfb, "_blank");
    }

  }




  userDetails: any
  ngOnInit() {
    this.userDetails = JSON.parse(this.service.getStorage('userDetailYala'))
    this.activatedRoute.params.subscribe(async params => {
      this.competitionId = params['compId']
    })
    this.formValidation();
    this.getParamData();
    this.getroundId();
  

  }



  receiveURL(data) {
    // this.childURL = data
  }

  //On back
  onBack() {
 
  }

  formValidation() {
    this.editGameForm = new FormGroup({
      date: new FormControl('',),
      place: new FormControl('',),
      team1: new FormControl('',),
      result1: new FormControl('', [Validators.pattern(/^[0-9]*$/)]),
      result2: new FormControl('', [Validators.pattern(/^[0-9]*$/)]),
      team2: new FormControl('',)
    });
  }

  getParamData() {
    this.activatedRoute.params.subscribe(params => {
      this.paramData = params;
      this.roundId =   this.isroundId
    });
    this.service.isSingalMatchUpdateSubject.subscribe(isCalled=>{
        this.getsportType();
      
    })
   
  }

  getsportType() {
    this.isLoginVistUser = this.service.isLoginVisitUser()
    let url;
    let apireq;
  if(this.service.isRolePlayer){
      url = `organizer/competition/getCompetitionPlayer`
       apireq = {
        competitionId: this.iscompId
      };
  }else{
    url = `organizer/competition/getACompetition`
     apireq = {
      userId: localStorage.getItem('userId'),
      competitionId: this.iscompId
    };
  }

    this.service.spinnerShow();
    
    this.service.postApi(url, apireq, 1).subscribe(success => {
      if (success.responseCode === 200) {
        // this.compititionData =[]
        this.compititionData = success.result;
        this.statsRouting();
        this.getGameData();

      } else {
        this.service.toastrErr("Something went wrong");
      }
      this.service.spinnerHide();
    }, error => {
      this.service.spinnerHide();
      this.service.toastrErr('Something went wrong in  get competition');
    });
  }

  statsRouting() {
    this.observation = false;
    localStorage.setItem('observation', JSON.stringify(this.observation))
    this.contentType = 'STATS'
 
  }
  contentType : any = ''
  observationRouting() {
    this.observation = true;
    this.contentType = 'Observation'

    // window.location.reload();
    localStorage.setItem('observation', JSON.stringify(this.observation))
    //// console.log("Status of Observation ------------:)", this.observation);

  }
  getTeamList: any
  competitionId: any
  getTeamListFun() {
    //************** Get Team List Api Integration *************//
    let getTeamData = {
      'page': 1,
      'limit': 1000,
      'search': ""
    }

    this.service.postApi(`organizer/competition/getTeamInCompetition?userId=` + this.userDetails._id + `&competitionId=` + this.competitionId, getTeamData, 1).subscribe(response => {
      if (response.responseCode == 200) {
        this.getTeamList = []
        this.getTeamList = response.result.docs
      //  console.log(this.getTeamList)
      }
    })
    //******************* End ******************//
  }
  editBtn() {
    this.getTeamListFun()
  }

  getGameData() {

    this.service.spinnerShow();
    this.getObservation1()
    var apireq;
    if (this.team1Id !== 'noId' && this.team2Id !== 'noId') {
      apireq = {
        teams: [this.team1Id, this.team2Id],
        type: '',
        gameId: this._gameId,
        gameType: this.compititionData.sports
      };
    } else if (this.team1Id !== 'noId') {
      apireq = {
        teams: [this.team1Id],
        type: '1',
        gameId: this._gameId,
        gameType: this.compititionData.sports
      };
    } else if (this.team2Id !== 'noId') {
      apireq = {
        teams: [this.team2Id],
        type: '2',
        gameId: this._gameId,
        gameType: this.compititionData.sports
      };
    } else {
    //  console.log('this.paramData match report els ===>>>');
      return;
    }
    if (this.compititionData.sports == 'Basketball') {
      this.service.postApi('game/basketballGameDetails', apireq, 1).subscribe(success => {
        if (success.responseCode === 200) {
          this.gameData = {
            'gameData': success,
            'competitionData': this.compititionData,
          }
          // this.gameData = success;
        //  console.log("this.game details from match-report BasketBall component", this.gameData);
          this.runSelector = true;
        }
        this.service.spinnerHide();
      }, error => {
        this.service.spinnerHide();
        this.service.toastrErr('Something went wrong Basketball');
      });
    }
    else if (this.compititionData.sports == 'Badminton' && this.compititionData.sportType == 'double') {
      this.service.postApi('game/badmintonDoubleGameDetails', apireq, 1).subscribe(success => {
        if (success.responseCode === 200) {

          this.gameData = {
            'gameData': success,
            'competitionData': this.compititionData,
          }
          // this.gameData = success;
        //  console.log("this.game details from match-report Badminton-double component", this.gameData);
          this.runSelector = true;
        }
        this.service.spinnerHide();
      }, error => {
        this.service.spinnerHide();
        this.service.toastrErr('Something went wrong Badminton' );
      });
    }
    else if (this.compititionData.sports == 'Badminton' && this.compititionData.sportType == 'single') {
      this.service.postApi('game/badmintonSingleGameDetails', apireq, 1).subscribe(success => {
        if (success.responseCode === 200) {
          this.gameData = {
            'gameData': success,
            'competitionData': this.compititionData,
          }
          // this.gameData = success;
        //  console.log("this.game details from match-report badminton-single component", this.gameData);
          this.runSelector = true;
        }
        this.service.spinnerHide();
      }, error => {
        this.service.spinnerHide();
        this.service.toastrErr('Something went wrong Badminton-s');
      });
    }
    else if (this.compititionData.sports == 'Generic' && this.compititionData.sportType == 'double') {
      this.service.postApi('game/genericDoubleGameDetails', apireq, 1).subscribe(success => {
        if (success.responseCode === 200) {
          this.gameData = {
            'gameData': success,
            'competitionData': this.compititionData,
          }
          // this.gameData =  this.compititionData;
        //  console.log(" details from Match Report generic-double game component", this.gameData);
          this.runSelector = true;
        }
        this.service.spinnerHide();
      }, error => {
        this.service.spinnerHide();
        this.service.toastrErr('Something went wrong Generic-double');
      });
    }
    else if (this.compititionData.sports == 'Generic' && this.compititionData.sportType == 'single') {
      this.service.postApi('game/genericSingleGameDetails', apireq, 1).subscribe(success => {
        if (success.responseCode === 200) {
          this.gameData = {
            'gameData': success,
            'competitionData': this.compititionData,
          }
          // this.gameData =  this.compititionData;
        //  console.log(" details from Match Report generic-single game component", this.gameData);
          this.runSelector = true;
        }
        this.service.spinnerHide();
      }, error => {
        this.service.spinnerHide();
        this.service.toastrErr('Something went wrong Generic-single');
      });
    }
    else if (this.compititionData.sports == 'Volleyball') {
      this.service.postApi('game/voleyballGameDetails', apireq, 1).subscribe(success => {
        if (success.responseCode === 200) {

          this.gameData = {
            'gameData': success,
            'competitionData': this.compititionData,
          }

        //  console.log(" details from Match Report Volley-ball game component", this.gameData);
          this.runSelector = true;
        }
        this.service.spinnerHide();
      }, error => {
        this.service.spinnerHide();
        this.service.toastrErr('Something went wrong Volleyball');
      });
    }
    else if (this.compititionData.sports == 'Table Tennis') {
      this.service.postApi('game/tableTennisSingleGameDetails', apireq, 1).subscribe(success => {
        if (success.responseCode === 200) {
          this.gameData = {
            'gameData': success,
            'competitionData': this.compititionData,
          }
        //  console.log("gameData of  Table Tennis Component", this.gameData);
          // this.gameData =  this.compititionData;
        //  console.log(" details from Match Report Table Tennis game component", this.gameData);
          this.runSelector = true;
        }
        this.service.spinnerHide();
      }, error => {
        this.service.spinnerHide();
        this.service.toastrErr('Something went wrong Table Tennis');
      });
    }
    else if (this.compititionData.sports == 'Cricket') {
      this.service.postApi('game/cricketGameDetails', apireq, 1).subscribe(success => {
        if (success.responseCode === 200) {
          this.gameData = {
            'gameData': success,
            'competitionData': this.compititionData,
          }
          ////console.log("gameData of  Cricket Component", this.gameData);
          // this.gameData =  this.compititionData;
        //  console.log(" details from Match Report Cricket game component", this.gameData);
          this.runSelector = true;
        }
        this.service.spinnerHide();
      }, error => {
        this.service.spinnerHide();
        this.service.toastrErr('Something went wrong Cricket');
      });
    }
    else if (this.compititionData.sports == 'Soccer') {
      this.service.postApi('game/soccerGameDetails', apireq, 1).subscribe(success => {
        if (success.responseCode === 200) {
          this.gameData = {
            'gameData': success,
            'competitionData': this.compititionData,
          }
        //  console.log("HIIIIIIIPOp", success)
        //  console.log("HIIIIIIIPOp2", this.gameData);
          this.runSelector = true;
        }
        this.service.spinnerHide();
      }, error => {
        this.service.spinnerHide();
        this.service.toastrErr('Something went wrong Soccer');
      });
    }
    else if (this.compititionData.sports == 'Generic' && this.compititionData.sportType == 'team') {
      this.service.postApi('game/genericTeamGameDetails', apireq, 1).subscribe(success => {
        if (success.responseCode === 200) {
          this.gameData = {
            'gameData': success,
            'competitionData': this.compititionData,
          }
        //  console.log("gameData of Generic Team Component", this.gameData)
          this.runSelector = true;
        }
        this.service.spinnerHide();
      }, error => {
        this.service.spinnerHide();
        this.service.toastrErr('Something went wrong Generic-team');
      });
    }
    else if (this.compititionData.sports == 'Swimming') {
      this.service.postApi('game/soccerGameDetails', apireq, 1).subscribe(success => {
        if (success.responseCode === 200) {
          this.gameData = {
            'gameData': success,
            'competitionData': this.compititionData,
          }
        //  console.log("gameData of Swimming Component", this.gameData)
          this.runSelector = true;
        }
        this.service.spinnerHide();
      }, error => {
        this.service.spinnerHide();
        this.service.toastrErr('Something went wrong-Swimming');
      });
    }
  }

  getroundId() {
    this.service.getApi(`game/roundIdByGameIdAndCompetitionId?gameId=${this._gameId}&competitionId=${this.iscompId}`, 0).subscribe(success => {
    //  console.log("wwf", success.roundId);
      // this.roundId = success.roundId;
      this.service.getApi(`organizer/fetchScoreByRoundId?gameId=${this._gameId}&roundId=${this.isroundId}`, 0).subscribe(success => {
        this.mResult = success.gamesArray[0];
      //  console.log("mResult", this.mResult)
      })
    })
  }

  editGameInfo() {
    this.getroundId();
  //  console.log("this.roundId", this.roundId);

    if (this.editGameForm.invalid) {
      return;
    }
    let apireq = {
      gameId: this.paramData.gameId,
      roundId: this.roundId,
      teamName1: this.editGameForm.value.team1,
      teamName2: this.editGameForm.value.team2,
      result1: this.editGameForm.value.result1,
      result2: this.editGameForm.value.result2,
      result: this.editGameForm.value.result1 + ' - ' + this.editGameForm.value.result2,
      matchTime: this.editGameForm.value.date === '' ? '' : (this.editGameForm.value.date.epoc * 1000),
      place: this.editGameForm.value.place,
    };
    //// console.log('apireq  ==>>', apireq);
    this.service.postApi('organizer/editRound', apireq, 1).subscribe(success => {
      if (success.responseCode === 200) {
        this.service.toastrSucc(success.responseMessage);
        // this.getRoundGames ();
        $('#edit-game').modal('hide');
      } else {
        if (success.responseCode === 201) {
          this.service.toastrSucc(success.responseMessage);
        }
      }
    }, error => {
      this.service.toastrErr('Something went wrong');
    });
  }

  getObservation1(){
    this.service.spinnerShow();
    let apireq = {
     gameId : this._gameId,
     groupId : this.groupId,
     roundId :  this.roundId
    }

    this.service.postApi('game/get_overAllGameObeservation',apireq,1).subscribe(success=>{
      if(success.responseCode == 200){
        this.observation = success.result.observation
        this.service.toastrSucc(success.responseMessage);
      }
    },error=>{
      this.service.spinnerHide();
      this.service.toastrErr(error);
    })
  }
}



