import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MainService } from 'src/app/providers/main-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-upcoming-tournaments',
  templateUrl: './upcoming-tournaments.component.html',
  styleUrls: ['./upcoming-tournaments.component.scss'],
  providers: [DatePipe]
})
export class UpcomingTournamentsComponent implements OnInit {

  constructor(private service: MainService, private datePipe: DatePipe, private router: Router) { }

  userId: String;
  competitionList: any[];
  upcomingCompetitions: any[];
  currentDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  ngOnInit(): void {
    this.userId = this.service.getStorage('userId');
    this.getCompetitionList();
  }
 dateSplit(date){
  let m = date.split('T')
  return m[0]
 }
  isFutureDate(idate){
  var today = new Date().getTime(),
      idate = idate.split("-");
  
  idate = new Date(idate[0], idate[1] - 1, idate[2]).getTime();
  return (today - idate) < 0 ? true : false;
  }
  getCompetitionList() {
    // /"02 dd/03mm/2016 yyy"
    let date =this.isFutureDate(this.dateSplit('2023-03-05'));
    this.service.getApi(`organizer/getAllCompetitionByUserId?userId=${this.userId}`, 0).subscribe(response => {
      if (response.responseCode == 200) {
        this.competitionList = response.result;
        this.upcomingCompetitions = this.competitionList.filter(
          // competition => this.currentDate < this.dateSplit(competition.startDate)  && this.currentDate < this.dateSplit(competition.endDate));
          competition => this.isFutureDate((this.dateSplit(competition.startDate))));

      }
    });
  }
  navigateTodetailPage(id) {
    this.router.navigate(['/tournament-groups'], { queryParams: { id } })
  }
}
