<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <img src="assets/img/team-member1.jpg" class="player-image" alt="image">
            <br>
            <h1 title="Sarah Taylor">Sarah Taylor</h1>
            <span class="sub-title">Sarah3s</span>
        </div>
    </div>
</section>

<section class="player-details-area ptb-100">
    <div class="container">
        <div class="player-details-desc">
            <span class="sub-title">DOTA 01</span>
            <h3>About the player</h3>
            <p>Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget.</p>

            <ul class="player-meta">
                <li>
                    <div class="icon">
                        <i class="flaticon-3d"></i>
                    </div>
                    TRN RATING
                    <span>3204.5</span>
                </li>

                <li>
                    <div class="icon">
                        <i class="flaticon-network"></i>
                    </div>
                    WINS / 1201 MATCHES
                    <span>617</span>
                </li>

                <li>
                    <div class="icon">
                        <i class="flaticon-fall"></i>
                    </div>
                    FRAGS / 2401 MATCHES
                    <span>00</span>
                </li>

                <li>
                    <div class="icon">
                        <i class="flaticon-game-computer"></i>
                    </div>
                    WIN RATIO
                    <span>74.8%</span>
                </li>

                <li>
                    <div class="icon">
                        <i class="flaticon-network"></i>
                    </div>
                    Follow
                    <div class="social">
                        <a href="#" target="_blank"><i class="bx bxl-facebook"></i></a>
                        <a href="#" target="_blank"><i class="bx bxl-twitch"></i></a>
                        <a href="#" target="_blank"><i class="bx bxl-twitter"></i></a>
                        <a href="#" target="_blank"><i class="bx bxl-youtube"></i></a>
                    </div>
                </li>
            </ul>


          
        </div>
    </div>
</section>



<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>