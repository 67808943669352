import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/providers/main-service.service';

@Component({
  selector: 'app-tournaments-details-page',
  templateUrl: './tournaments-details-page.component.html',
  styleUrls: ['./tournaments-details-page.component.scss'],
  providers: [DatePipe]
})
export class TournamentsDetailsPageComponent implements OnInit {
  userId: String;
  competitionList: any;
  upcomingCompetitions: any= [];
  compID:any
  currentDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  constructor(private service: MainService, private datePipe: DatePipe,private activatedRoute:ActivatedRoute) { 
this.activatedRoute.queryParams.subscribe((res)=>{
  this.compID = res.id
})
  }
  ngOnInit(): void {
    this.userId = this.service.getStorage('userId');
    this.getComptetionDetails();
  }
getComptetionDetails(){
  let compData = {
    userId: this.userId,
    competitionId: this.compID
  };
  let url = `organizer/competition/getACompetition`
  this.service.postApi(url,compData, 0).subscribe(response => {
    if (response.responseCode == 200) {
      this.competitionList = response.result;
      // this.competitionList = this.competitionList.slice().reverse();

      // this.upcomingCompetitions = this.competitionList.filter(
      //   competition => this.currentDate < competition.startDate && this.currentDate < competition.endDate);
    }
  });
}
}
