<div class="table-responsive">
    <table class="table table-bordered">
        <thead>
            <tr class="table-head-bg">
              <th></th>
              <th>Total Score</th>
              <th>Total Outs</th>
              <th>Net Score</th>
              <th>Leading Skins</th>
              <th>Lagging Skins</th>
              <th>Best Batsman</th>
              <th>Best Bowler</th>
            </tr>
          </thead>
          <tbody>
            <tr >
              <td>{{ cricketGameDetailsData?.result?.teamDeatailsOne[0].teamDetails[0].teamName }}</td>
              <td>{{cricketGameDetailsData?.result?.teamDeatailsOne[0].totalScore }}</td>
              <td>{{cricketGameDetailsData?.result?.teamDeatailsOne[0].totalOut }}</td>
              <td>{{ cricketGameDetailsData?.result?.teamDeatailsOne[0].netScore }}</td>
              <td>{{cricketGameDetailsData?.result?.teamDeatailsOne[0].leadingSkins }}</td>
              <td>{{ cricketGameDetailsData?.result?.teamDeatailsOne[0].laggingSkins }}</td>
              <td *ngIf="!cricketGameDetailsData?.result?.teamDeatailsOne.bestBatsman">select from Team A - player names</td>
              <td *ngIf="cricketGameDetailsData?.result?.teamDeatailsOne.bestBatsman">{{ cricketGameDetailsData?.result?.teamDeatailsOne.bestBatsman }}</td>

              <td *ngIf="!cricketGameDetailsData?.result?.teamDeatailsOne.bestBowler">select from Team A - player names</td>
              <td *ngIf="cricketGameDetailsData?.result?.teamDeatailsOne.bestBowler">{{ cricketGameDetailsData?.result?.teamDeatailsOne.bestBowler}}</td>
            </tr>
            <tr >
              <td>{{cricketGameDetailsData?.result?.teamDeatailsTwo[0].teamDetails[0].teamName }}</td>
              <td>{{ cricketGameDetailsData?.result?.teamDeatailsTwo[0].totalScore }}</td>
              <td>{{ cricketGameDetailsData?.result?.teamDeatailsTwo[0].totalOut }}</td>
              <td>{{cricketGameDetailsData?.result?.teamDeatailsTwo[0].netScore }}</td>
              <td>{{ cricketGameDetailsData?.result?.teamDeatailsTwo[0].leadingSkins }}</td>
              <td>{{ cricketGameDetailsData?.result?.teamDeatailsTwo[0].laggingSkins }}</td>
              <td *ngIf="!cricketGameDetailsData?.result?.teamDeatailsTwo.bestBatsman">select from Team B - player names</td>
              <td *ngIf="cricketGameDetailsData?.result?.teamDeatailsTwo.bestBatsman">{{ cricketGameDetailsData?.result?.teamDeatailsTwo.bestBatsman }}</td>

              <td *ngIf="!cricketGameDetailsData?.result?.teamDeatailsTwo.bestBowler">select from Team B - player names</td>
              <td *ngIf="cricketGameDetailsData?.result?.teamDeatailsTwo.bestBowler">{{ cricketGameDetailsData?.result?.teamDeatailsTwo.bestBowler}}</td>
            </tr>
          </tbody>
    </table>
</div>


<div class="table-responsive mt-5">
    <table class="table table-bordered">
        <thead>
            <tr class="table-head-bg">
              <th>{{ cricketGameDetailsData?.result?.teamDeatailsOne[0]?.teamDetails[0]?.teamName }}</th>
              <th colspan="7"> Batting Performance</th>
            </tr>
          </thead>
          <tbody>
            <tr class="strong-color">
              <th>Skin</th>
              <th>Player No.</th>
              <th>Player First Name</th>
              <th>Player Last Name</th>
              <th>Runs</th>
            </tr>
            <tr *ngFor = " let data of cricketGameDetailsData?.result?.teamOne">
             <td>{{ data?.skin}}</td>
              <td>{{ data?.playerNumber }}</td>
              <td>{{ data?.userDetails[0]?.firstName }}</td>
              <td>{{ data?.userDetails[0]?.lastName }}</td>
              <td>{{ data?.runs }}</td>
            </tr> 
          </tbody>
    </table>
</div>

<div class="table-responsive mt-5">
    <table class="table table-bordered">
        <thead>
            <tr class="table-head-bg">
              <th>{{ cricketGameDetailsData?.result?.teamDeatailsTwo[0]?.teamDetails[0]?.teamName }}</th>
              <th colspan="7"> Batting Performance</th>
            </tr>
          </thead>
          <tbody>
            <tr class="strong-color">
              <th>Skin</th>
              <th>Player No.</th>
              <th>Player First Name</th>
              <th>Player Last Name</th>
              <th>Runs</th>
            </tr>
            <tr *ngFor = " let data of cricketGameDetailsData?.result?.teamTwo">
              <td>{{ data?.skin }}</td>
              <td>{{ data?.playerNumber }}</td>
              <td>{{ data?.userDetails[0]?.firstName }}</td>
              <td>{{ data?.userDetails[0]?.lastName }}</td>
              <td>{{ data?.runs }}</td>
            </tr>
            <!-- <tr>
             <td>2nd Skin</td>
              <td>11</td>
              <td>Kajal</td>
              <td>Garg</td>
              <td>34</td>
            </tr>
            <tr>
             <td>3rd Skin</td>
              <td>11</td>
              <td>Kajal</td>
              <td>Garg</td>
              <td>34</td>
            </tr>
            <tr>
             <td>4th Skin</td>
              <td>11</td>
              <td>Kajal</td>
              <td>Garg</td>
              <td>34</td>
            </tr> -->
          </tbody>
    </table>
</div>