import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/providers/main-service.service';

@Component({
  selector: 'app-venue-player-header',
  templateUrl: './venue-player-header.component.html',
  styleUrls: ['./venue-player-header.component.scss']
})
export class VenuePlayerHeaderComponent implements OnInit {

  items: any = [];
  venueImages: any = [];
  currUrl: string;
  venueId: any;
  organizerId: any;
  userDetails: any;
  isLoginVistUser:any
  @Input() venueDetail;
  @Input() venueIdGet;
  loginId:any
  constructor(private fb: FormBuilder, private service: MainService, private router: Router, private route: ActivatedRoute) { 
    console.log(this.venueDetail,this.venueIdGet);
  }
  ngOnInit() {
    this.isLoginVistUser = this.service.isLoginVisitUser()
    this.loginId = this.isLoginVistUser?._id
    console.log('UUYD',this.isLoginVistUser )
    let currRoute = this.router.url
    let currPage = currRoute.split("/");
    this.currUrl = currPage[2]
    this.getVenueDetailApi(this.service.isRolePlayer)
  }

  /************************************************** GET VENUE DETAIL  API ********************************************************/
  getVenueDetailApi(isPlayer) {
    let userId = localStorage.getItem('userId')
let url 
let member
if(isPlayer){
  url = `venue/getDetailOfAVenue?venueId=${this.venueIdGet}`
  member =  {
    playerId : this.isLoginVistUser?._id,
    "page":1,
    "limit":100
    }
}else{
  url = `venue/getDetailOfAVenue?venueId=${this.venueIdGet}`
  member =  {
  playerId : userId
  }
}
    this.service.postApi(url, member, 1).subscribe(response => {
      if (response.responseCode == 201 || response.responseCode == 200) {
        let venueDetail = response.result
        this.venueImages = venueDetail.images
      }
    })
  }
  /********************************************************** END ******************************************************************/
  getActiveResult(edr){
    
  }
}
