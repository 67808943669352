import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {MainService} from './main-service.service';

@Injectable({
  providedIn: 'root'
})
export class CanActiveRouteGuard implements CanActivate {
  userId :any;
  constructor(private router: Router, private service: MainService){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
       this.userId = this.service.getStorage('userId');
      console.log("##----",this.userId); 
      
      if(this.service.getStorage('userId') != null) {
          console.log("##-right-",this.userId);   
          return true;       
      } else {
        console.log("else",this.userId);
          return false;
      }
  }
  
  
}
