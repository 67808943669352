import { Component, Input, OnInit } from '@angular/core';
import { MainService } from 'src/app/providers/main-service.service';

@Component({
  selector: 'app-standing',
  templateUrl: './standing.component.html',
  styleUrls: ['./standing.component.scss']
})
export class StandingComponent implements OnInit {
  @Input() grouporcup : any
  @Input() activeTabId : any
  @Input() roundWiseData : any
  @Input() groupId : string
  @Input() competitionList : any
  cupTeamData: any = [];
  thirdAndForthPlace: any = [];
  cupTeamList : any = []
  roundId: any;
  particularRoundGame: any;
  userId: string;  
  groupTeamList: any = [];
  particularRoundGamelimit: any = 2;
  particularRoundGameTotal: any;
  particularRoundGameTotalCurrentPage: any = 1;
  list: any = { sportsList: [], refereeList: {}, limitChangeArr: ["2", "10", "25", "50", "100"], limit: 5 };
  messageFromObservation: any;
  constructor(private service : MainService) { 
    this.userId = this.service.getStorage('userId');
  }

  ngOnInit(): void {
    console.log(this.roundWiseData);
    
    this.cupTeamListApi()
    this.groupTeamListApi()
    // alert(competitionList)
  
    console.log(this.competitionList);
    
  }
  cupTeamListApi() {
    let apireq = {
      cupId: this.activeTabId,
    };
    console.log('apireq ===>>>' , apireq);
    this.service.postApi('organizer/getGameListCupWise', apireq, 1).subscribe(
      success => {
        if (success.responseCode === 200) {
          this.cupTeamList = success.results;
          this.manageCupData(this.cupTeamList);
        }
      },
      error => {
        this.service.toastrErr('Something went wrong');
      }
    );
  }

  manageCupData(cupTeamList) {
    this.thirdAndForthPlace = [];
    this.cupTeamData = [];
    var finaldata = [];
    var semiFinaldata = [];
    var quarterFinaldata = [];
    let sixtyFourthdata = [];
    let thirtyTwodata = [];
    let sixteenthdata = [];
    let eighthdata = [];
    cupTeamList.forEach((element, i) => {
        if (element.roundType === 'Final') {
          finaldata.push(element);
        } else {
          if (element.roundType === 'Semi Final') {
            semiFinaldata.push(element);
          } else {
            if (element.roundType === 'Quarter') {
              quarterFinaldata.push(element);
            } else {
              if (element.roundType === '64') {
                sixtyFourthdata.push(element);
              } else {
                if (element.roundType === '32') {
                  thirtyTwodata.push(element);
                } else {
                  if (element.roundType === '16') {
                    sixteenthdata.push(element);
                  } else {
                    if (element.roundType === '8') {
                      eighthdata.push(element);
                    }
                  }
                }
              }
            }
          }
        }
        if (element.roundType === 'thirdAndFourthPlace') {
          this.thirdAndForthPlace.push(element);
        }
    });
    console.log('this.thirdAndForthPlace ===>>>', this.thirdAndForthPlace);
    if (sixtyFourthdata.length) {
      this.cupTeamData.push(sixtyFourthdata);
    }
    if (thirtyTwodata.length) {
      this.cupTeamData.push(thirtyTwodata);
    }
    if (sixteenthdata.length) {
      this.cupTeamData.push(sixteenthdata);
    }
    if (eighthdata.length) {
      this.cupTeamData.push(eighthdata);
    }
    if (quarterFinaldata.length) {
      this.cupTeamData.push(quarterFinaldata);
    }
    if (semiFinaldata.length) {
      this.cupTeamData.push(semiFinaldata);
    }
    if (finaldata.length) {
      this.cupTeamData.push(finaldata);
    }
    console.log('this.cupTeamData ===>>>', this.cupTeamData);
  }

  sliceFunction(data) {
    return (data);
}

getRoundsId(roundId) {
  this.roundId = roundId.target.value;
  console.log('this.roundId ==>>', this.roundId);
  this.getRoundGames();
}

getRoundGames () {
 this.getRefereeListApi()
  let apireq = {
    roundId : this.roundId
  };
  this.service.postApi('organizer/roundDetails', apireq, 1).subscribe(success => {
    if (success.responseCode === 200) {
      this.particularRoundGame = success.roundData.games;

      this.particularRoundGameTotal = success.roundData.games.length;
      this.particularRoundGame = success.roundData.games.length;
  
    } else {
      if (success.responseCode === 501) {
        this.particularRoundGame = [];
        this.particularRoundGame = 0;
      }
    }
  }, error => {
    this.service.toastrErr('Something went wrong');
  });
}
getRefereeListApi() {
  return new Promise((resolve, reject) => {
    let bodyData = { 'page': 1, 'limit': 100 }
    this.service.postApi('data/getListOfReferee?userId=' + this.userId, bodyData, 1).subscribe(responseList => {
      console.log(responseList)
      let Response = responseList;
      if (Response['responseCode'] == 200) {
        this.list.refereeList = Response[`result`]['docs']
        resolve(true)
      }
    })
  })
}

groupTeamListApi() {
  let apireq = {
    groupId: this.groupId,
    search: '',
    limit: 100,
    pageNumber: 1,
    sortType:""
  };
  this.service.postApi('organizer/teamListInAGroup', apireq, 1).subscribe(
    success => {
      if (success.responseCode === 200 && success.allData.length>0) {
        this.groupTeamList = success.allData;

      } else {
       this.groupTeamList = [];
       
      }
     if (success.responseCode === 404) {
          this.groupTeamList = [];
        
        }
    },
    error => {
      this.service.toastrErr('Something went wrong');
    }
  );
}
acctiveTab:any='result1'
getActiveResult(e){
  this.acctiveTab = e

}
gameId:any
getObservation(teamId){
  let apireq = {
    "teamId":teamId,
  }

  this.service.postApi('game/getObservationGameByTeamId',apireq,1).subscribe(success=>{
    if(success.responseCode == 200 && success.result && success.result.observation){
      this.messageFromObservation = success.result.observation
    }
  },error=>{
    this.service.toastrErr(error);
  })
}




_gameId : any
team1Id : any
team2Id : any
basketballGameDetailsData : any
playerOne : any
playerTwo : any
finalScoreOne : any
finalScoreTwo : any
scoreArrayOne : any
scoreArrayTwo : any
basketballGameDetails(team1Id,team2Id,_gameId){
  this.team1Id = team1Id
  this.team2Id = team2Id
  this._gameId = _gameId
  // this.service.spinnerShow();
  // let apireq = {
  //      teams: [team1Id,team2Id] ,
  //      gameId: _gameId
  // }
  // this.service.postApi('game/basketballGameDetails', apireq , 0).subscribe(success => {
  //   if (success.responseCode === 200) {
  //     this.basketballGameDetailsData = success;
  //     this.scoreArrayOne =  this.basketballGameDetailsData.result.teamDeatailsOne[0].scoreData;
  //     this.scoreArrayTwo = this.basketballGameDetailsData.result.teamDeatailsTwo[0].scoreData;
  //     this.finalScoreOne = this.basketballGameDetailsData.result.teamDeatailsOne[0].finalScore;
  //     this.finalScoreTwo = this.basketballGameDetailsData.result.teamDeatailsTwo[0].finalScore;
  //     this.playerOne = this.basketballGameDetailsData.result.teamDeatailsOne[0].mvp;
  //     this.playerTwo = this.basketballGameDetailsData.result.teamDeatailsTwo[0].mvp;
  //     // console.log("success score of basketBallGameDetails ======>",this.scoreArrayOne,this.scoreArrayTwo);
  //     this.service.toastrSucc(success.responseMessage);
  //   } 
  //   this.service.spinnerHide();
  // }, error => {
  //   this.service.spinnerHide();
  //   this.service.toastrErr(error);
  // });
}
}
