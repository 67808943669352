<div id="Starts" class=" tab-pane active show" *ngIf="!observation">
    <div class="row">
       <div class="col-lg-12">
          <div class="scoreboard-table-block1">
             <div class="scoreboard-table-inner">
              <div class="status-content-block">
                <div>
                  <div>
                     <h3 style="text-align: center; margin-top: 10px;"> Competition Details </h3>
                     <table class="table table-bordered large-table">
                        <thead>
                           <tr class="table-head-bg">
                              <th>Competition</th>
                              <th>Division</th>
                              <th>Period</th>
                              <th>Date</th>
                              <th>Venue</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr >
                              <td>{{gameData?.competitionData?.competitionName}}</td>
                              <td>{{ gameData?.competitionData?.division }}</td>
                              <td>{{ gameData?.competitionData?.period}}</td>
                              <td>{{ gameData?.competitionData?.startDate | date:'mediumDate' }}</td>
                              <td>{{ gameData?.competitionData?.venue }}</td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
               <h3 style="text-align: center;" > Statistics </h3>
               </div>
                 <div class="table-scrool-box">
                 <div class="table-block mt30">
                    <div class="table-responsive">
                        <table class="table table-bordered text-center">
                           <thead>
                             <tr class="table-head-bg">
                               <th>Team Name</th>
                               <th colspan="2">Set 1 Score</th>
                               <th colspan="2">Set 2 Score</th>
                               <th colspan="2">Set 3 Score</th>
                               <th colspan="2">Set 4 Score</th>
                               <th colspan="2">Set 5 Score</th>
                               <th>Final Score</th>
                               <th>MVP</th>
                             </tr>
                           </thead>
                           <tbody>
                             <tr>
                               <th></th>
                               <th>Set 1 Score</th>
                               <th>Time Outs</th>
                               <th>Set 2 Score</th>
                               <th>Time Outs</th>
                               <th>Set 3 Score</th>
                               <th>Time Outs</th>
                               <th>Set 4 Score</th>
                               <th>Time Outs</th>
                               <th>Set 5 Score</th>
                               <th>Time Outs</th>
                               <th></th>
                               <th></th>
                             </tr>
                             <tr>
                               <th>{{ voleyBallGameDetailsData?.result?.teamDeatailsOne[0]?.teamDetails[0]?.teamName }}</th> 
                               <td colspan="2" *ngFor= "let data of teamOneScore">
                                 <span class="span" >{{ data.score }}</span> 
                                 <span >{{ data.timeOut }}</span>
                               </td>
                               <td> {{ finalScoreOne }} </td>
                               <td *ngIf="!mvpOfTeamOne">Select Player From Team {{this.voleyBallGameDetailsData?.result?.teamDeatailsOne[0]?.teamDetails[0]?.teamName}}</td>
                               <td *ngIf="mvpOfTeamOne">{{mvpOfTeamOne}}</td>
                             </tr>
                             <tr>
                               <th>{{ voleyBallGameDetailsData?.result?.teamDeatailsTwo[0]?.teamDetails[0]?.teamName }}</th>
                               <td colspan="2" *ngFor = " let data of teamTwoScore" >
                                 <span class="span">{{data.score}}</span>
                                 <span> {{data.timeOut}} </span>
                               </td>
                               <td>{{ finalScoreTwo }}</td>
                               <td *ngIf="!mvpOfTeamTwo">Select Player From Team {{this.voleyBallGameDetailsData?.result?.teamDeatailsTwo[0]?.teamDetails[0]?.teamName}}</td>
                               <td *ngIf="mvpOfTeamTwo">{{mvpOfTeamTwo}}</td>
                             </tr>
                           </tbody>
                         </table>
                         <!-- <button class="btn btn btn-dark-blue-s" data-toggle="modal" data-target="#score" data-backdrop="static" data-keyboard="false" style="width:147px">Edit Score</button> -->
                    </div>
                 </div>
                 <div class="tableentry">
                   <div class="row">
                   <div class="col-md-6">
                     <div class="table-block mt10">
                       <div class="table-responsive">
                        <table class="table table-bordered text-center">
                           <thead>
                             <tr class="table-head-bg">
                               <th colspan="4" class="text-align-left">{{ voleyBallGameDetailsData?.result?.teamDeatailsOne[0]?.teamDetails[0]?.teamName }}</th>

                             </tr>
                           </thead>
                           <tbody>
                             <tr class="strong-color">
                               <th>Player No</th>
                               <th>Player First Name</th>
                               <th>Player Last Name</th>
                               <th>Points</th>
                             </tr>
                             <tr *ngFor=" let data of voleyBallGameDetailsData?.result?.teamOne">
                               <td>{{ data.playerNumber }}</td>
                               <td>{{ data.userDetails[0].firstName }}</td>
                               <td>{{ data.userDetails[0].lastName }}</td>
                               <td>{{ data.points }}</td>
                             </tr>
                           </tbody>
                         </table>
                     </div>
                   </div>
                   </div>
                   <div class="col-md-6">
                     <div class="table-block mt10">
                       <div class="table-responsive">
                        <table class="table table-bordered text-center">
                           <thead>
                             <tr class="table-head-bg">
                               <th colspan="4"  class="text-align-left">{{ voleyBallGameDetailsData?.result?.teamDeatailsTwo[0]?.teamDetails[0]?.teamName }}</th>
                             </tr>
                           </thead>
                           <tbody>
                             <tr class="strong-color">
                               <th>Player No</th>
                               <th>Player First Name</th>
                               <th>Player Last Name</th>
                               <th>Points</th>
                             </tr>
                             <tr *ngFor=" let data of voleyBallGameDetailsData?.result?.teamTwo">
                               <td>{{ data.playerNumber }}</td>
                               <td>{{ data.userDetails[0].firstName }}</td>
                               <td>{{ data.userDetails[0].lastName }}</td>
                               <td>{{ data.points }}</td>
                             </tr>
                           </tbody>
                         </table>
                     </div>
                   </div>
                 </div>
               </div>
               <div class="row">
                 <div class="col-md-6">
                   <!-- <button class="btn btn btn-dark-blue-s" data-toggle="modal" data-target="#editScorePlayerWiseTeamOne" data-backdrop="static" style="float: left" data-keyboard="false">Edit Player's Score</button> -->
                 </div>
                 <div class="col-md-6">
                   <!-- <button class="btn btn btn-dark-blue-s" data-toggle="modal" data-target="#editScorePlayerWiseTeamTwo" data-backdrop="static" data-keyboard="false">Edit Player's Score</button> -->
                 </div> 
               </div>
             </div>
           </div>
            <!--  <div class=" text-right mt20">
               <button class="btn btn btn-dark-blue-s" data-toggle="modal" data-target="#socialSharing" data-backdrop="static" data-keyboard="false" style="width:147px">Publish Match</button>
             </div> -->
           </div>
         </div>
       </div>
     </div>
 </div>


 <!-- Observation -->
<div id="Information" *ngIf="observation">
  <div class="tab-inner-text-block">
    <div class="col-md-12 col-lg-12 marginzero">
      <textarea class="form-control common-textarea" readonly [(ngModel)]="observationMessage"></textarea>
      <!-- <div class=" text-right mt10">
        <button class="btn btn btn-dark-blue-s" (click)="updateObservation()">Update Observation</button>
        <button class="btn btn btn-dark-blue-s" [disabled]="messageFromObservation" (click)="addObservation()">Add Observation</button>
      </div> -->
    </div>
  </div>
</div>


