<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <h1 title="Cart">Cart</h1>
        </div>
    </div>
</section>

<section class="cart-area ptb-100">
    <div class="container">
        <form>
            <div class="cart-table table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Product</th>
                            <th scope="col">Name</th>
                            <th scope="col">Unit Price</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Total</th>
                        </tr>
                    </thead>

                    <tbody>


                        <tr *ngFor="let item of cartList">
                            <td class="product-thumbnail">
                                <a routerLink="/single-products" [queryParams]="{_id : item?.productId?._id}">
                                    <img [src]="item?.productId?.productImage?.url ||'assets/img/products-img2.jpg'"
                                        alt="item">
                                </a>
                            </td>

                            <td class="product-name">
                                <a routerLink="/single-products"
                                    [queryParams]="{_id : item?.productId?._id}">{{item?.productId?.productType?.productType
                                    || 'N/A'}}</a>
                            </td>

                            <td class="product-price">
                                <span class="unit-amount">AED {{item?.price}}</span>
                            </td>

                            <td class="product-quantity">
                                <div class="input-counter">
                                    <span class="minus-btn" (click)="updateQuantity(1,item)"><i
                                            class='bx bx-minus'></i></span>
                                    <input type="text" min="1" value="1" [(ngModel)]="item.quantity"
                                        [ngModelOptions]="{standalone: true}">
                                    <span class="plus-btn" (click)="updateQuantity(2,item)"><i
                                            class='bx bx-plus'></i></span>
                                </div>
                            </td>

                            <td class="product-subtotal">
                                <span class="subtotal-amount">AED {{item?.totalPrice}}</span>
                                <a class="remove" (click)="removecart(item?._id)"><i class='bx bx-trash'></i></a>
                            </td>
                        </tr>


                    </tbody>
                </table>
            </div>

            <div class="cart-buttons">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-sm-7 col-md-7">
                        <div class="shopping-coupon-code" style="display: none;">
                            <input type="text" class="form-control" placeholder="Coupon code" name="coupon-code"
                                id="coupon-code">
                            <button type="submit">Apply Coupon</button>
                        </div>
                    </div>

                    <div class="col-lg-5 col-sm-5 col-md-5 text-right">
                        <a (click)="updateAllCartValue()" class="default-btn">Update Cart</a>
                    </div>
                </div>
            </div>

            <div class="cart-totals">
                <h3>Cart Totals</h3>
                <ul>
                    <li>Subtotal <span>AED {{subtotal}}</span></li>
                    <li>Shipping <span>AED 0.00</span></li>
                    <li>Total <span>AED {{subtotal}}</span></li>
                </ul>
                <!-- routerLink="/checkout" -->
                <a data-toggle="modal" data-target="#exampleModalCenter" class="default-btn">Proceed to Checkout</a>
            </div>
        </form>
    </div>
</section>

<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>

<!-- Modal -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            <login (navigate)="navigate()"></login>
        </div>
    </div>
</div>