// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {///
  production: false,
      //apiUrl:  "http://172.16.6.130:1808/api/v1/",
      // apiUrl:  "http://localhost:1808/api/v1/",
       apiUrl: 'https://backend.digixvsx.com/api/v1/',
  // stripeKeyLive:'pk_test_51KBtzMSI2dwhNfVRFO2Nn5UDEW4AT3I8uUv4FEFz60tVvqSVCfyZ89FmOkLu6zhUB0x1Fhbu2n2JA4C3qGiuAyuw00IKJCL23d'
  stripeKeyLive:'pk_live_51J4jyzCYuZhsBWzn5RRD0VpZfKD3Xgk1FipBFmz6TLAk5T62BovFqtVyFZ26VDrQGrjk4MEQjekXnSM4QCocHDMX00lo5TKXHU'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
