<br>
<br>

<div class="row">
    <div class="col-md-12">
        <div class="btn_row mb20">
            <button type="btn" [ngClass]="{'active': this.typeOfMedia == 'ALL'}" (click)="allMedia()"
                class="btn">All</button>
            <button type="btn" [ngClass]="{'active': this.typeOfMedia == 'ALBUM'}" (click)="albumMedia()"
                class="btn">Albums</button>
            <button type="btn" [ngClass]="{'active': this.typeOfMedia == 'VIDEO'}" (click)="videoMedia()"
                class="btn">Videos</button>
            <button type="btn" [ngClass]="{'active': this.typeOfMedia == 'NEWS'}" (click)="newsMedia()"
                class="btn">News</button>
        </div>
    </div>
</div>

<section class="match-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="content-box-filter p20  pt35 mt20" *ngFor="let media of gameDetail">
                <form *ngIf="media.typeOfMedia == 'VIDEO'" class="media-box-head1">
                    <div class="media-box-head">
                        <div class="row">
                            <div class="col-md-8">
                                <!-- <div class="media-head-img">
                                    <img *ngIf="!media.competitionId?.imageURL" src="assets/images/imageuser.png">
                                    <img *ngIf="media.competitionId?.imageURL" src="{{media.competitionId?.imageURL}}">
                                </div> -->
                                <div class="media-head-info">
                                    <h4 class="mb5">{{media.competitionName}}</h4>
                                    <p><span class="pR30">{{media.date | date :'mediumDate'}} at {{media.time}}
                                    </span></p>
                                    <h1 class='cursor'>{{media.title}}</h1>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="media-banner-img">
                        <div class="vidoe-block-media">
                            <iframe width="560" height="315" [src]='media.securedMediaURL' frameborder="0"  allow="autoplay; encrypted-media" allowfullscreen></iframe>
                            <!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/wU2xJ7klKIQ" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe> -->
                        </div>
                    </div>
                    <!-- <div class="media-inner-head ">
                        <h4 class='cursor' [routerLink]="['/player/media/PlayerMediaDetails', competitionId, organizerId, media._id]">{{media.title}}</h4>
                    </div> -->
                    <div class="media-inner-head">
                        <!-- <h4 class='cursor' [routerLink]="['/player/media/PlayerMediaDetails', competitionId, organizerId, media._id]">{{media.title}}</h4> -->
                        <div class="media-description">
                            <p class="more"[innerHTML]="media?.description" ></p>
                        </div>
                    </div>
                    <div class="like-block-main comment-block cursor line" >
                        <span>{{media.noOfLike}} Like</span> 
                        <span style="margin-left: 20px;">{{media.noOfComment}} Comment</span>
                    </div>
                    <!-- <div class="like-block-main">
                        <span (click)="like(media)" *ngIf="media.likeStatus == 'False'"><i class="far fa-heart"></i>Like</span>
                        <span (click)="like(media)" *ngIf="media.likeStatus == 'True'"><i class="fa fa-heart active"></i>Unlike</span>
                        <span class='cursor' [routerLink]="['/player/media/PlayerMediaDetails', competitionId, organizerId, media._id]"><i
                                class="far fa-comment"></i> Comment</span>
                    </div> -->
                    <div class="comments-area">
                        <h3 class="comments-title">{{media?.comments?.length || 0}} Comments:</h3>
                        <ol class="comment-list">
                            <li class="comment" *ngFor="let comData of media?.comments">
                                <article class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img style="height:63px;" [src]="comData?.commentImage || 'assets/img/imageuser.png'"  class="avatar" alt="image">
                                            <b style=" margin-left: 10px;" class="fn">{{comData?.commentFirstName}} {{comData?.commentLastName}}</b>
                                            <span style=" margin-left: 10px;"  class="says">says:</span>
                                        </div>

                                        <div class="comment-metadata">
                                            <a >
                                                <span>{{comData?.createdAt | date : 'dd - MMM,yyyy hh:mm a'}}</span>
                                            </a>
                                        </div>
                                    </footer>

                                    <div class="comment-content">
                                        <p>{{comData?.text}}</p>
                                    </div>

                                </article>

                             
                            </li>

                        </ol>

                    
                    </div>
                </form>

                <form *ngIf="media.typeOfMedia == 'ALBUM'" class="media-box-head1">
                    <div class="media-box-head">
                        <div class="row">
                            <div class="col-md-8">
                                <!-- <div class="media-head-img">
                                    <img *ngIf="!media.competitionId?.imageURL" src="assets/images/follow-img.png">
                                    <img *ngIf="media.competitionId?.imageURL" src="{{media.competitionId?.imageURL}}">
                                </div> -->
                                <div class="media-head-info">
                                    <h4 class="mb5">{{media.competitionName}}</h4>
                                    <p><span class="pR30">{{media.date | date :'mediumDate'}} at {{media.time}}</span> </p>
                                    <h1 class='cursor'>{{media.title}}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="media-banner-img " class='cursor'>
                        <ul class="media-img-list clearfix media-new">
                            <li *ngFor="let image of media.newMediaUrls; let i = index">
                                <figure *ngIf="media.newMediaUrlsLength == 0" class="thumb-img"><img src="{{image.url}}"
                                        alt="Image"></figure>
                                <figure *ngIf="media.newMediaUrlsLength > 0 && i<3" class="thumb-img"><img src="{{image.url}}"
                                        alt="Image"></figure>
                                <figure *ngIf="media.newMediaUrlsLength > 0 &&  i == 3" class="thumb-img thumb-img-more lastli"><img
                                        src="{{image.url}}" alt="Image"><span>+{{media.newMediaUrlsLength}}<br>more</span></figure>
                            </li>
                        </ul>
                    </div>
                    <div class="media-inner-head">
                        <div class="media-description">
                            <p class="more"[innerHTML]="media?.description" ></p>
                        </div>
                    </div>
                    <!-- <div class="media-inner-head">
                        <h4 class='cursor' [routerLink]="['/player/media/PlayerMediaDetails', competitionId, organizerId, media._id]">{{media.title}}</h4>
                    </div> -->
                    <div class="like-block-main comment-block cursor line" >
                        <span>{{media.noOfLike}} Like</span> 
                        <span style="margin-left: 20px;">{{media.noOfComment}} Comment</span>
                    </div>
                    <div class="comments-area">
                        <h3 class="comments-title">{{media?.comments?.length || 0}} Comments:</h3>
                        <ol class="comment-list">
                            <li class="comment" *ngFor="let comData of media?.comments">
                                <article class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img style="height:63px;" [src]="comData?.commentImage || 'assets/img/imageuser.png'"  class="avatar" alt="image">
                                            <b style=" margin-left: 10px;" class="fn">{{comData?.commentFirstName}} {{comData?.commentLastName}}</b>
                                            <span style=" margin-left: 10px;"  class="says">says:</span>
                                        </div>

                                        <div class="comment-metadata">
                                            <a >
                                                <span>{{comData?.createdAt | date : 'dd - MMM,yyyy hh:mm a'}}</span>
                                            </a>
                                        </div>
                                    </footer>

                                    <div class="comment-content">
                                        <p>{{comData?.text}}</p>
                                    </div>

                                </article>

                             
                            </li>

                        </ol>

                    
                    </div>
                    <!-- <div class="like-block-main">
                        <span (click)="like(media)" *ngIf="media.likeStatus == 'False'"><i class="far fa-heart"></i>Like</span>
                        <span (click)="like(media)" *ngIf="media.likeStatus == 'True'"><i class="fa fa-heart active"></i>Unlike</span>
                        <span class='cursor' [routerLink]="['/player/media/PlayerMediaDetails', competitionId, organizerId, media._id]"><i
                                class="far fa-comment"></i> Comment</span>
                    </div> -->
                </form>

                <form *ngIf="media.typeOfMedia == 'NEWS'"  class="media-box-head1">
                    <div class="media-box-head">
                        <div class="row">
                            <div class="col-md-8">
                                <!-- <div class="media-head-img">
                                    <img *ngIf="!media.competitionId?.imageURL" src="assets/images/follow-img.png">
                                    <img *ngIf="media.competitionId?.imageURL" src="{{media.competitionId?.imageURL}}">
                                </div> -->
                                <div class="media-head-info">
                                    <h4 class="mb5">{{media.competitionName}}</h4>
                                    <p><span class="pR30">{{media.date | date :'mediumDate'}} at {{media.time}}</span></p>
                                    <h1 class='cursor'>{{media.title}}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="media-banner-img">
                        <ul class="media-img-list clearfix media-new">
                            <li style="width: calc(50% - 5px);padding: 30px 0px 30px 0px;">
                                <figure class="thumb-img"><img src="{{media.mediaUrls[0].url}}" alt="Image"></figure>
                            </li>
                        </ul>
                    </div>
                    <div class="media-inner-head">
                        <div class="media-description">
                            <p class="more"[innerHTML]="media?.description" ></p>
                        </div>
                    </div>
                    <div class="like-block-main comment-block cursor line" >
                        <span>{{media.noOfLike}} Like</span> 
                        <span style="margin-left: 20px;">{{media.noOfComment}} Comment</span>
                    </div>
                    <!-- <div class="like-block-main">
                        <span (click)="like(media)" *ngIf="media.likeStatus == 'False'"><i class="far fa-heart"></i>Like</span> 
                        <span (click)="like(media)" *ngIf="media.likeStatus == 'True'"><i class="fa fa-heart active"></i>Unlike</span>
                        <span class="cursor" [routerLink]="['/player/media/PlayerMediaDetails', competitionId, organizerId, media._id]"><i
                                class="far fa-comment "></i> Comment</span>
                    </div> -->
                    <div class="comments-area">
                        <h3 class="comments-title">{{media?.comments?.length || 0}} Comments:</h3>
                        <ol class="comment-list">
                            <li class="comment" *ngFor="let comData of media?.comments">
                                <article class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img style="height:63px;" [src]="comData?.commentImage || 'assets/img/imageuser.png'"  class="avatar" alt="image">
                                            <b style=" margin-left: 10px;" class="fn">{{comData?.commentFirstName}} {{comData?.commentLastName}}</b>
                                            <span style=" margin-left: 10px;"  class="says">says:</span>
                                        </div>

                                        <div class="comment-metadata">
                                            <a >
                                                <span>{{comData?.createdAt | date : 'dd - MMM,yyyy hh:mm a'}}</span>
                                            </a>
                                        </div>
                                    </footer>

                                    <div class="comment-content">
                                        <p>{{comData?.text}}</p>
                                    </div>

                                </article>

                             
                            </li>

                        </ol>

                    
                    </div>
                </form>
            </div>
       </div>
    </div>
</section>




