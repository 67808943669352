import { Component, OnInit } from "@angular/core";
import { DatePipe } from "@angular/common";
import { MainService } from "src/app/providers/main-service.service";

@Component({
    selector: "app-popular-leagues",
    templateUrl: "./popular-leagues.component.html",
    styleUrls: ["./popular-leagues.component.scss"],
    providers: [DatePipe],
})
export class PopularLeaguesComponent implements OnInit {
    constructor(private service: MainService, public datePipe: DatePipe) {}
    filteredMatches: any[] = [];
    userId: String;
    competitionList: any[];
    onGoingCompetitions: any[];
    currentDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");


    ngOnInit(): void {
        //new
        this.userId = this.service.getStorage("userId");
        this.getCompetitionList();
        this.AllCompetation()
        // this.filteredMatches = this.filterMatches();
    }
 dateSplit(date){
  let m = date.split('T')
  return m[0]
 }
    getCompetitionList() {
        const data = {
            filterFields : {
                followStatus : 'APPROVED'
            },
            userId : localStorage.getItem('userId')
        }
        this.service.postApi(`player/competition/filterCompetitions`,data,0)
            .subscribe((response) => {
                if (response.responseCode == 200) {
                    this.competitionList = response.result.docs;  
                    this.onGoingCompetitions = this.competitionList.filter(
                        (competition) =>
                            this.currentDate >= competition.startDate && this.currentDate <= competition.endDate
                    );
                }
            });
    }
    allComp:any =[]
    AllCompetation() {
        this.service.getApi(`organizer/getAllCompetitionByUserId?userId=${this.userId}`, 0).subscribe(response => {
          if (response.responseCode == 200) {
            let data = response.result;
            this.allComp = this.filterMatches(response)
          }
        });
      }
      filterMatches(response) {
        return response.result.filter(match => 
          this.currentDate >= this.datePipe.transform(match.startDate, "yyyy-MM-dd")  && this.currentDate <= this.datePipe.transform(match.endDate, "yyyy-MM-dd") 
        );
      }
}
