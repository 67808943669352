import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'venue-detail',
  templateUrl: './venue-detail.component.html',
  styleUrls: ['./venue-detail.component.scss']
})
export class VenueDetailsPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
