import { Component, Input, OnInit } from '@angular/core';
import { MainService } from 'src/app/providers/main-service.service';

@Component({
  selector: 'cricket-report',
  templateUrl: './cricket-report.component.html',
  styleUrls: ['./cricket-report.component.scss']
})
export class CricketReportComponent implements OnInit {
  @Input() team1Id: any
  @Input() team2Id: any
  @Input() _gameId: any
  cricketGameDetailsData: any;
  bestBatsmanOfTeamOne: any;
  bestBowlerOfTeamOne: any;
  bestBatsmanOfTeamTwo: any;
  bestBowlerOfTeamTwo: any;
  constructor(private service: MainService) { }

  ngOnInit(): void {
    this.gameDetail(this.team1Id, this.team2Id, this._gameId)
  }


  gameDetail(team1Id, team2Id, _gameId) {

    this.service.spinnerShow();
    let apireq = {
      teams: [team1Id, team2Id],
      gameId: _gameId,
    };
    // console.log("apireq======>",apireq);
    this.service.postApi("game/cricketGameDetails", apireq, 0).subscribe(
      (success) => {
        if (success.responseCode === 200) {
          this.cricketGameDetailsData = success;
          this.bestBatsmanOfTeamOne =
            this.cricketGameDetailsData.result.teamDeatailsOne[0].bestBatsman;
          this.bestBowlerOfTeamOne =
            this.cricketGameDetailsData.result.teamDeatailsOne[0].bestBowler;
          this.bestBatsmanOfTeamTwo =
            this.cricketGameDetailsData.result.teamDeatailsTwo[0].bestBatsman;
          this.bestBowlerOfTeamTwo =
            this.cricketGameDetailsData.result.teamDeatailsTwo[0].bestBowler;
          console.log(
            "Cricket Game Details from cricket ====>",
            this.cricketGameDetailsData
          );
        }
        this.service.spinnerHide();
        this.service.toastrSucc(success.responseMessage);
      },
      (error) => {
        this.service.spinnerHide();
        this.service.toastrErr(error);
        console.log("error of cricket game details");
      }
    );
  }

}
