import { Component, Input, OnInit } from '@angular/core';
import { MainService } from 'src/app/providers/main-service.service';

@Component({
  selector: 'badminton-report',
  templateUrl: './badminton-report.component.html',
  styleUrls: ['./badminton-report.component.scss']
})
export class BadmintonReportComponent implements OnInit {

  @Input() team1Id: any
  @Input() team2Id: any
  @Input() _gameId: any
  @Input() competitionList: any
  basketballGameDetailsData: any;
  basketballGameDetailsSingle: any;
  scoreOfOne: any;
  scoreOfTwo: any;
  finalScoreOne: any;
  finalScoreTwo: any;
  sportType: any
  basketballGameDetailsDouble: any;
  constructor(private service: MainService) { }

  ngOnInit(): void {
   
    setTimeout(() => {

      this.sportType = this.competitionList.sportType
      if(this.sportType == 'double'){
        this.badmintonDoubleGameDetails(this.team1Id, this.team2Id, this._gameId)
      }
      else{
        this.gameDetail(this.team1Id, this.team2Id, this._gameId)
      }

    }, 100);
  }


  gameDetail(team1Id, team2Id, _gameId) {
    this.service.spinnerShow();
    let apireq = {
      teams: [team1Id, team2Id],
      gameId: _gameId
    };
    this.service.postApi('game/badmintonSingleGameDetails', apireq, 0).subscribe(success => {

      if (success.responseCode === 200) {
        this.basketballGameDetailsData = success;
        this.basketballGameDetailsSingle = success;
        this.scoreOfOne = this.basketballGameDetailsData.teamDeatailsOne[0].scoreData;
        this.scoreOfTwo = this.basketballGameDetailsData.teamDeatailsTwo[0].scoreData;
        this.finalScoreOne = this.basketballGameDetailsData.teamDeatailsOne[0].finalScore;
        this.finalScoreTwo = this.basketballGameDetailsData.teamDeatailsTwo[0].finalScore;
        this.service.toastrSucc(success.responseMessage);

      }
      this.service.spinnerHide();
    }, error => {
      this.service.spinnerHide();
      this.service.toastrErr(error);
    });
  }

  badmintonDoubleGameDetails(team1Id, team2Id, _gameId) {
    this.service.spinnerShow();
    let apireq = {
      teams: [team1Id, team2Id],
      gameId: _gameId
    };
    this.service.postApi('game/badmintonDoubleGameDetails', apireq, 0).subscribe(success => {

      if (success.responseCode === 200) {
        this.basketballGameDetailsData = success;
        this.basketballGameDetailsDouble = success;
        this.scoreOfOne = this.basketballGameDetailsData.teamDeatailsOne[0].scoreData;
        this.scoreOfTwo = this.basketballGameDetailsData.teamDeatailsTwo[0].scoreData;
        this.finalScoreOne = this.basketballGameDetailsData.teamDeatailsOne[0].finalScore;
        this.finalScoreTwo = this.basketballGameDetailsData.teamDeatailsTwo[0].finalScore;
        console.log("badminton Double Game Details====>", this.basketballGameDetailsData);
        this.service.toastrSucc(success.responseMessage);

      }
      this.service.spinnerHide();
    }, error => {
      this.service.spinnerHide();
      this.service.toastrErr(error);
    });
  }

}
