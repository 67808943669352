<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <h1 title="Venue">Venue</h1>
        </div>
    </div>
</section>

<section class="fixtures-area pt-100 pb-70">
    <div class="container">
        <div class="table-responsive">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <!-- <th>Sr.No.</th> -->
                        <th>Venue</th>
                        <th>Location</th>
                        <th>Description</th>
                        <th>Action</th>
          
                 
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of memberList.docs | paginate: { itemsPerPage: memberList.limit, currentPage: memberList.page,  totalItems: memberList.total }">
                        <!-- <td>{{i+1}}</td> -->
                        <td>{{item?.venueName}}</td>
                        <td>{{item?.locationName}}</td>
                        <td>{{item?.description || '--'}}</td>
                        <td>
                            <!-- <button  *ngIf="item?.playerFollowStatus?.followStatus == 'APPROVED'"  (click)="navigateToVenueView(item?._id)" style="background: #17a2b8;" class="btn btn-primary">View Venue</button> -->
                            <!-- <a  *ngIf="item?.playerFollowStatus?.followStatus == 'APPROVED'"   (click)="navigateToVenueView(item?._id)"  class="small-btn">View Venue</a> -->
                            <a  *ngIf="item?.playerFollowStatus?.followStatus == 'APPROVED'"   (click)="navigateToVenueView(item?._id)"  class="small-btn">View Venue</a>
                            <a  *ngIf="item?.playerFollowStatus == 'NOT FOLLOWED'"  class="small-btn">NOT FOLLOWED</a>

                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="pagination-block mt10 text-right" *ngIf="memberList.total > memberList.limit">
                <pagination-controls (pageChange)="onPageChange($event)"></pagination-controls>
            </div>
          </div>
    </div>
</section>



<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>