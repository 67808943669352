<!-- <div class="container">
    <div class="section-title">
        <span class="sub-title">Our Valuable Sponsers</span>
    </div>
    <div class="row">

        <div *ngFor="let item of sponsorsList" class="single-partner-item" class="col-lg-3 col-md-4 col-sm-6">
            <div class="single-products-box">
                <div class="products-image">
                    <a href="{{item?.link}}" target="_blank" class="d-block">
                        <img src="{{item?.image}}" alt="image" class="img-responsive"
                        style="max-height: 250px; margin-bottom: 33px;"></a>                    
                </div>                
            </div>
        </div>   
        <div style="width: 100%" *ngIf="sponsorsList?.length == 0">
            <h3 class="text-center">No Sponsors Found</h3>
        </div>

    </div>

</div> -->

<div class="container">
    <div class="section-title">
        <span class="sub-title">Our Valuable Sponsers</span>
    </div>
    <div class="row">
       <style>
       </style>
       <div class="slider">
           <div class="slide-track">
               <div *ngFor="let item of sponsorsList"  class="slide">
                   <img (click)="openTab(item?.link)" src="{{item?.image}}"  height="100" width="150" alt="" />
               </div>
             
           </div>
       </div>
    </div>
   </div>
   