import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/providers/main-service.service';
import { Router,ParamMap,Params,ActivatedRoute} from '@angular/router'
@Component({
  selector: 'app-service-list',
  templateUrl: './service-list.component.html',
  styleUrls: ['./service-list.component.scss']
})
export class ServiceListComponent implements OnInit {

  servicelist : any = []
  userDetail: any;
  compId: any;
  servicesList: any;
  membershipId:any;
  pagination: any;
  newArrrr: any;
  data={page: 1, limit:5, status:""}
  constructor(public service : MainService, private route: ActivatedRoute,public router: Router) {
    // this.userDetail = JSON.parse(this.service.getStorage('userDetailYala'))
    this.userDetail = this.service.isLoginVisitUser()
   }
  ngOnInit(): void {
    this.data={
      page:1,
      limit:5,
      status:""
    }
    this.route.params.subscribe(async params => {
      this.compId = params['compId']
      this.membershipId = params['compId']

    })
    if(this.service.isRolePlayer){
      this.getServicesListPlayer()
    }else{
      this.serviceList()
    }
  }
  getServicesListPlayer(){  
    this.service.postApi(`membership/getServiceListInPlayer?userId=${this.userDetail._id}&membershipId=${this.compId}`, this.data, 1).subscribe(response => {
      if(response.responseCode == 200) {
        this.servicelist = response.result.docs
        this.pagination.itemPP = response.result.limit
        this.pagination.currPage = response.result.page
        this.pagination.total = response.result.total
        this.servicesList = response['result'].docs.filter(value => {
          if (value.startDate) {
             var d = new Date(value.startDate.toString());
             var fDate = [d.getDate(),d.getMonth(),d.getFullYear()].join("/");
            return value.startDate = fDate
          } 
        }) 
        this.newArrrr = this.servicesList.professionals
       
      }
    })
  }

  serviceList(){
    let userId = localStorage.getItem('userId')
      let serviceData= {	
        "organizerId":userId,
        "loginWith":"WEBSITE",
      }
    this.service.postApi(`membership/getListOfService`, serviceData, 1).subscribe(response => {
      if(response.responseCode == 200) {
       this.servicelist = response.result.docs;

       }
        else if(response.responseCode == 402) {
        console.log("Message---> ",response.responseMessage);
      }
    })
      
  }
  openBooking(e, num){ 
    var serId = e._id;
    var orgId = e.organizerId;
    // if(e.bookingStatus){
      this.router.navigate(['/book-membership', serId,num])
    // }
    console.log('book clicked!', num)
  }

  LeaderBoardView(item){
    this.router.navigate(['/service-view'],{queryParams:{compId:this.compId,orgId:item.organizerId,serId:item._id,membershipId:this.membershipId}})
  }
  
}
