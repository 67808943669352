<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <h1 title="Media">Media</h1>
        </div>
    </div>
</section>

<section class="fixtures-area pt-100 pb-70">
    <div class="container">
<!-- new web code -->
<div class="topBanner">
    <div class="container">
    </div>
  </div>
<!-- main Start -->
<main class="main-container pt0 mt-min60">
    <div class="content-container">
        <div class="fixtures-section w-100 center-box">
            <!-- Global Box Start -->
            <div class="global-box grey-box">
                <div *ngIf='num == 1'>
                    <app-comp-header-player></app-comp-header-player>
                </div>
                <div *ngIf='num == 2'>
                    <app-memb-header-player></app-memb-header-player>
                </div>
                <div *ngIf='num == 3'>
                        <!-- <app-venue-player-header></app-venue-player-header> -->

                    <div class="fixture-inner">
                        <div class="custom-breadcrumb mt10 custom-breadcrumb1">
                            <nav class="breadcrumb">
                            <a class="breadcrumb-item" [routerLink]="['/venue-view']" [queryParams]="{ id:competitionId}"> View 
                            </a>
                            <a class="breadcrumb-item active" [routerLink]="['/media', competitionId, organizerId, 3]">Media</a>
                            <a class="breadcrumb-item" [routerLink]="['/playerVenueSponsor',competitionId]">Sponsor</a>
                            </nav>
                            <div class="" style="float: right;" *ngIf='num != 3'>
                            <div class="contact-organise">
                            <span>Contact Organizer </span>
                            <button type="button" class="btn btn-blue" (click)="messageModal()">Send Message
                            </button>
                            </div>
                            </div>
                            
                            </div>
                    </div>
                </div>
                <!-- <app-comp-header></app-comp-header> -->
                <app-media-venue ></app-media-venue>
               
            </div>
            <!-- Global Box End -->
        </div>
        <!-- Max With Box End -->
    </div>

</main>

    </div>
</section>



<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>
