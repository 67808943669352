import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/providers/main-service.service';

@Component({
  selector: 'app-checkout-page',
  templateUrl: './checkout-page.component.html',
  styleUrls: ['./checkout-page.component.scss']
})
export class CheckoutPageComponent implements OnInit {
  userId: any
  cartList: any = []
  subtotal: any = 0
  checkoutForm: FormGroup
  paymentMethod: any = "STRIPE"
  countryList: any;
  constructor(private service: MainService, private router: Router) { }

  ngOnInit(): void {
    this.getcartlist()
    this.validationForm()
    this.getCountryList()
  }
  validationForm() {
    this.checkoutForm = new FormGroup({
      "firstName": new FormControl('', [Validators.required]),
      "lastName": new FormControl('', [Validators.required]),
      "companyName": new FormControl(''),
      "address": new FormControl('', [Validators.required]),
      "town_city": new FormControl('', [Validators.required]),
      "state_Country": new FormControl('', [Validators.required]),
      "postCode": new FormControl('', [Validators.required]),
      "email": new FormControl('', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      "phone": new FormControl('', [Validators.required, Validators.pattern('^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$')]),
      "country": new FormControl('', [Validators.required]),
      "orderNote": new FormControl(''),
    })
  }
  get f() { return this.checkoutForm.controls }

  getcartlist() {
    this.userId = this.service.getStorage('userId');
    let url = `product/cartList?userId=${this.userId}`

    this.service.postApi(url, {}, 0).subscribe(response => {
      if (response.responseCode == 200) {
        this.cartList = response.result
        this.subtotal = response.subtotal
        this.service.toastrSucc(response.responseMessage)
      }
      else if (response.responseCode === 409) {
        this.cartList = []
      }
    })
  }
  totalPrice = 0
  placeOrder() {
    this.totalPrice = 0

    this.userId = this.service.getStorage('userId');
    let url = `product/placeOrder`
    let arr = []
    for (let item of this.cartList) {
      console.log(item.productId._id);

      let obj = {
        "productId": item.productId._id,
        "size": item.size,
        "quantity": item.quantity,
        "price": item.price
      }
      this.totalPrice = this.totalPrice+  item.price * item.quantity
      arr.push(obj)
    }

    const data = {
      "firstName": this.checkoutForm.value.firstName,
      "lastName": this.checkoutForm.value.lastName,
      "companyName": this.checkoutForm.value.companyName,
      "address": this.checkoutForm.value.address,
      "town_city": this.checkoutForm.value.town_city,
      "state_Country": this.checkoutForm.value.state_Country,
      "postCode": this.checkoutForm.value.postCode,
      "email": this.checkoutForm.value.email,
      "phone": this.checkoutForm.value.phone,
      "orderNote": this.checkoutForm.value.orderNote,
      "country": this.checkoutForm.value.country,
      "userId": this.userId,
      "paymentMethod": this.paymentMethod,
      "productIds": arr
    }


    this.service.postApi2(url, data, 1).subscribe(response => {
      if (response.responseCode == 200) {
        if (this.paymentMethod == 'STRIPE') {
          this.redirectingToStripe(response.result._id, arr)
        }
        else{
          this.router.navigate(['/'])
        }
        this.service.toastrSucc(response.responseMessage)
      }
      else if (response.responseCode === 409) {

      }
    })
  }

  redirectingToStripe(id, arr) {
   
    let currurl = window.location.protocol +
    "//" +
    window.location.host + "/successPayment";
  
    this.userId = this.service.getStorage('userId');
    const data = {
      "firstName": this.checkoutForm.value.firstName,
      "lastName": this.checkoutForm.value.lastName,
      "price": this.totalPrice,
      "currency": "AED",
      "email": this.checkoutForm.value.email,
      "success_url": currurl + '?paymentStatus=Success',
      "cancel_url": currurl+ '?paymentStatus=Failed',
      "organizerId": this.userId,
      "orderId": id,
      "productType": arr,
    }
    let url = `product/purchaseProduct?paymentType=STRIPE`
    this.service.postApi2(url, data, 0).subscribe(response => {
      if (response.responseCode == 200) {
        let a = document.createElement('a')
        a.href = response.result.url
        a.click()
        

        this.service.toastrSucc(response.responseMessage)
      }
      else if (response.responseCode === 409) {

      }
    })
  }
  getCountryList() {
    this.service.getApi(`users/code`, 0).subscribe((response) => {
      if (response.responseCode == 200) {
        this.countryList = response.result
      }
    });
  }
}
