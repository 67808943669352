import { Component, Input, OnInit } from '@angular/core';
import { MainService } from 'src/app/providers/main-service.service';

@Component({
  selector: 'soccer-report',
  templateUrl: './soccer-report.component.html',
  styleUrls: ['./soccer-report.component.scss']
})
export class SoccerReportComponent implements OnInit {

  @Input() team1Id: any
  @Input() team2Id: any
  @Input() _gameId: any
  @Input() competitionList: any
  soccerGameDetailsData: any;
 
  constructor(private service: MainService) { }

  ngOnInit(): void {
   
    this.gameDetail(this.team1Id, this.team2Id, this._gameId)
  
  }


  gameDetail(team1Id, team2Id, _gameId) {
    this.service.spinnerShow();
    let apireq = {
         teams: [team1Id,team2Id] ,
         gameId: _gameId
    };
    this.service.postApi('game/soccerGameDetails', apireq , 0).subscribe(success => {
      if (success.responseCode === 200) {
        this.soccerGameDetailsData = success;
        this.service.toastrSucc(success.responseMessage);
      } 
      this.service.spinnerHide();
    }, error => {
      this.service.spinnerHide();
      this.service.toastrErr(error);
    });
  }



}
