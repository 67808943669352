import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-success-payment',
  templateUrl: './success-payment.component.html',
  styleUrls: ['./success-payment.component.scss']
})
export class SuccessPaymentComponent implements OnInit {
  time : any = 7
  interval : any
  paymentStatus : any
  constructor(private route : Router,private activated : ActivatedRoute) { 
    this.activated.queryParams.subscribe((res)=>{
      this.paymentStatus = res.paymentStatus
    })
  }

  ngOnInit(): void {
    this.startTimer()
  }
  startTimer() {
    
    this.interval = setInterval(() => {
      this.time--;
      if(this.time == 0){
        clearInterval(this.interval);
        this.route.navigate(['/'])

      }    
    },1000)
  
    
  }

}
