import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
    selector: "map-location",
    templateUrl: "./map.component.html",
    styleUrls: ["./map.component.scss"],
})
export class MapComponent implements OnInit {
  constructor(private sanitizer: DomSanitizer) {}
    imageSrc : SafeResourceUrl
    apiKey = "AIzaSyD9cP3m5Rpxl7F5Zx5-TDY72kn2L8NLtO8"
    ngOnInit(): void {
      let me = this
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    // Success: Use user's current location
                    var lat = position.coords.latitude;
                    var lng = position.coords.longitude;
                    var mapSrc = `https://www.google.com/maps/embed/v1/view?key=${me.apiKey}&center=${lat},${lng}&zoom=15`;
                    me.imageSrc = me.sanitizer.bypassSecurityTrustResourceUrl(mapSrc);
                },
                function () {
                    // Error: Show Dubai as fallback location
                    var lat = 25.276987;
                    var lng = 55.296249;
                    var dubaiMapSrc = `https://www.google.com/maps/embed/v1/view?key=${me.apiKey}&center=${lat},${lng}&zoom=15`;
                    me.imageSrc = me.sanitizer.bypassSecurityTrustResourceUrl(dubaiMapSrc);;
                }
            );
        } else {
            // Geolocation not supported, show Dubai
            var lat = 25.276987;
            var lng = 55.296249;
            var dubaiMapSrc = `https://www.google.com/maps/embed/v1/view?key=${me.apiKey}&center=${lat},${lng}&zoom=15`;
            me.imageSrc = me.sanitizer.bypassSecurityTrustResourceUrl(dubaiMapSrc);;
        }
    }
}
