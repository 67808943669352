<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <h1 title="Checkout">Checkout</h1>
        </div>
    </div>
</section>

<section class="checkout-area ptb-100">
    <div class="container">
        <div class="user-actions">
            <i class='bx bx-log-in'></i>
            <span>Returning customer? <a routerLink="/profile-authentication">Click here to login</a></span>
        </div>

        <form [formGroup]="checkoutForm">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="billing-details">
                        <h3 class="title">Billing Details</h3>

                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Country <span class="required">*</span></label>

                                    <div class="select-box">
                                        <select formControlName="country">
                                            <option [ngValue]="null">Select Country</option>
                                            <option *ngFor="let item of countryList" [ngValue]="item?.countryName">{{item?.countryName}}</option>
                                         
                                        </select>
                                    </div>
                                    <div class="text-danger text-left" *ngIf="f.country.errors && f.country.touched">
                                        <small *ngIf="f.country.errors.required">*Please select country</small>

                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>First Name <span class="required">*</span></label>
                                    <input type="text" formControlName="firstName" class="form-control">
                                    <div class="text-danger text-left"
                                        *ngIf="f.firstName.errors && f.firstName.touched">
                                        <small *ngIf="f.firstName.errors.required">*First name is required</small>
                                        <small *ngIf="f.firstName.errors.pattern">*Invalid first name</small>

                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Last Name <span class="required">*</span></label>
                                    <input type="text" formControlName="lastName" class="form-control">
                                    <div class="text-danger text-left" *ngIf="f.lastName.errors && f.lastName.touched">
                                        <small *ngIf="f.lastName.errors.required">*Last name is required</small>
                                        <small *ngIf="f.lastName.errors.pattern">*Invalid last name</small>

                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Company Name</label>
                                    <input type="text" formControlName="companyName" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <label>Address <span class="required">*</span></label>
                                    <input type="text" formControlName="address" class="form-control">
                                    <div class="text-danger text-left" *ngIf="f.address.errors && f.address.touched">
                                        <small *ngIf="f.address.errors.required">*Address is required</small>
                                        <small *ngIf="f.address.errors.pattern">*Invalid address</small>

                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <label>Town / City <span class="required">*</span></label>
                                    <input type="text" formControlName="town_city" class="form-control">
                                    <div class="text-danger text-left" *ngIf="f.town_city.errors && f.town_city.touched">
                                        <small *ngIf="f.town_city.errors.required">*Town / City is required</small>
                                        <small *ngIf="f.town_city.errors.pattern">*Invalid Town / City</small>

                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>State / County <span class="required">*</span></label>
                                    <input type="text" formControlName="state_Country" class="form-control">
                                    <div class="text-danger text-left" *ngIf="f.state_Country.errors && f.state_Country.touched">
                                        <small *ngIf="f.state_Country.errors.required">*State / County is required</small>
                                        <small *ngIf="f.state_Country.errors.pattern">*Invalid State / County</small>

                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Postcode / Zip <span class="required">*</span></label>
                                    <input type="text" formControlName="postCode" class="form-control">
                                    <div class="text-danger text-left" *ngIf="f.postCode.errors && f.postCode.touched">
                                        <small *ngIf="f.postCode.errors.required">*Postcode / Zip is required</small>
                                        <small *ngIf="f.postCode.errors.pattern">*Invalid Postcode / Zip</small>

                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Email Address <span class="required">*</span></label>
                                    <input type="email" formControlName="email" class="form-control">
                                    <div class="text-danger text-left" *ngIf="f.email.errors && f.email.touched">
                                        <small *ngIf="f.email.errors.required">*Email is required</small>
                                        <small *ngIf="f.email.errors.pattern">*Invalid email</small>

                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Phone <span class="required">*</span></label>
                                    <input type="text" formControlName="phone" class="form-control">
                                    <div class="text-danger text-left" *ngIf="f.phone.errors && f.phone.touched">
                                        <small *ngIf="f.phone.errors.required">*Phone number is required</small>
                                        <small *ngIf="f.phone.errors.pattern">*Invalid phone number</small>

                                    </div>
                                </div>
                            </div>

                            <!-- <div class="col-lg-12 col-md-12">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="create-an-account">
                                    <label class="form-check-label" for="create-an-account">Create an account?</label>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="ship-different-address">
                                    <label class="form-check-label" for="ship-different-address">Ship to a different address?</label>
                                </div>
                            </div> -->

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="notes" id="notes" formControlName="orderNote" cols="30" rows="5"
                                        placeholder="Order Notes" class="form-control"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="order-details">
                        <h3 class="title">Your Order</h3>

                        <div class="order-table table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Product Name</th>
                                        <th scope="col">Total</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let item of cartList">
                                        <td class="product-name">
                                            <a
                                                routerLink="/single-products">{{item?.productId?.productType?.productType}}</a>
                                        </td>

                                        <td class="product-total">
                                            <span class="subtotal-amount">${{item?.totalPrice}}</span>
                                        </td>
                                    </tr>



                                    <tr>
                                        <td class="order-subtotal">
                                            <span>Cart Subtotal</span>
                                        </td>

                                        <td class="order-subtotal-price">
                                            <span class="order-subtotal-amount">${{subtotal}}</span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="order-shipping">
                                            <span>Shipping</span>
                                        </td>

                                        <td class="shipping-price">
                                            <span>AED 0.00</span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="total-price">
                                            <span>Order Total</span>
                                        </td>

                                        <td class="product-subtotal">
                                            <span class="subtotal-amount">${{subtotal}}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="payment-box">
                            <div class="payment-method">
                                <!-- <p>
                                    <input type="radio" (input)="paymentMethod = 'BANK_TRANSFER'" id="direct-bank-transfer" name="radio-group" checked>
                                    <label for="direct-bank-transfer">Direct Bank Transfer</label>
                                    Make your payment directly into our bank account. Please use your Order ID as the
                                    payment reference. Your order will not be shipped until the funds have cleared in
                                    our account.
                                </p> -->
                                <p>
                                    <input type="radio" (input)="paymentMethod = 'STRIPE'" id="paypal" name="radio-group" checked>
                                    <label for="paypal">Credit / Debit card</label>
                                </p>
                                <p>
                                    <input type="radio" (input)="paymentMethod = 'COD'" id="cash-on-delivery" name="radio-group">
                                    <label for="cash-on-delivery">Cash on Delivery</label>
                                </p>
                            </div>
                            <button (click)="placeOrder()" [disabled]="checkoutForm.invalid"  class="default-btn">Place Order</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>


<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>