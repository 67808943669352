import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/providers/main-service.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnInit {
  gameId : any
  competitionId : any
  gameDetail : any
  constructor(private service: MainService, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe((res)=>{
      this.gameId = res.gameId
      this.competitionId = res.competitionId
    })
   }

  ngOnInit(): void {
    this.getGameDetail()
  }

  getGameDetail(){
    const data = {
      gameId : this.gameId,
      competitionId :this.competitionId
    }
    let url = `organizer/gameDetailById`
    this.service.postApi(url,data,0).subscribe((res)=>{
      if(res.responseCode == 200){
        this.gameDetail = res.result
      }
      
    })
  }
}
