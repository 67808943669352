import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/providers/main-service.service';

@Component({
  selector: 'app-venue-list',
  templateUrl: './venue-list.component.html',
  styleUrls: ['./venue-list.component.scss']
})
export class VenueListComponent implements OnInit {

  memberList : any = []
  isLoginVistUser:any
  constructor(public service : MainService,private router:Router) { }
  ngOnInit(): void {
    this.isLoginVistUser = this.service.isLoginVisitUser()
    console.log('UUYD',this.isLoginVistUser )
    this.membersListApi(this.service.isRolePlayer)
  }
  membersListApi(isPlayer?){
let userId = localStorage.getItem('userId')
let url 
let member
if(isPlayer){
  url = `venue/getAllVenueWithPagination`
  member =  {
    playerId : this.isLoginVistUser?._id,
    "page":1,
    "limit":50000
    }
}else{
  url = `venue/getAllVenueWithPagination`
  member =  {
  playerId : userId
  }
}

   this.service.postApi(url,member,1).subscribe(response => {
    if(response.responseCode == 200) {

     this.memberList = response.result;
    } else if(response.responseCode == 402) {

    }
  });
  }
  page: any = { currPage: 1, limit: 10, search: '', limitChangeArr: 10, entryLimit: 10 };

  onPageChange(pageNo) {
    this.page.currPage = pageNo;
    this.membersListApi()
  }
navigateToVenueView(id){
  this.router.navigate(['/venue-view'],{queryParams: {id:id}})
}
}
