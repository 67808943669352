
<main class="main-container pt0 mt-min20">
  <div class="content-container">
    <div class="content-box bg-gray nopadding">
      <div class="tab-block tab-scoreboard">
        <div class="tab-scoreboard-list">
          <ul class="nav nav-tabs mt-3" id="myTab" role="tablist">
            <li class="nav-item" role="presentation"><a class="nav-link active"
             (click)="getActiveResult('result2')"
            id="result2-result-tab" data-toggle="tab" href="#result2" role="tab"
            aria-controls="result2-result" aria-selected="false">
             Statistics</a></li>
            <li class="nav-item" role="presentation"><a class="nav-link"
             (click)="getActiveResult('result3')"
            id="result3-result-tab" data-toggle="tab" href="#result3" role="tab"
            aria-controls="result3-result" aria-selected="false">
            Match Highlights </a></li>
         </ul>
        </div>
        <ng-container *ngIf="acctiveTab === 'result2'">
          <app-group-report-cricket [gameData]="gameData" [team1Id]="team1Id" [team2Id]="team2Id" [_gameId]="_gameId" [roundId]="roundId" [groupId]="groupId"     *ngIf="compititionData?.sports == 'Cricket'"></app-group-report-cricket>
          <app-group-report-basket-ball  [gameData]="gameData" [team1Id]="team1Id" [team2Id]="team2Id" [_gameId]="_gameId" [roundId]="roundId" [groupId]="groupId"  *ngIf="compititionData?.sports == 'Basketball' "></app-group-report-basket-ball>
          <app-group-report-badminton [gameData]="gameData" [team1Id]="team1Id" [team2Id]="team2Id" [_gameId]="_gameId"  [roundId]="roundId" [groupId]="groupId" *ngIf="compititionData?.sports == 'Badminton' "></app-group-report-badminton>
          <app-group-report-soccer [gameData]="gameData" [team1Id]="team1Id" [team2Id]="team2Id" [_gameId]="_gameId"  [roundId]="roundId" [groupId]="groupId"  *ngIf="compititionData?.sports == 'Soccer' "></app-group-report-soccer>
          <app-group-report-swimming  [gameData]="gameData" [team1Id]="team1Id" [team2Id]="team2Id" [_gameId]="_gameId"  [roundId]="roundId" [groupId]="groupId"  *ngIf="compititionData?.sports == 'Swimming' "></app-group-report-swimming>
          <app-group-report-table-tennis  [gameData]="gameData" [team1Id]="team1Id" [team2Id]="team2Id" [_gameId]="_gameId"  [roundId]="roundId" [groupId]="groupId"   *ngIf="compititionData?.sports == 'Table Tennis' "></app-group-report-table-tennis>    
          <app-group-report-voley-ball [gameData]="gameData" [team1Id]="team1Id" [team2Id]="team2Id" [_gameId]="_gameId" [roundId]="roundId" [groupId]="groupId" *ngIf="compititionData?.sports == 'Volleyball' "></app-group-report-voley-ball>
          <app-group-report-generic  [gameData]="gameData" [team1Id]="team1Id" [team2Id]="team2Id" [_gameId]="_gameId" [roundId]="roundId" [groupId]="groupId"  *ngIf="compititionData?.sports == 'Generic' && compititionData?.sportType != 'team' "></app-group-report-generic>
          <app-group-report-generic-team  [gameData]="gameData" [team1Id]="team1Id" [team2Id]="team2Id" [_gameId]="_gameId" [roundId]="roundId" [groupId]="groupId" *ngIf="compititionData?.sports == 'Generic' && compititionData?.sportType == 'team' "></app-group-report-generic-team>
  
      </ng-container>
      <div id="Information" *ngIf="acctiveTab === 'result3'">
          <div class="tab-inner-text-block">
            <div class="col-md-12 col-lg-12 marginzero">
              <textarea class="form-control common-textarea" style="height: 500px" readonly [(ngModel)]="observation"></textarea>
            </div>
         </div>
        <!-- <app-group-report-observation [messageFromObservation]="messageFromObservation" [_gameId]="_gameId" [roundId]="roundId" [groupId]="groupId" *ngIf=" contentType == 'Observation'"></app-group-report-observation> -->
       </div>
        
      </div>
    </div>
  </div>
</main>
