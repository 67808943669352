<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <h1 title="Venue">Venue View</h1>
        </div>
        <div class="topBanner">
            <div class="container">
              <div class="row">
                <div class="col">
                  <div class="topBanner-content d-flex align-items-center w-100">
                    <div class="w-100">
                      <h1>COMPREHENSIVE MANAGEMENT OF
                        <br> ANY SPORTS venue</h1>
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
        
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
</section>

<section class="fixtures-area pt-100 pb-70">
    <div class="container">
<!-- new web code -->
<div class="topBanner">
    <div class="container">
    </div>
  </div>
  <!-- main Start -->
  <main class="main-container pt0">
    <div class="venue-booking-container">
      <!-- content-box -->
      <div class="content-box bg-gray mt-60">
          <app-venue-player-header [venueIdGet]="venueId" [loginId]="loginId"></app-venue-player-header>
          <!-- content-box-filter End -->
          <div class="table-block mt30">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Sponsor Name</th>
                    <th>Link</th>
                    <th>Details</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="sponsorList.total == 0">
                    <td colspan="6" style="text-align: center;">No data found.</td>
                  </tr>
                  <tr *ngFor="let sponsor of sponsorList.docs | paginate: { itemsPerPage: sponsorList.limit, currentPage: sponsorList.page,  totalItems: sponsorList.total }">
                    <td style="cursor: pointer;" >
                    <!-- <td style="cursor: pointer;" (click)="venueDetailFun(venue)"> -->
                      <div class="text-left d-flex align-items-center">
                        <span class="image-box image-box_1" *ngIf="sponsor.image">
                          <img *ngIf="!sponsor.image" src="assets/images/follow-img.png">
                          <img *ngIf="sponsor.image" src="{{sponsor.image}}">
                        </span>
                        <div>{{sponsor.sponsorName}}</div>
                      </div>
                    </td>
                    <td style="cursor: pointer;">
                      <a href="" target="_blank">{{sponsor.link}}</a>
                    </td>
                    <td style="cursor: pointer;">{{sponsor.description}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="clearfix pagination-entity">
              <div class="pagination-block mt10 text-right" *ngIf="sponsorList.total > sponsorList.limit">
                <pagination-controls (pageChange)="onPageChange($event)"></pagination-controls>
              </div>
              <div class="entity-text" *ngIf="sponsorList.docs.length">
                <span>Showing {{page.limit * (page.currPage - 1) + 1 || 0}} to {{((page.limit * page.currPage) < sponsorList.total) ? (page.limit * page.currPage) : sponsorList.total || 0}} of {{sponsorList.total}} entries </span>
                    <select class="form-control select-style1 mw50" [(ngModel)]="page.entryLimit" name="entryLimit" (change)="onLimitChange()">
                      <option *ngFor="let item of page.limitChangeArr" [value]="item">{{item}}</option>
                    </select>
                    <span>entries</span>
              </div>
            </div>
          </div>
          <!-- Row End -->
        </div>
        <!-- content-box End-->
      </div>
  </main>
  <!-- main End -->

       
    </div>
</section>



<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>
  <!-- Modal Start -->
  <!-- <div class="modal fade" id="venue_rating" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <form class="rating-form">
          <div class="modal-header d-block bdr-none pb0 clearfix">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body text-center">
            <p class="head_lagend mb25 navy-blue font_uni_sans" style="color: #1b060a;">Rate Venue</p>
            <div class="rating-block">
              <div class="d-inline-block active">
                <rating [(ngModel)]="userRating" fullIcon="★" emptyIcon="☆" [max]="5" (click)='onRatingClicked()' [ngModelOptions]="{standalone: true}">
                </rating>
              </div>
            </div>
          </div>
          <div class="modal-footer d-block text-center bdr-none">
            <button type="submit" class="mb25 btn btn-navy-blue max-WT-170" [disabled]="!this.newRating" (click)="saveRatingFun()">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div> -->
  <!-- book_slot End -->