<div id="Starts" class=" tab-pane active show" *ngIf="!observation">
    <div class="row">
       <div class="col-lg-12">
          <div class="scoreboard-table-block1">
             <div class="scoreboard-table-inner">
               <div class="status-content-block">
                  <div>
                    <div>
                       <h3 style="text-align: center; margin-top: 10px;"> Competition Details </h3>
                       <table class="table table-bordered large-table">
                          <thead>
                             <tr class="table-head-bg">
                                <th>Competition</th>
                                <th>Division</th>
                                <th>Period</th>
                                <th>Date</th>
                                <th>Venue</th>
                             </tr>
                          </thead>
                          <tbody>
                             <tr >
                                <td>{{gameData?.competitionData?.competitionName}}</td>
                                <td>{{ gameData?.competitionData?.division }}</td>
                                <td>{{ gameData?.competitionData?.period}}</td>
                                <td>{{ gameData?.competitionData?.startDate | date:'mediumDate' }}</td>
                                <td>{{ gameData?.competitionData?.venue }}</td>
                             </tr>
                          </tbody>
                       </table>
                    </div>
                 </div>
                 <h3 style="text-align: center;" > Statistics </h3>
                 </div>
                

<div class="table-scrool-box" >
    <div class="table-block mt30">
         <div class="table-responsive">
           <table class="table table-bordered large-table" style="border: 1px solid #6c757d;" >
             <thead>
               <tr class="table-head-bg" >
                 <th>Player Name</th>
                 <th>Team Name</th>
                 <th  *ngFor="let data of data?.teamDeatailsOne[0]?.scoreData">{{ data?.name }}</th>
          
                 <th>Full Score</th>
               </tr>
            </thead>
     
      <tbody>
         <tr>
            <td>
            <p>{{ data?.teamDeatailsOne[0]?.playersDetails[0]?.firstName }}</p>
            <p *ngIf="sportType == 'double'">{{data?.teamDeatailsOne[0]?.playersDetailsTwo[0]?.firstName}}</p>
           </td>
           <td >{{ data?.teamDeatailsOne[0]?.teamDetails[0]?.teamName }}</td>
           <td *ngFor="let data of data?.teamDeatailsOne[0]?.scoreData">{{ data.score }}</td>

           <td>{{  finalScoreOne }}</td>
        </tr>
        <tr>
           <td>
               <p>{{ data?.teamDeatailsTwo[0]?.playersDetails[0]?.firstName }}</p>
               <p *ngIf="sportType == 'double'">{{data?.teamDeatailsTwo[0]?.playersDetailsTwo[0]?.firstName}}</p>
           </td>
           <td>{{ data?.teamDeatailsTwo[0]?.teamDetails[0]?.teamName }}</td>
           <td *ngFor="let data of data?.teamDeatailsTwo[0]?.scoreData">{{ data.score }}</td>
         
           <td>{{ finalScoreTwo  }}</td>
        </tr>
     </tbody>
  </table>
 </div>
</div>
</div>
             <div class=" text-right mt20">
                  <!-- <button class="btn btn btn-dark-blue-s" data-toggle="modal" data-target="#score" data-backdrop="static" data-keyboard="false">Edit Score</button> -->
                 <!--  <button type="button" class="btn btn-dark-blue-s max-WT-200 white-bor" data-toggle="modal" data-target="#socialSharing" data-backdrop="static" data-keyboard="false" (click)="sendUrl()">Publish Match</button> -->
            </div>
           </div>
         </div>
       </div>
     </div>
 </div>

 <!-- Observation -->
<div id="Information" *ngIf="observation">
   <div class="tab-inner-text-block">
     <div class="col-md-12 col-lg-12 marginzero">
       <textarea class="form-control common-textarea" readonly [(ngModel)]="observationMessage"></textarea>
       <!-- <div class=" text-right mt10">
         <button class="btn btn btn-dark-blue-s" (click)="updateObservation()">Update Observation</button>
         <button class="btn btn btn-dark-blue-s" [disabled]="messageFromObservation" (click)="addObservation()">Add Observation</button>
       </div> -->
     </div>
   </div>
 </div>

  
  