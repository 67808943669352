import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/providers/main-service.service';

@Component({
  selector: 'app-tournaments-competition-round',
  templateUrl: './tournaments-competition-round.component.html',
  styleUrls: ['./tournaments-competition-round.component.scss'],
  providers: [DatePipe]
})
export class TournamentsCompetitionRoundComponent implements OnInit {

  userId: String;
  competitionList: any;
  upcomingCompetitions: any= [];
  compID:any
  currentDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  constructor(private service: MainService, private datePipe: DatePipe,private activatedRoute:ActivatedRoute) { 
this.activatedRoute.queryParams.subscribe((res)=>{
  this.compID = res.compId
})
  }
  ngOnInit(): void {
    this.userId = this.service.getStorage('userId');
    this.getComptetionDetails();
    this.getGroupList()
  }
getComptetionDetails(){
  let compData = {
    userId: this.userId,
    competitionId: this.compID
  };
  let url = `organizer/competition/getACompetition`
  this.service.postApi(url,compData, 0).subscribe(response => {
    if (response.responseCode == 200) {
      this.competitionList = response.result;
     
    }
  });
}
grouplist : any = []
getGroupList(){
  let compData = {
  
    competitionId: this.compID
  };
  let url = `organizer/groupList`
  this.service.postApi(url,compData, 0).subscribe(response => {
    if (response.responseCode == 200) {
      this.grouplist = response.result
     
     
    }
  });
}

}
