 <div id="Starts" class=" tab-pane active show"  *ngIf="!observation">
    <div class="row">
       <div class="col-lg-12">
          <div class="scoreboard-table-block1">
             <div class="scoreboard-table-inner">
              <div class="status-content-block">
                <div>
                  <div>
                     <h3 style="text-align: center; margin-top: 10px;"> Competition Details </h3>
                     <table class="table table-bordered large-table">
                        <thead>
                           <tr class="table-head-bg">
                              <th>Competition</th>
                              <th>Division</th>
                              <th>Period</th>
                              <th>Date</th>
                              <th>Venue</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr >
                              <td>{{gameData?.competitionData?.competitionName}}</td>
                              <td>{{ gameData?.competitionData?.division }}</td>
                              <td>{{ gameData?.competitionData?.period}}</td>
                              <td>{{ gameData?.competitionData?.startDate | date:'mediumDate' }}</td>
                              <td>{{ gameData?.competitionData?.venue }}</td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
               <h3 style="text-align: center;" > Statistics </h3>
               </div>

                 <div class="table-scrool-box1">
                 <div class="table-block mt30">
                    <div class="table-responsive">
                        <table class="table table-bordered text-center">
                           <thead>
                             <tr class="table-head-bg">
                               <th></th>
                               <th>Total Score</th>
                               <th>Total Outs</th>
                               <th>Net Score</th>
                               <th>Leading Skins</th>
                               <th>Lagging Skins</th>
                               <th>Best Batsman</th>
                               <th>Best Bowler</th>
                             </tr>
                           </thead>
                           <tbody>
                             <tr class="vertalgn">
                               <td>{{genericTeamData?.result?.teamDeatailsOne[0]?.teamDetails[0]?.teamName}}</td>
                               <td>{{genericTeamData?.result?.teamDeatailsOne[0]?.totalScore}}</td>
                               <td>{{genericTeamData?.result?.teamDeatailsOne[0]?.totalOut}}</td>
                               <td>{{genericTeamData?.result?.teamDeatailsOne[0]?.netScore}}</td>
                               <td>{{genericTeamData?.result?.teamDeatailsOne[0]?.leadingSkins}}</td>
                               <td>{{genericTeamData?.result?.teamDeatailsOne[0]?.laggingSkins}}</td>
                               <td *ngIf="!bestBatsmanOfTeamTwo">select from Team A - player names</td>
                               <td *ngIf="bestBatsmanOfTeamTwo">{{bestBatsmanOfTeamTwo}}</td>

                               <td *ngIf="!bestBowlerOfTeamOne">select from Team A - player names</td>
                               <td *ngIf="bestBowlerOfTeamOne">{{bestBowlerOfTeamOne}}</td>
                             </tr>
                             <tr class="vertalgn">
                               <td>{{genericTeamData?.result?.teamDeatailsTwo[0].teamDetails[0].teamName}}</td>
                               <td>{{genericTeamData?.result?.teamDeatailsTwo[0]?.totalScore}}</td>
                               <td>{{genericTeamData?.result?.teamDeatailsTwo[0]?.totalOut}}</td>
                               <td>{{genericTeamData?.result?.teamDeatailsTwo[0]?.netScore}}</td>
                               <td>{{genericTeamData?.result?.teamDeatailsTwo[0]?.leadingSkins}}</td>
                               <td>{{genericTeamData?.result?.teamDeatailsTwo[0]?.laggingSkins}}</td>
                               <td *ngIf="!bestBatsmanOfTeamTwo">select from Team A - player names</td>
                               <td *ngIf="bestBatsmanOfTeamTwo">{{bestBatsmanOfTeamTwo}}</td>

                               <td *ngIf="!bestBowlerOfTeamTwo">select from Team A - player names</td>
                               <td *ngIf="bestBowlerOfTeamTwo">{{bestBowlerOfTeamTwo}}</td>
                             </tr>
                           </tbody>
                         </table>
                         <div class=" text-right mt20" >
                            <!-- <button class="btn btn btn-dark-blue-s" data-toggle="modal" data-target="#genericTeamEditScore" data-backdrop="static" data-keyboard="false" style="margin-bottom: 12px;margin-top: -18px;width: 164px;">Edit Team Score</button> -->
                         </div>


                          <table class="table table-bordered text-center">
                           <thead>
                             <tr class="table-head-bg">
                               <th>{{genericTeamData?.result?.teamDeatailsOne[0].teamDetails[0].teamName}}</th>
                               <th colspan="4"> Batting Performance</th>
                               </tr>
                             <tr class="table-head-bg">
                               <th>Skin</th>
                               <th>Player No.</th>
                               <th>Player First Name</th>
                               <th>Player Last Name</th>
                               <th>Runs</th>
                             </tr>
                           </thead>
                           <tbody>
                             <tr class="vertalgn" *ngFor="let data of genericTeamData?.result?.teamOne">
                               <td>{{data.skin}}</td>
                               <td>{{data.playerNumber}}</td>
                               <td>{{data.userDetails[0].firstName}}</td>
                               <td>{{data.userDetails[0].lastName}}</td>
                               <td>{{data.runs}}</td>
                             </tr>
                           </tbody>
                         </table>
                         <div class=" text-right mt20" >
                            <!-- <button class="btn btn btn-dark-blue-s" data-toggle="modal" data-target="#genericTeamPlayerEditScoreOne" data-backdrop="static" data-keyboard="false" style="margin-bottom: 12px;margin-top: -18px;width: 164px;">Edit Player Score</button> -->
                         </div>


                           <table class="table table-bordered text-center">
                           <thead>
                             <tr class="table-head-bg">
                               <th>{{genericTeamData?.result?.teamDeatailsTwo[0]?.teamDetails[0]?.teamName}}</th>
                               <th colspan="4"> Batting Performance</th>
                               </tr>
                             <tr class="table-head-bg" >
                               <th>Skin</th>
                               <th>Player No.</th>
                               <th>Player First Name</th>
                               <th>Player Last Name</th>
                               <th>Runs</th>
                             </tr>
                           </thead>
                           <tbody>
                             <tr class="vertalgn" *ngFor="let data of genericTeamData?.result?.teamTwo">
                               <td>{{data.skin}}</td>
                               <td>{{data.playerNumber}}</td>
                               <td>{{data.userDetails[0].firstName}}</td>
                               <td>{{data.userDetails[0].lastName}}</td>
                               <td>{{data.runs}}</td>
                             </tr>
                           </tbody>
                         </table>
                         <div class=" text-right mt20" >
                            <!-- <button class="btn btn btn-dark-blue-s" data-toggle="modal" data-target="#genericTeamPlayerEditScoreTwo" data-backdrop="static" data-keyboard="false" style="margin-bottom: 12px;margin-top: -18px;width: 164px;">Edit Player Score</button> -->
                         </div>
                    </div>
                 </div>
               </div>
           </div>
         </div>
       </div>
     </div>
 </div>

<!-- Observation -->
<div id="Information" *ngIf="observation">
  <div class="tab-inner-text-block">
    <div class="col-md-12 col-lg-12 marginzero">
      <textarea class="form-control common-textarea" readonly [(ngModel)]="observationMessage"></textarea>
      <!-- <div class=" text-right mt10">
        <button class="btn btn btn-dark-blue-s" (click)="updateObservation()">Update Observation</button>
        <button class="btn btn btn-dark-blue-s" [disabled]="messageFromObservation" (click)="addObservation()">Add Observation</button>
      </div> -->
    </div>
  </div>
</div>


