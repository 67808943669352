<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <h1 title="{{sectionName}}">  {{sectionName}}</h1>
        </div>
    </div>
</section>

<section    class="match-details-area ptb-100">
    <div class="container">
        <div  class="row">
            <div  class="col-lg-8 col-md-12">
                <div class="match-details-desc">
                    <div class="article-content">
                        <section *ngIf="!linkSection" [innerHTML]="sectionsList"></section>
                        <section *ngIf="linkSection" >
                            <h3>For more details  :<a (click)="openSite(linkSectionUrl)">Click Here</a>  </h3>
                            <!-- <iframe width="1350px" height="800px"  
                            [src]="urlSafe" title="description"></iframe> -->
                        </section>
                        <img [src]="imgUrl" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="subscribe-area pb-100">
    <app-subscribe-style-one></app-subscribe-style-one>
</section>

<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>