<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <h1 title="Esports league">Esports league</h1>
        </div>
    </div>
</section>

<section class="tournament-details-area ptb-100">
    <div class="container">
        <ng-container>
            <div class="tournament-details-desc">
                <span class="sub-title">{{competitionList?.startDate | date :'EEEE, MMMM d, y'}} /
                    {{competitionList?.startDate | date : 'h:mm a'}} / {{competitionList?.venue}}</span>
                <h3>{{competitionList?.competitionName}}</h3>
                <ng-container *ngFor="let item of competitionList?.prize">
                    
                    <ul class="tournament-meta">
                        <li>
                            <div class="icon">
                                <i class="flaticon-coin"></i>
                            </div>
                            <span>{{item?.value}} AED</span>
                          {{item?.name}}
                        </li>
                        <!-- <li>
                            <div class="icon">
                                <i class="flaticon-game"></i>
                            </div>
                            <span>1v1</span>
                            Play Mood
                        </li> -->

                        <!-- <li>
                            <div class="icon">
                                <i class="flaticon-game-1"></i>
                            </div>
                            <span>Mobile</span>
                            Platform
                        </li> -->

                        <!-- <li>
                            <div class="icon">
                                <i class="flaticon-teamwork"></i>
                            </div>
                            <span>10 Groups</span>
                            Players
                        </li> -->
                    </ul>
                    <p>{{item?.description}}</p>
                </ng-container>
            </div>
        </ng-container>

        <div class="matches-tabs">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation"><a class="nav-link active" (click)="getActiveResult('All')"
                        id="all-matches-tab" data-toggle="tab" href="#all-matches" role="tab"
                        aria-controls="all-matches" aria-selected="true">All Matches</a></li>

                <li class="nav-item" role="presentation"><a class="nav-link" (click)="getActiveResult('Upcomming')"
                        id="upcoming-matches-tab" data-toggle="tab" href="#upcoming-matches" role="tab"
                        aria-controls="upcoming-matches" aria-selected="false">Upcoming Matches</a></li>

                <li class="nav-item" role="presentation"><a class="nav-link" (click)="getActiveResult('Latest')"
                        id="latest-result-tab" data-toggle="tab" href="#latest-result" role="tab"
                        aria-controls="latest-result" aria-selected="false">Latest
                        Result</a></li>
              <li *ngIf="widgets?.media" class="nav-item" role="presentation"><a class="nav-link"
                             [routerLink]="['/mediaa']"
                            [queryParams]="{'competitionId': competId,'gameId' : gameID,compIds:this.compID}"
                             (click)="getActiveResult('Latest')"
                            id="latest-result-tab" data-toggle="tab" href="#latest-result" role="tab"
                            aria-controls="latest-result" aria-selected="false">
                            Media</a></li>
                   <li *ngIf="widgets?.sponsors" class="nav-item" role="presentation"><a class="nav-link"
                                (click)="getActiveResult('Sponsar')"
                               id="Sponsar-result-tab" data-toggle="tab" href="#Sponsar-result" role="tab"
                               aria-controls="Sponsar-result" aria-selected="false">
                               Sponsers</a></li>
            </ul>
            <ng-container *ngIf="this.acctiveTab!='Sponsar'">
                <ul *ngIf="widgets?.roundsAndGroupAndCup"  class="nav nav-tabs mt-3" id="myTab" role="tablist">

                    <li class="nav-item" *ngFor="let item of groupList" role="presentation"><a class="nav-link"
                            (click)="getGroupId(item?._id)"
                            [ngClass]="groupFlag && groupId == item?._id ? 'active' : ''">{{item?.groupName}}</a></li>
                    <li class="nav-item" *ngFor="let item of cupList" role="presentation"><a class="nav-link"
                            (click)="getActiveTab(item?._id)"
                            [ngClass]="!groupFlag && activeTabId == item?._id ? 'active' : ''">{{item?.cupName}}</a></li>
    
                </ul>
    
                <ul  class="nav nav-pills mt-3" *ngIf="acctiveTab == 'All' && groupFlag">
                    <li  *ngIf="widgets?.standingAndFixtures" class="nav-item" style="width: 6.5%;">
                        <a class="nav-link " (click)="getPointType('Fixture')"
                            [ngClass]="pointtype == 'Fixture' ? 'active' : ''">Fixture</a>
                    </li>
                    <li  *ngIf="widgets?.standingAndFixtures" class="nav-item" style="width: 6.5%;">
                        <a class="nav-link" (click)="getPointType('Standing')"
                            [ngClass]="pointtype == 'Standing' ? 'active' : ''">Standing</a>
                    </li>
                    <li *ngIf="pointtype == 'Fixture'" class="nav-item" style="width: 85%; text-align: end;">
                        <!-- <a class="nav-link" (click)="getPointType('Standing')"
                            [ngClass]="pointtype == 'Standing' ? 'active' : ''">Select Round</a> -->
                        <select *ngIf="widgets?.roundsAndGroupAndCup" class="form-control" (change)="getGameListRoundData($event.target.value)" style="max-width: 110px;
                            display: initial;">
                            <option [value]="rounds?._id" [selected]="rounds?._id == roundId"
                                *ngFor="let rounds of roundsList">Round {{rounds?.roundName}}</option>
                        </select>
                    </li>
    
                </ul>
    
                <div class="tab-content" id="myTabContent" *ngIf="groupFlag">
                    <div class="tab-pane fade show active" id="all-matches" role="tabpanel">
                        <ng-container *ngIf="pointtype == 'Fixture'">
                            <a   *ngFor="let item of roundDataList" [routerLink]="['/single-match']"
                                [queryParams]="{'competitionId': competId,'gameId' : item._id,groupId:this.groupId,roundId:isRoundId,iscompids:compID}" class="link-btn mt-2 w-100">
                                <div class="single-matches-box">
                                    <div class="row align-items-center md-10">
                                        <div class="col-lg-5 col-md-12">
                                            <div class="matches-team">
                                                <img class="img-player"
                                                    [src]="item?.teamdata1?.imageURL ||'assets/img/team1.png'" alt="image">
    
                                                <div class="content">
                                                    <h3>{{item?.teamName1}}</h3>
                                                    <!-- <ul class="watch-list">
                                                        <li><span>Watch</span></li>
                                                        <li><a href="#" target="_blank" data-toggle="tooltip"
                                                                data-placement="top" title="Watch on YouTube"><i
                                                                    class='bx bxl-youtube'></i></a></li>
                                                        <li><a href="#" target="_blank" data-toggle="tooltip"
                                                                data-placement="top" title="Watch on Twitch"><i
                                                                    class='bx bxl-twitch'></i></a></li>
                                                        <li><a href="#" target="_blank" data-toggle="tooltip"
                                                                data-placement="top" title="Watch on Vimeo"><i
                                                                    class='bx bxl-vimeo'></i></a></li>
                                                    </ul> -->
                                                </div>
                                            </div>
                                        </div>
    
                                        <div class="col-lg-2 col-md-12">
                                            <div class="matches-result">
                                                <ul>
                                                    <li>{{item?.result1 || 0}}</li>
                                                    <li>{{item?.result2 || 0}}</li>
                                                </ul>
                                                <span class="date-time">{{(item?.updateTime || roundDetail?.updatedAt) |
                                                    date : 'dd - MMM,yyyy hh:mm a'}}</span>
                                            </div>
                                        </div>
    
                                        <div class="col-lg-5 col-md-12">
                                            <div class="matches-team right-image">
                                                <img class="img-player"
                                                    [src]="item?.teamdata2?.imageURL ||'assets/img/team2.png'" alt="image">
    
                                                <div class="content">
                                                    <h3>{{item?.teamName2}}</h3>
                                                    <!-- <ul class="watch-list">
                                                        <li><a href="#" target="_blank" data-toggle="tooltip"
                                                                data-placement="top" title="Watch on YouTube"><i
                                                                    class='bx bxl-youtube'></i></a></li>
                                                        <li><a href="#" target="_blank" data-toggle="tooltip"
                                                                data-placement="top" title="Watch on Twitch"><i
                                                                    class='bx bxl-twitch'></i></a></li>
                                                        <li><a href="#" target="_blank" data-toggle="tooltip"
                                                                data-placement="top" title="Watch on Vimeo"><i
                                                                    class='bx bxl-vimeo'></i></a></li>
                                                        <li><span>Watch</span></li>
                                                    </ul> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
    
                                </div>
                            </a>
                        </ng-container>
                        <ng-container *ngIf="pointtype == 'Standing'">
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Played Match</th>
                                            <th scope="col">Won</th>
                                            <th scope="col">Loss</th>
                                            <th scope="col">Tie</th>
                                            <th scope="col">Point</th>
                                            <th scope="col">For</th>
                                            <th scope="col">Against</th>
                                            <th scope="col">Diffrence</th>
    
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of teamList">
                                            <td>{{data?.teamName}}</td>
                                            <td>{{data?.count || 0}}</td>
                                            <td>{{data?.win || 0}}</td>
                                            <td>{{data?.loss || 0}}</td>
                                            <td>{{data?.tie || 0}}</td>
                                            <td>{{data?.totalPoint || 0}}</td>
                                            <td>{{data?.forPoint || 0}}</td>
                                            <td>{{data?.againstCount || 0}}</td>
                                            <td>{{data?.difference || 0}}</td>
                                        </tr>
    
                                    </tbody>
                                </table>
                            </div>
                        </ng-container>
    
    
                    </div>
    
                    <div class="tab-pane fade" id="upcoming-matches" role="tabpanel">
                        <ng-container *ngFor="let item of upcommingResult">
                            <a   [routerLink]="['/single-match']"
                                [queryParams]="{'competitionId': item._id,'gameId' : rounds._id,groupId:this.groupId,roundId:isRoundId,iscompids:compID}"
                                *ngFor="let rounds of item.games" class="link-btn">
                                <div class="single-matches-box ">
                                    <div class="row align-items-center md-10">
                                        <div class="col-lg-5 col-md-12">
                                            <div class="matches-team">
                                                <img class="img-player"
                                                    [src]="rounds?.teamdata1?.imageURL || 'assets/img/team5.png'"
                                                    alt="image">
    
                                                <div class="content">
                                                    <h3>{{rounds?.teamName1}}</h3>
                                                    <!-- <ul class="watch-list">
                                                        <li><span>Watch</span></li>
                                                        <li><a href="#" target="_blank" data-toggle="tooltip"
                                                                data-placement="top" title="Watch on YouTube"><i
                                                                    class='bx bxl-youtube'></i></a></li>
                                                        <li><a href="#" target="_blank" data-toggle="tooltip"
                                                                data-placement="top" title="Watch on Twitch"><i
                                                                    class='bx bxl-twitch'></i></a></li>
                                                        <li><a href="#" target="_blank" data-toggle="tooltip"
                                                                data-placement="top" title="Watch on Vimeo"><i
                                                                    class='bx bxl-vimeo'></i></a></li>
                                                    </ul> -->
                                                </div>
                                            </div>
                                        </div>
    
                                        <div class="col-lg-2 col-md-12">
                                            <div class="matches-result">
                                                <ul>
                                                    <li>{{rounds?.result1 || 0}}</li>
                                                    <li>{{rounds?.result2 || 0}}</li>
                                                </ul>
                                            </div>
                                        </div>
    
                                        <div class="col-lg-5 col-md-12">
                                            <div class="matches-team right-image">
                                                <img class="img-player"
                                                    [src]="rounds?.teamdata2?.imageURL || 'assets/img/team6.png'"
                                                    alt="image">
    
                                                <div class="content">
                                                    <h3>{{rounds?.teamName2}}</h3>
                                                    <!-- <ul class="watch-list">
                                                        <li><a href="#" target="_blank" data-toggle="tooltip"
                                                                data-placement="top" title="Watch on YouTube"><i
                                                                    class='bx bxl-youtube'></i></a></li>
                                                        <li><a href="#" target="_blank" data-toggle="tooltip"
                                                                data-placement="top" title="Watch on Twitch"><i
                                                                    class='bx bxl-twitch'></i></a></li>
                                                        <li><a href="#" target="_blank" data-toggle="tooltip"
                                                                data-placement="top" title="Watch on Vimeo"><i
                                                                    class='bx bxl-vimeo'></i></a></li>
                                                        <li><span>Watch</span></li>
                                                    </ul> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </ng-container>
    
    
    
                    </div>
    
                    <div class="tab-pane fade" id="latest-result" role="tabpanel">
                        <ng-container *ngFor="let item of latestResult">
                            <div *ngFor="let rounds of item.games" [routerLink]="['/single-match']"
                                [queryParams]="{'competitionId': item._id,'gameId' : rounds._id,groupId:this.groupId,roundId:isRoundId,iscompids:compID}"
                                class="single-matches-box">
                                <div class="row align-items-center md-10">
                                    <div class="col-lg-5 col-md-12">
                                        <div class="matches-team">
                                            <img class="img-player"
                                                [src]="rounds?.teamdata1?.imageURL || 'assets/img/team1.png'" alt="image">
    
                                            <div class="content">
                                                <h3>{{rounds?.teamName1}}</h3>
                                                <!-- <ul class="watch-list">
                                                    <li><span>Watch</span></li>
                                                    <li><a href="#" target="_blank" data-toggle="tooltip"
                                                            data-placement="top" title="Watch on YouTube"><i
                                                                class='bx bxl-youtube'></i></a></li>
                                                    <li><a href="#" target="_blank" data-toggle="tooltip"
                                                            data-placement="top" title="Watch on Twitch"><i
                                                                class='bx bxl-twitch'></i></a></li>
                                                    <li><a href="#" target="_blank" data-toggle="tooltip"
                                                            data-placement="top" title="Watch on Vimeo"><i
                                                                class='bx bxl-vimeo'></i></a></li>
                                                </ul> -->
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="col-lg-2 col-md-12">
                                        <div class="matches-result">
                                            <ul>
                                                <li>{{rounds?.result1 || 0}}</li>
                                                <li>{{rounds?.result2 || 0}}</li>
                                            </ul>
                                            <span class="date-time">{{(rounds?.updateTime) | date : 'dd - MMM,yyyy hh:mm
                                                a'}}</span>
                                        </div>
                                    </div>
    
                                    <div class="col-lg-5 col-md-12">
                                        <div class="matches-team right-image">
                                            <img class="img-player"
                                                [src]="rounds?.teamdata2?.imageURL || 'assets/img/team1.png'" alt="image">
    
                                            <div class="content">
                                                <h3>{{rounds?.teamName2}}</h3>
                                                <!-- <ul class="watch-list">
                                                    <li><a href="#" target="_blank" data-toggle="tooltip"
                                                            data-placement="top" title="Watch on YouTube"><i
                                                                class='bx bxl-youtube'></i></a></li>
                                                    <li><a href="#" target="_blank" data-toggle="tooltip"
                                                            data-placement="top" title="Watch on Twitch"><i
                                                                class='bx bxl-twitch'></i></a></li>
                                                    <li><a href="#" target="_blank" data-toggle="tooltip"
                                                            data-placement="top" title="Watch on Vimeo"><i
                                                                class='bx bxl-vimeo'></i></a></li>
                                                    <li><span>Watch</span></li>
                                                </ul> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
    
    
    
                    </div>
    
                    <div class="tab-pane fade" id="cup-result" role="tabpanel">
                        <ng-container>
                            <app-standing *ngIf="!groupFlag && activeTabId" [grouporcup]="grouporcup" [groupId]="groupId"
                                [activeTabId]="activeTabId" [roundWiseData]="roundsList" [competitionList]="competitionList"></app-standing>
    
                        </ng-container>
    
    
    
                    </div>
    
                </div>
                <ng-container *ngIf="!groupFlag">
                    <app-standing *ngIf="activeTabId"  [grouporcup]="grouporcup" [activeTabId]="activeTabId"
                        [roundWiseData]="roundsList" [groupId]="groupId" [competitionList]="competitionList"></app-standing>
    
                </ng-container>
            </ng-container>

     

            <div style="padding-top: 50px;" *ngIf="this.acctiveTab=='Sponsar'" class="tab-pane fade" id="Sponsar-result" role="tabpanel" >
                <app-partner-cmp [compID]="compID" ></app-partner-cmp>
            </div>

            





        </div>
    </div>
</section>
<!-- <section class="top-team-area pb-70">
    <app-top-ranking-team-style-two></app-top-ranking-team-style-two>
</section> -->

<!-- <div class="partner-area pb-70">
    <app-partner></app-partner>
</div> -->

<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>