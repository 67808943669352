<div class="container">
    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="section-title">
                <span class="sub-title">What We Do</span>
                <h2>We offer game application development services</h2>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="single-services-box">
                <div class="icon">
                    <i class="flaticon-game"></i>
                </div>
                <h3>Bingo Game Development</h3>
                <p>Lorem ipsum dolor sit amet, ectetur adipiscing elit, sed do eiusmod por incididunt ut labore et dolore magna ectetur aliqua.</p>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="single-services-box">
                <div class="icon">
                    <i class="flaticon-card-game"></i>
                </div>
                <h3>Poker Game Development</h3>
                <p>Lorem ipsum dolor sit amet, ectetur adipiscing elit, sed do eiusmod por incididunt ut labore et dolore magna ectetur aliqua.</p>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="single-services-box">
                <div class="icon">
                    <i class="flaticon-slot-machine"></i>
                </div>
                <h3>Slot Game Development</h3>
                <p>Lorem ipsum dolor sit amet, ectetur adipiscing elit, sed do eiusmod por incididunt ut labore et dolore magna ectetur aliqua.</p>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="single-services-box">
                <div class="icon">
                    <i class="flaticon-3d"></i>
                </div>
                <h3>3D Game Development</h3>
                <p>Lorem ipsum dolor sit amet, ectetur adipiscing elit, sed do eiusmod por incididunt ut labore et dolore magna ectetur aliqua.</p>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="single-services-box">
                <div class="icon">
                    <i class="flaticon-website"></i>
                </div>
                <h3>Web Game Development</h3>
                <p>Lorem ipsum dolor sit amet, ectetur adipiscing elit, sed do eiusmod por incididunt ut labore et dolore magna ectetur aliqua.</p>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="single-services-box">
                <div class="icon">
                    <i class="flaticon-game-controller"></i>
                </div>
                <h3>AR/VR Game Development</h3>
                <p>Lorem ipsum dolor sit amet, ectetur adipiscing elit, sed do eiusmod por incididunt ut labore et dolore magna ectetur aliqua.</p>
            </div>
        </div>
    </div>
</div>