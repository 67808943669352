import { MainService } from 'src/app/providers/main-service.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-group-report-swimming',
  templateUrl: './match-report-swimming.component.html',
  styleUrls: ['./match-report-swimming.component.css']
})
export class GroupReportSwimmingComponent implements OnInit {

  @Output() MessageEvent = new EventEmitter<string>()
  @Input() gameData: any;

  @Input() team1Id: any
  @Input() team2Id: any
  @Input() _gameId: any

  @Input() groupId: any;
  @Input() roundId: any;
  paramData: any;
  swimmingGameDetailsData: any;
  teamOneLane: any;
  teamOnePlace: any;
  teamOneTime: any;
  teamTwoLane: any;
  teamTwoPlace: any;
  teamTwoTime: any;
  currentUrl: string;

  constructor(
    public service: MainService,
    public activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.getParamData();
    this.service.isSingalMatchUpdateSubject.subscribe(isUpdate=>{
      this.swimmingEditGame();
    })
    
    setTimeout(() => {
      console.log('gameData swimming ===>>>', this.gameData);
      this.service.spinnerHide();
    }, 500);
  }

  //Send current Url
  send() {
    this.currentUrl = window.location.href;
  }

  // Swimming Edit  game details 
  swimmingEditGame() {
    this.service.spinnerShow();
    let apireq = {
      teams: [this.team1Id, this.team2Id],
      gameId: this._gameId,
      type: 1,
      groupId: this.groupId,
      roundId: this.roundId,
    };
    this.service.postApi('game/swimmingEditGame_group', apireq, 0).subscribe(success => {

      if (success.responseCode === 200) {
        console.log("success of Swimming  Edit ======>", success);
        this.swimmingGameDetails()
        // this.service.toastrSucc(success.responseMessage);
      }
      this.service.spinnerHide();
    }, error => {
      this.service.spinnerHide();
      this.service.toastrErr(error);
    });
  }


  //Swimming Game Details 
  swimmingGameDetails() {
    this.service.spinnerShow();
    let apireq = {
      teams: [this.team1Id, this.team2Id],
      gameId: this._gameId,
      groupId: this.groupId,
      roundId: this.roundId,
    };
    this.service.postApi('game/swimmingGameDetails_group', apireq, 0).subscribe(success => {

      if (success.responseCode === 200) {
        this.swimmingGameDetailsData = success;
        this.teamOneLane = this.swimmingGameDetailsData.teamDeatailsOne[0].scoreData[0].lane,
          this.teamOnePlace = this.swimmingGameDetailsData.teamDeatailsOne[0].scoreData[0].place,
          this.teamOneTime = this.swimmingGameDetailsData.teamDeatailsOne[0].scoreData[0].time,

          this.teamTwoLane = this.swimmingGameDetailsData.teamDeatailsTwo[0].scoreData[0].lane,
          this.teamTwoPlace = this.swimmingGameDetailsData.teamDeatailsTwo[0].scoreData[0].place,
          this.teamTwoTime = this.swimmingGameDetailsData.teamDeatailsTwo[0].scoreData[0].time,
          console.log("success of Swimming Details ======>", this.swimmingGameDetailsData);
        this.service.toastrSucc(success.responseMessage);
      }
      this.service.spinnerHide();
    }, error => {
      this.service.spinnerHide();
      this.service.toastrErr(error);
    });
  }

  //On Cancel the edit score
  onCancel() {
    this.swimmingEditGame();
  }

  // update score
  updatedTeamScore() {
    this.service.spinnerShow();
    let apireq = {
      "data1": {
        "docId": this.swimmingGameDetailsData.teamDeatailsOne[0]._id,
        "teamId": this.swimmingGameDetailsData.teamDeatailsOne[0].teamId,
        "scoreData": [
          {
            "_id": this.swimmingGameDetailsData.teamDeatailsOne[0].scoreData[0]._id,
            "lane": this.teamOneLane,
            "time": "",
            "place": this.teamOnePlace
          }
        ]
      },
      "data2": {

        "docId": this.swimmingGameDetailsData.teamDeatailsTwo[0]._id,
        "teamId": this.swimmingGameDetailsData.teamDeatailsTwo[0].teamId,
        "scoreData": [
          {
            "_id": this.swimmingGameDetailsData.teamDeatailsTwo[0].scoreData[0]._id,
            "lane": this.teamTwoLane,
            "time": "",
            "place": this.teamTwoPlace
          }

        ]
      }
    }

    this.service.postApi('game/updateSwimmingGameTeamWise_group', apireq, 1).subscribe(success => {
      if (success.responseCode == 200) {
        this.service.spinnerHide();
        this.swimmingEditGame();
      }
    }, error => {
      this.service.spinnerHide();
      this.service.toastrErr(error);
    })
  }



  getParamData() {
    this.activatedRoute.params.subscribe(param => {


    });
  }

}
