import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/providers/main-service.service';

@Component({
  selector: 'app-tournaments-groups',
  templateUrl: './tournaments-groups.component.html',
  styleUrls: ['./tournaments-groups.component.scss'],
  providers: [DatePipe]
})
export class TournamentsGroupsComponent implements OnInit {

  userId: String;
  competitionList: any;
  upcomingCompetitions: any = [];
  compID: any
  acctiveTab = 'All'
  currentDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  cupList : any = []
  grouporcup: any='cup'
  isRoundId: any;
  widgets:any
  constructor(private service: MainService, private datePipe: DatePipe, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe((res) => {
      this.compID = res.id
      this.widgetsData()
    })

  }
  widgetsData(){
   this.widgets =  JSON.parse(localStorage.getItem("widgets"))
   console.log(this.widgets )
  }
  gameID:any;
  ngOnInit(): void {
    this.userId = this.service.getStorage('userId');
    this.getComptetionDetails();
    this.getGroupList()
    this.getcupList()
  }
  getActiveResult(e){
    this.acctiveTab = e
  }
  getComptetionDetails() {
    let compData = {
      userId: this.userId,
      competitionId: this.compID
    };
    let url = `organizer/competition/getACompetition`
    this.service.postApi(url, compData, 0).subscribe(response => {
      if (response.responseCode == 200) {
        this.competitionList = response.result;
      }
    });
  }
  upcommingResult : any = []
  getUpcommingMatches() {
    let compData = {
      competitionId: this.compID,
      groupId : this.groupId
    };
    let url = `organizer/latestRoundDetails`
    this.service.postApi(url, compData, 0).subscribe(response => {
      if (response.responseCode == 200) {
        console.log(response.result)
        this.upcommingResult = response.result;
      }
    });
  }
  latestResult : any = []
  getLatestResult() {
  this.latestResult = []
    let compData = {
      competitionId: this.compID,
      groupId : this.groupId
    };
    let url = `organizer/latestResultDetails`
    this.service.postApi(url, compData, 0).subscribe(response => {
      if (response.responseCode == 200) {
     
        this.latestResult = response.result;
      }
    });
  }
  groupId: any
  groupList = []

  getGroupList() {
    let url = `organizer/groupList`
    let compData = {
      competitionId: this.compID
    };
    this.service.postApi(url, compData, 0).subscribe(response => {
      if (response.responseCode == 200 && response.result.length>0) {
        this.groupList = response.result;
        this.groupId = response.result[0]._id
      
        if (this.groupList.length > 0) {
          this.grouporcup = 'cup';
        } else {
          this.grouporcup = 'cup';
          // this.cupTeamListApi();
        }
        this.getTeamList()
        this.getGameListRoundWise()
        this.getUpcommingMatches()
        this.getLatestResult()
      }
    });
  }
  getcupList() {
    let url = `organizer/cupList`
    let compData = {
      competitionId: this.compID,
      organizerId : this.userId
    };
    this.service.postApi(url, compData, 0).subscribe(response => {
      if (response.responseCode == 200) {
       this.cupList = response.result
      }
    });
  }
  groupFlag :boolean = true
  getGroupId(_id) {
    // this.activeTabId = ''
    this.groupFlag = true
    this.groupId = _id

  }
  activeTabId : any
  getActiveTab(_id){
    this.groupFlag = true
    setTimeout(() => {
      this.groupFlag = false
    }, 100);
   
    
    this.activeTabId = _id
  
  }
  pointtype = "Fixture"
  teamList: any = []
  getTeamList() {
    let url = `organizer/teamListInAGroup`
    const data = {
      "groupId": this.groupId,

    }
    this.service.postApi(url, data, 0).subscribe(response => {
      if (response.responseCode == 200) {
        this.teamList = response.allData;

      }
    });
  }

  getPointType(e) {
    this.pointtype = e
  }
  roundsList = []
  roundId = ''
  getGameListRoundWise() {
    let url = `organizer/getGameListRoundWise`
    const data = {
      "groupId": this.groupId,
      
    }
    this.service.postApi(url, data, 0).subscribe(response => {
      if (response.responseCode == 200) {

        this.roundsList = response.results;
        this.roundId = response.results[0]._id
        this.isRoundId =response.results[0]._id
        this.getGameListRoundData()
      }
    });
  }
  roundDataList : any = []
  roundDetail : any
  competId : any
  
  getGameListRoundData(id?) {
    let url = `organizer/roundDetails`
  
    const data = {
      "roundId": id ||this.roundId,
    }
    this.roundId =id
    this.service.postApi(url, data, 0).subscribe(response => {
      if (response.responseCode == 200) {
        console.log(response)
       this.roundDataList = response.roundData.games
       this.gameID =this.roundDataList[0]._id
       this.roundDetail = response.roundData
       this.competId = response.roundData._id
      }
    });
  }

}
