import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/providers/main-service.service';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {

  constructor(private service : MainService) { }

  userDetails: any = {};
  fbEnabled: boolean;
  fbUrl: String;
  igEnabled: boolean;
  igUrl: String;
  googleAnalyticsEnabled: boolean;
  googleAnalyticsUrl: String;
  twitterEnable: boolean;
  twitterUrl: String;
  sociIT:any=[];

  ngOnInit(): void {
    this.userDetails = JSON.parse(this.service.getStorage('userDetailYala'));
    this.getSocialMediaLinks();    
  }

  getSocialMediaLinks(){
    this.service.getApi(`configuration_domain/getSocialWork?userId=${this.userDetails._id}`,0).subscribe(response => {
      console.log('socail links :: ',response);
      this.sociIT=response.getData;
      for(let i=0;i<this.sociIT.length;i++){
        switch(this.sociIT[i].socialName){
          case "FACEBOOK":
            this.fbEnabled = this.sociIT[i].status;
            this.fbUrl = this.sociIT[i].link;
            break;
          case "INSTAGRAM":
            this.igEnabled = this.sociIT[i].status;
            this.igUrl = this.sociIT[i].link;
            break;
          case "GOOGLEANALYTICS":
            this.googleAnalyticsEnabled = this.sociIT[i].status;
            this.googleAnalyticsUrl = this.sociIT[i].link;
            break;    
          case "TWITTER":
            this.twitterEnable = this.sociIT[i].status;
            this.twitterUrl = this.sociIT[i].link;
            break;  
        }
      }
      console.log('igEnabled',this.igUrl);
    });
  }

}
