import { Component, OnInit } from "@angular/core";
import { MainService } from "src/app/providers/main-service.service";

@Component({
    selector: "app-footer-style-one",
    templateUrl: "./footer-style-one.component.html",
    styleUrls: ["./footer-style-one.component.scss"],
})
export class FooterStyleOneComponent implements OnInit {
    userDetails: any = {};
    orgLogo: any;
    footerMenuList: any[];
    userId2: string;
    constructor(private service: MainService) {}
    ngOnInit(): void {
        this.service.userDetail.subscribe((res) => {
            this.userDetails = res;
            this.orgLogo = this.userDetails.organiserLogo;
        });
        this.getFooterMenus();
    }
    getYear() {
        const d = new Date();
        return d.getFullYear();
    }
    getFooterMenus() {
        this.userId2 = localStorage.getItem("userId")
            ? localStorage.getItem("userId")
            : null;
        if (this.userId2) {
            this.service
                .getApi(
                    `configuration_domain/getFooterMenu?userId=${this.userId2}`,
                    0
                )
                .subscribe((response) => {
                    if (response.responseCode == 200) {
                        this.footerMenuList = response.result.length
                            ? response.result[0].footerMenu
                            : [];
                        this.footerMenuList = this.footerMenuList
                            .slice()
                            .reverse();
                        //console.log('this.secondaryMenuList : ',this.secondaryMenuList);
                    }
                });
        }
    }
}
