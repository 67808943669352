import { Component, Input, OnInit } from '@angular/core';
import { MainService } from 'src/app/providers/main-service.service';

@Component({
  selector: 'volleyball-report',
  templateUrl: './volleyball-report.component.html',
  styleUrls: ['./volleyball-report.component.scss']
})
export class VolleyballReportComponent implements OnInit {

  @Input() team1Id: any
  @Input() team2Id: any
  @Input() _gameId: any
  constructor(private service: MainService) { }

  ngOnInit(): void {
    this.gameDetail(this.team1Id, this.team2Id, this._gameId)
  }

  voleyBallGameDetailsData: any
  mvpOfTeamOne: any
  mvpOfTeamTwo: any
  finalScoreOne: any
  finalScoreTwo: any
  teamOneScore: any
  teamTwoScore: any
  gameDetail(team1Id, team2Id, _gameId) {

    this.service.spinnerShow();
    let apireq = {
      teams: [team1Id, team2Id],
      gameId: _gameId
    };
    // console.log("apireq======>",apireq);
    this.service.postApi('game/voleyballGameDetails', apireq, 0).subscribe(success => {

      if (success.responseCode === 200) {
        this.voleyBallGameDetailsData = success;
        this.teamOneScore = this.voleyBallGameDetailsData.result.teamDeatailsOne[0].scoreData;
        this.finalScoreOne = this.voleyBallGameDetailsData.result.teamDeatailsOne[0].finalScore;
        this.teamTwoScore = this.voleyBallGameDetailsData.result.teamDeatailsTwo[0].scoreData;
        this.finalScoreTwo = this.voleyBallGameDetailsData.result.teamDeatailsTwo[0].finalScore;
        this.mvpOfTeamOne = this.voleyBallGameDetailsData.result.teamDeatailsOne[0].mvp;
        this.mvpOfTeamTwo = this.voleyBallGameDetailsData.result.teamDeatailsTwo[0].mvp;
        console.log("Volley Ball Game Details====>", this.voleyBallGameDetailsData);
        this.service.toastrSucc(success.responseMessage);

      } else {
        // this.service.toastrErr(success.responseMessage);
        console.log("error======>");
      }
      this.service.spinnerHide();
    }, error => {
      this.service.spinnerHide();
      console.log("error of badminton game details");
    });
  }
}