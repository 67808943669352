<div id="Starts" class=" tab-pane active show" >
    <div class="row">
       <div class="col-lg-12">
          <div class="scoreboard-table-block1">
             <div class="scoreboard-table-inner">
               <div class="status-content-block">
                  <div>
                    <div>
                       <h3 style="text-align: center; margin-top: 10px;"> Competition Details </h3>
                       <table class="table table-bordered large-table">
                          <thead>
                             <tr class="table-head-bg">
                                <th>Competition</th>
                                <th>Division</th>
                                <th>Period</th>
                                <th>Date</th>
                                <th>Venue</th>
                             </tr>
                          </thead>
                          <tbody>
                             <tr >
                                <td>{{gameData?.competitionData?.competitionName}}</td>
                                <td>{{ gameData?.competitionData?.division }}</td>
                                <td>{{ gameData?.competitionData?.period}}</td>
                                <td>{{ gameData?.competitionData?.startDate | date:'mediumDate' }}</td>
                                <td>{{ gameData?.competitionData?.venue }}</td>
                             </tr>
                          </tbody>
                       </table>
                    </div>
                 </div>
                 <h3 style="text-align: center;" > Statistics </h3>
                 </div>
                 <div class="table-scrool-box">
                 <div class="table-block mt30">
                     <div class="table-responsive table-responsive-edit">
                        <table class="table table-bordered large-table extra-large-table">
                           <thead>
                              <tr class="table-head-bg">
                                 <th  class="empty-tb"></th>
                                 <th  colspan="3"  class="custom-top-heading">Q1</th>
                                 <th  colspan="3"  class="custom-top-heading">Q2</th>
                                 <th  colspan="3"  class="custom-top-heading">Q3</th>
                                 <th  colspan="3"  class="custom-top-heading">Q4</th>
                                 <th  colspan="3"  class="custom-top-heading">Extra Period</th>
                                 <th  colspan="3"  class="empty-tb"></th>

                              </tr>
                                 <tr class="table-head-bg">
                                 <th class="custom-top-heading"></th>
                                 <th>Q1 Score</th>
                                 <th>Team Fouls</th>
                                 <th>Time Outs</th>
                                 <th>Q2 Score</th>
                                 <th>Team Fouls</th>
                                 <th>Time Outs</th>
                                 <th>Q3 Score</th>
                                 <th>Team Fouls</th>
                                 <th>Time Outs</th>
                                 <th>Q4 Score</th>
                                 <th>Team Fouls</th>
                                 <th>Time Outs</th>
                                 <th>Q5 Score</th>
                                 <th>Team Fouls</th>
                                 <th>Time Outs</th>
                                    <th>Final Score</th>
                                    <th>MVP</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr *ngFor= "let data of basketballGameDetailsData?.result?.teamDeatailsOne ">
                                 <td>{{ data?.teamDetails[0]?.teamName}}</td>
                                 <td *ngFor = " let item of data?.scoreData" colspan="3">
                                    <span class="span">
                                       {{item?.score}}
                                    </span>
                                    <span class="spanMiddle">
                                       {{item?.fouls}}
                                    </span>
                                    <span >
                                       {{item?.timeOut}}
                                    </span>
                                    </td>
                                 <td>{{ finalScoreOne }}</td>
                                 <td *ngIf="!playerOne">select from Team A - player names</td>
                                 <td *ngIf="playerOne">{{ playerOne }}</td>
                              </tr>
                              <tr  *ngFor= "let data of basketballGameDetailsData?.result?.teamDeatailsTwo;let i=index">
                                 <td>{{ data?.teamDetails[0]?.teamName }}</td>
                                 <td *ngFor = " let item of data?.scoreData" colspan="3">
                                    <span class="span" >{{item?.score}}</span>
                                    <span class="spanMiddle">{{item?.fouls}}</span>
                                    <span>{{item?.timeOut}}</span>
                                 </td>
                                 <td>{{ finalScoreTwo }}</td>
                                 <td *ngIf="!playerTwo">select from Team B - player names</td>
                                 <td *ngIf="playerTwo">{{ playerTwo }}</td>
                              </tr>
                           </tbody>
                        </table>
                        </div>
               <div class=" text-right mt20">
                  <!-- <button class="btn btn btn-dark-blue-s" data-toggle="modal" data-target="#BasketBallScore" data-backdrop="static" data-keyboard="false" style="margin-top: -28px; width:199px;">Edit Team Score</button> -->
               </div>


   <table class="table table-bordered large-table">
     <thead>
        <tr class="table-head-bg">
           <th colspan="5" class="custom-top-heading text-align-left">{{ basketballGameDetailsData?.result?.teamDeatailsOne[0]?.teamDetails[0]?.teamName }}</th>
        </tr>
        <tr class="table-head-bg">
           <th>Player No.</th>
           <th>Player First Name</th>
           <th>Player Last Name</th>
           <th>Baskets</th>
           <th>Fouls</th>
        </tr>
     </thead>
     <tbody>
        <tr *ngFor="let data of basketballGameDetailsData?.result?.teamOne; let i = index">
           <td>{{data?.playerNumber}}</td>
           <td>{{data?.userDetails[0]?.firstName}}</td>
           <td>{{data?.userDetails[0]?.lastName}}</td>
           <td>{{data?.baskets}}</td>
           <td>{{data?.fouls}}</td>
        </tr>
         <tr *ngIf="!basketballGameDetailsData?.result?.teamOne?.length">
           <td colspan="9">No Record Found</td>
         </tr>
     </tbody>
  </table>
  <div class=" text-right mt20">
      <!-- <button class="btn btn btn-dark-blue-s" data-toggle="modal" data-target="#BasketBallPlayerScoreOne" data-backdrop="static" data-keyboard="false" style="margin-top: -28px; width:199px;">Edit Player's Score</button> -->
  </div>

   <table class="table table-bordered large-table">
     <thead>
        <tr class="table-head-bg">
           <th colspan="5" class="custom-top-heading text-align-left">{{ basketballGameDetailsData?.result?.teamDeatailsTwo[0]?.teamDetails[0]?.teamName }}</th>
        </tr>
        <tr class="table-head-bg">
           <th>Player No.</th>
           <th>Player First Name</th>
           <th>Player Last Name</th>
           <th>Baskets</th>
           <th>Fouls</th>
        </tr>
     </thead>
     <tbody>
        <tr *ngFor="let data of basketballGameDetailsData?.result?.teamTwo; let i = index">
           <td>{{data?.playerNumber}}</td>
           <td>{{data?.userDetails[0]?.firstName}}</td>
           <td>{{data?.userDetails[0]?.lastName}}</td>
           <td>{{data?.baskets}}</td>
           <td>{{data?.fouls}}</td>
        </tr>
        <tr *ngIf="!basketballGameDetailsData?.result?.teamTwo?.length">
           <td colspan="9">No Record Found</td>
        </tr>
      
     </tbody>
  </table>
  <div class=" text-right mt20">
      <!-- <button class="btn btn btn-dark-blue-s" data-toggle="modal" data-target="#BasketBallPlayerScoreTwo" data-backdrop="static" data-keyboard="false" style="margin-top: -28px; width:199px;">Edit Player's Score</button> -->
  </div>
</div>
      <div class="button-box text-right mt20">   
      <!-- <button type="button" class="btn btn-dark-blue-s max-WT-200 white-bor" data-toggle="modal" data-target="#socialSharing" data-backdrop="static" data-keyboard="false" (click)="sendUrl()">Publish Match</button> -->
      </div>
</div>
       </div>
     </div>
 </div>
</div>
</div>


<!-- Observation -->
<div id="Information" *ngIf="observation">
      <div class="tab-inner-text-block">
        <div class="col-md-12 col-lg-12 marginzero">
          <textarea class="form-control common-textarea" readonly [(ngModel)]="observationMessage"></textarea>
          <!-- <div class=" text-right mt10">
            <button class="btn btn btn-dark-blue-s" (click)="updateObservation()">Update Observation</button>
            <button class="btn btn btn-dark-blue-s" [disabled]="messageFromObservation" (click)="addObservation()">Add Observation</button>
          </div> -->
        </div>
      </div>
</div>

