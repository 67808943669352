<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <div class="single-matches-box">
                <div class="row align-items-center">
                    <div class="col-lg-5 col-md-12">
                        <div class="matches-team">
                            <img [src]="gameDetail?.competitionGameDetails?.teamdata1?.imageURL || 'assets/img/team2.png'"  class="wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1500ms" alt="image">

                            <div class="content">
                                <a ><h3>{{gameDetail?.competitionGameDetails?.teamName1}}</h3></a>
                              
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-12">
                        <div class="matches-result">
                            <ul>
                                <li>{{gameDetail?.competitionGameDetails?.result1 || 0}}</li>
                                <li>{{gameDetail?.competitionGameDetails?.result2 || 0}}</li>
                            </ul>
                            <span class="date-time">{{gameDetail?.competitionGameDetails?.updateTime | date : 'dd - MMM,yyyy hh:mm a'}}</span>
                        </div>
                    </div>

                    <div class="col-lg-5 col-md-12">
                        <div class="matches-team right-image">
                            <img [src]="gameDetail?.competitionGameDetails?.teamdata2?.imageURL || 'assets/img/team2.png'" class="wow fadeInRight" data-wow-delay="00ms" data-wow-duration="1500ms" alt="image">

                            <div class="content">
                                <a ><h3>{{gameDetail?.competitionGameDetails?.teamName2}}</h3></a>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="match-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div  class="col-lg-8 col-md-12">
                    <ng-container >
                        <app-group-match-report-player  [team1Id]="team1Id" [team2Id]="team2Id" [_gameId]="_gameId"
                        [iscompId]="compId" [groupId]="groupId" [isroundId]="roundId">
                        </app-group-match-report-player>
                    </ng-container>
                    
                </div>
       
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <section class="widget widget_match_list">
                        <div *ngFor="let item of gameDetail?.allGamaes" class="single-match-list">


                            <!-- [routerLink]="['/single-match']"
                            [queryParams]="{'competitionId': competitionId,'gameId' :  item._id ,groupId:this.groupId,roundId:roundId,iscompids:compId}"
                            -->
                            <div (click)="updatedNewMatchScore(item)" >
                            <img [src]="item?.teamdata1?.imageURL || 'assets/img/team1.png'" class="team-1" alt="image">
                            <span class="date">{{item?.updateTime | date : 'dd - MMM,yyyy hh:mm a'}}</span>
                            <ul>
                                <li>{{item?.result1 || 0}}</li>
                                <li>{{item?.result2 || 0}}</li>
                            </ul>
                            <img [src]="item?.teamdata2?.imageURL || 'assets/img/team1.png'" class="team-2" alt="image">
                            </div>
                        </div>
                    </section>
                          
                </aside>
            </div>
        </div>
    </div>
</section>

<section class="subscribe-area pb-100">
    <app-subscribe-style-one></app-subscribe-style-one>
</section>

<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>