<div class="container">
    <div class="section-title">
        <span class="sub-title">Games</span>
        <h2>Upcoming Tournaments</h2>
    </div>
    <div *ngIf="upcomingCompetitions?.length>0">
    <div class="single-tournaments-box" *ngFor="let item of upcomingCompetitions">
        <div class="tournaments-image">
            <div class="image bg1">
                <img src="{{item.imageURL}}" alt="image">
            </div>
        </div>

        <div class="tournaments-content">
            <div class="content">
                <h3><a >{{item.competitionName}}</a></h3>
                <div class="date">
                    {{item.startDate | date:'dd'}}
                    <sup>{{item?.startDate | date:'LLLL'}}</sup>
                    <sub>{{item?.startDate | date:'yyyy'}}</sub>
                </div>
                <ul class="meta">
                    <li>
                        <span>Where</span>
                        {{item?.venue}}
                    </li>
                    <li>
                        <span>When</span>
                        <span>{{item?.startDate | date:'h:mm a'}}</span>
                    </li>
                </ul>
                <ul class="info">
                    <li><i class="flaticon-coin"></i>{{item && item?.prize[0] && item?.prize[0]?.value || 0}}</li>
                    <!-- <li><i class="flaticon-game"></i>1v1</li> -->
                    <li><i class="flaticon-game-1"></i>{{item?.sports}}</li>
                    <!-- <li><i class="flaticon-teamwork"></i>10 Teams</li> -->
                </ul>
                <a (click)="navigateTodetailPage(item?._id)" class="default-btn">More Info</a>
            </div>
        </div>
    </div>    
</div>
<div *ngIf="upcomingCompetitions?.length==0" style="text-align: center;">
    <h3>No Upcoming Tournaments Found</h3>
</div>
</div>