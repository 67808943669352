<div class="status-block-s" >
   <div class="status-content-block">
      <div>
        <div>
           <h3 style="text-align: center; margin-top: 10px;"> Competition Details </h3>
           <table class="table table-bordered large-table">
              <thead>
                 <tr class="table-head-bg">
                    <th>Competition</th>
                    <th>Division</th>
                    <th>Period</th>
                    <th>Date</th>
                    <th>Venue</th>
                 </tr>
              </thead>
              <tbody>
                 <tr >
                    <td>{{gameData?.competitionData?.competitionName}}</td>
                    <td>{{ gameData?.competitionData?.division }}</td>
                    <td>{{ gameData?.competitionData?.period}}</td>
                    <td>{{ gameData?.competitionData?.startDate | date:'mediumDate' }}</td>
                    <td>{{ gameData?.competitionData?.venue }}</td>
                 </tr>
              </tbody>
           </table>
        </div>
     </div>
     <h3 style="text-align: center;" > Statistics </h3>
     </div>
 </div>
<div id="Information" *ngIf="observation">
   <div class="tab-inner-text-block">
     <div class="col-md-12 col-lg-12 marginzero">
       <textarea class="form-control common-textarea" readonly [(ngModel)]="observationMessage"></textarea>
       
     </div>
   </div>
</div>
 <div class="table-block mt10 white-bg">
    <div class="table-responsive">
       <table class="table table-bordered large-table">
          <thead>
             <tr class="table-head-bg">
                <th>Player Name</th>
                <th>Team Name</th>
                <th>Set 1 score</th>
                <th>Set 2 score</th>
                 <th>Set 3 score</th>
                 <th>Set 4 score</th>
                 <th>Set 5 score</th>
                 <th>Set 6 score</th>
                 <th>Set 7 score</th>
                 <th>Full Score</th>
             </tr>
          </thead>
          <tbody>
             <tr>
                <td>
                  <p>{{basketballGameDetailsData?.teamDeatailsOne[0]?.playersDetails[0]?.firstName}}</p>
                  <p *ngIf="sportType == 'double'">{{basketballGameDetailsData?.teamDeatailsOne[0]?.playersDetailsTwo[0]?.firstName}}</p>
                 </td>
                <td>{{basketballGameDetailsData?.teamDeatailsOne[0]?.teamDetails[0]?.teamName}}</td>
                <td *ngFor="let data of basketballGameDetailsData?.teamDeatailsOne[0]?.scoreData">{{ data.score }}</td>
                <td>{{ this.finalScoreOne }}</td>
             </tr>
             <tr>
                <td>
                  <p>{{basketballGameDetailsData?.teamDeatailsTwo[0]?.playersDetails[0]?.firstName}}</p>
                  <p *ngIf="sportType == 'double'">{{basketballGameDetailsData?.teamDeatailsTwo[0]?.playersDetailsTwo[0]?.firstName}}</p>
                </td>
                <td>{{basketballGameDetailsData?.teamDeatailsTwo[0]?.teamDetails[0]?.teamName}}</td>
                <td *ngFor="let item of basketballGameDetailsData?.teamDeatailsTwo[0]?.scoreData">{{ item.score }}</td>
                <td>{{ this.finalScoreTwo }}</td>
             </tr>
          </tbody>
       </table>
    </div>
 </div>






