import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import {
    Location,
    LocationStrategy,
    PathLocationStrategy,
} from "@angular/common";
import { MainService } from "src/app/providers/main-service.service";
declare let $: any;
@Component({
    selector: "app-navbar-style-one",
    templateUrl: "./navbar-style-one.component.html",
    styleUrls: ["./navbar-style-one.component.scss"],
    providers: [
        Location,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
    ],
})
export class NavbarStyleOneComponent implements OnInit {
    location: any;
    containerClass: any;
    userDetails: any = {};
    orgLogo: any;
    widgets: any;
    sectionsList: any[];

    constructor(
        private router: Router,
        public service: MainService,
        location: Location
    ) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.location = this.router.url;
                if (
                    this.location == "/bosting" ||
                    this.location == "/magazine" ||
                    this.location == "/tournaments" ||
                    this.location == "/streaming"
                ) {
                    this.containerClass = "container";
                } else {
                    this.containerClass = "container-fluid";
                }
            }
        });
    }

    async ngOnInit() {
        await this.getUserDetail();
        // this.userDetails = JSON.parse(
        //     this.service.getStorage("userDetailYala")
        // );
        this.service.userDetail.subscribe((res)=>{
            this.userDetails = res
        })
        // this.orgLogo = this.userDetails.organiserLogo;
        this.widgets = JSON.parse(this.service.getStorage("widgets"));
        this.getPrimaryMenus();
        this.getSecondaryMenus();
    }

    getPrimaryMenus() { }

    async getSecondaryMenus() {
        this.userId = localStorage.getItem("userId");
        let response = await this.service.getApi(`configuration_domain/getAllSectionWithoutPagination?userId=${this.userId}`, 0).toPromise()
        if (response.responseCode == 200) {
            this.sectionsList = response.success;
            console.log("this.sectionsList : ", this.sectionsList);
        }

    }
    userId: any;
    userDet: any;
    async getUserDetail() {
        this.userId = localStorage.getItem("userId");
        // alert(this.userId)
        let response = await this.service.getApi(`users/getUserDetailWithoutToken?_id=${this.userId}`, 0).toPromise()
        // localStorage.setItem(
        //     "userDetailYala",
        //     JSON.stringify(response.result)
        // );
        this.service.userDetail.next(response.result)
        console.log(response.result)
        this.userDet = response.result;
        this.orgLogo = this.userDet.organiserLogo;
        console.log(this.orgLogo);
      



        this.service.getApi(`configuration_domain/getWidgets?userId=${this.userId}`, 0).subscribe((response) => {
            localStorage.setItem(
                "widgets",
                JSON.stringify(response.result[0])
            );
            this.widgets = response.result[0];
        });

    }
    openMenu(id){
        this.router.navigate(['/section'], { queryParams: { id } })
    }

    openLogin(){
        this.service.loginModal.next(true)
        
    }
    logoutfromTemplate(){
        localStorage.removeItem('loginData')
        localStorage.removeItem('currentId')
        localStorage.removeItem('userId')
        localStorage.removeItem('userVisit')
        localStorage.removeItem('token')
        this.router.navigate(['/'])
    }
}
