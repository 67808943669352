<div class="table-responsive">
    <table class="table table-bordered">
        <thead>
            <tr class="table-head-bg">
                <th>Team</th>
                <th>Final Score</th>
                <th>Team Fouls</th>
                <th>Offside</th>
                <th>Corner Kicks</th>
                <th>Goalkeeper Saves</th>
                <th>MVP</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>{{ soccerGameDetailsData?.result?.teamDeatailsOne[0].teamDetails[0].teamName}}</td>
                <td>{{ soccerGameDetailsData?.result?.teamDeatailsOne[0].finalScore }}</td>
                <td>{{ soccerGameDetailsData?.result?.teamDeatailsOne[0].teamFoul }}</td>
                <td>{{ soccerGameDetailsData?.result?.teamDeatailsOne[0].offSide }}</td>
                <td>{{ soccerGameDetailsData?.result?.teamDeatailsOne[0].cornerkicks }}</td>
                <td>{{ soccerGameDetailsData?.result?.teamDeatailsOne[0].goalKeeperSaves}}</td>
                <td *ngIf="!soccerGameDetailsData?.result?.teamDeatailsOne[0].mvp">MVP Player </td>
                <td *ngIf="soccerGameDetailsData?.result?.teamDeatailsOne[0].mvp">
                    {{soccerGameDetailsData?.result?.teamDeatailsOne[0].mvp}}</td>
            </tr>
            <tr>
                <td>{{ soccerGameDetailsData?.result?.teamDeatailsTwo[0].teamDetails[0].teamName }}</td>
                <td>{{ soccerGameDetailsData?.result?.teamDeatailsTwo[0].finalScore }}</td>
                <td>{{ soccerGameDetailsData?.result?.teamDeatailsTwo[0].teamFoul }}</td>
                <td>{{ soccerGameDetailsData?.result?.teamDeatailsTwo[0].offSide }}</td>
                <td>{{ soccerGameDetailsData?.result?.teamDeatailsTwo[0].cornerkicks }}</td>
                <td>{{ soccerGameDetailsData?.result?.teamDeatailsTwo[0].goalKeeperSaves}}</td>
                <td *ngIf="!soccerGameDetailsData?.result?.teamDeatailsTwo[0].mvp">MVP Player</td>
                <td *ngIf="soccerGameDetailsData?.result?.teamDeatailsTwo[0].mvp">
                    {{soccerGameDetailsData?.result?.teamDeatailsTwo[0].mvp }}</td>
            </tr>
        </tbody>
    </table>
</div>


<div class="table-responsive mt-5">
    <table class="table table-bordered">
        <thead>
            <tr class="table-head-bg">
               <th colspan="7" class="custom-top-heading text-align-left">{{ soccerGameDetailsData?.result?.teamDeatailsOne[0].teamDetails[0].teamName }}</th>
            </tr>
            <tr class="table-head-bg">
               <th>Player No.</th>
               <th>Player First Name</th>
               <th>Player Last Name</th>
               <th>Goals</th>
               <th>Shots</th>
               <th>Yellow Cards</th>
               <th>Red Cards</th>
            </tr>
         </thead>
         <tbody>
            <tr *ngFor = "let data of soccerGameDetailsData?.result?.teamOne">
               <td>{{ data?.playerNumber }}</td>
               <td>{{ data?.userDetails[0]?.firstName }}</td>
               <td>{{ data?.userDetails[0]?.lastName }}</td>
               <td>{{ data?.goals }}</td>
               <td>{{ data?.shots }}</td>
               <td>{{ data?.yellowCards }}</td>
               <td>{{ data?.redCards }}</td>
            </tr> 
         </tbody>
    </table>
</div>



<div class="table-responsive mt-5">
    <table class="table table-bordered">
        <thead>
            <tr class="table-head-bg">
               <th colspan="7" class="custom-top-heading text-align-left">{{ soccerGameDetailsData?.result?.teamDeatailsTwo[0].teamDetails[0].teamName }}</th>
            </tr>
            <tr class="table-head-bg">
               <th>Player No.</th>
               <th>Player First Name</th>
               <th>Player Last Name</th>
               <th>Goals</th>
               <th>Shots</th>
               <th>Yellow Cards</th>
               <th>Red Cards</th>
            </tr>
         </thead>
         <tbody>
            <tr *ngFor = "let data of soccerGameDetailsData?.result?.teamTwo">
               <td>{{ data?.playerNumber }}</td>
               <td>{{ data?.userDetails[0]?.firstName }}</td>
               <td>{{ data?.userDetails[0]?.lastName}}</td>
               <td>{{ data?.goals }}</td>
               <td>{{ data?.shots }}</td>
               <td>{{ data?.yellowCards }}</td>
               <td>{{ data?.redCards }}</td>
            </tr>
         </tbody>
    </table>
</div>