import { Component, OnInit } from '@angular/core';
import { MainService } from '../../../../providers/main-service.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';


@Component({
  selector: 'app-esports-main-banner',
  templateUrl: './esports-main-banner.component.html',
  styleUrls: ['./esports-main-banner.component.scss']
})
export class EsportsMainBannerComponent implements OnInit {
  sliderArr: any =[];
  userId: any;

  constructor(private router: Router, private service:MainService) { }
  

  ngOnInit(): void {

    this.userId = this.service.getStorage('userId');

    this.service.getApi(`configuration_domain/getSlider?userId=${this.userId}`, 0).subscribe(response => {
      if (response.responseCode == 200) {
        
        console.log("getSliderAPI===>>>>>", response)
        this.sliderArr = response.result[0].slider;
        console.log("sliderArr===>>>>", this.sliderArr)  
  }
    });
  }

}
