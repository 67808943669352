import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { MainService } from './providers/main-service.service';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;
    newurl: any;

    constructor(private router: Router,public service : MainService) {
        this.newurl = window.location.host
        // this.newurl ='http://www.yalasportsevents.com//'
        // this.newurl ='www.yalasportsevents.com'
        let local =window.location.href
        if(local.includes('172.16.6') || local.includes('localhost')){
            this.newurl ='admin.somya.com'
   
        }else{
            this.newurl = window.location.host
        }
        let reqbody = {
            url: this.newurl,
        };
        this.getStyle()
    }

    ngOnInit(){

        this.recallJsFuntions();
        console.log("Sachin")
        // this.temp()
        this.service.loginModal.subscribe((res)=>{
            if(res){
                this.temp()
            }
            else{
               this.navigate()
            }
        })
       
    }
    //
    getStyle(){
      
     
        this.service.getApi(`legendTemplate/getLegendTemplate?domain=${this.newurl}`, 0).subscribe(response => {
          if(response.responseCode == 200) {
            console.log(response);
            if(response.result){
                var body = document.body;

                // Set the style properties
                body.style.backgroundColor = response.result.backgroundColour; // Change the background color
                body.style.fontFamily = response.result.fontFamily; // Change the font family
            }
            
            this.service.toastrSucc(response.responseMessage)
           
          } else {
            this.service.toastrErr(response.responseMessage)
          }
        })
     
      }
    temp(){
        if(!localStorage.getItem('token')){
            $('#exampleModalCenter').modal({backdrop: 'static'})
          }
        
    }
    navigate(){
        $('#exampleModalCenter').modal('hide')
      
     }
     
    recallJsFuntions() {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationStart ) {
                $('.preloader').fadeIn('slow');
            }
        });
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/main.js');
            $('.preloader').fadeOut('slow');
            this.location = this.router.url.substring(1);
            if(routersUrl.includes(this.location)){
                this.service.loginModal.next(true)
            }
            else{
                this.service.loginModal.next(false)
            }
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }
}
const routersUrl = ['tournaments','fixtures','membership-list','service-list','single-tournament','venue-list','evalution','leaderboard']