import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
// import { MainService } from '../../../../providers/mainService.service';
import { MainService } from 'src/app/providers/main-service.service';

import { IMyDpOptions } from 'mydatepicker';
declare var $;

@Component({
  selector: 'app-evaluation',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.css']
})
export class EvaluationComponent1 implements OnInit {
  filterForm: FormGroup;
  userid: any;
  configurePlayerArr : any= [{field:"",fieldType:"text", importance:"optional"}];
  memberList: any=[];
  membershipname: any;
  membershipId: any;
  servicelist: any=[];
  serviceMonth:any =[];
  servicename: any;
  serviceId: any;
  show:boolean = false;
  disable: boolean = true;
  evaluationList: any=[];
   serviceYear: any=[2100, 2099,2098,2097,2096,2095,2094,2093,2092,2091,
2090,2089,2088,2087,2086,2085,2084,2083,2082,2081,
2080,2079,2078,2077,2076,2075,2074,2073,2072,2071,
2070,2069,2068,2067,2066,2065,2064,2063,2062,2061,
2060,2059,2058,2057,2056,2055,2054,2053,2052,2051,
2050,2049,2048,2047,2046,2045,2044,2043,2042,2041,
2040,2039,2038,2037,2036,2035,2034,2033,2032,2031,
2030,2029,2028,2027,2026,2025,2024,2023,2022,2021,
2020,2019,2018,2017,2016,2015,2014,2013,2012,2011,
2010,2009,2008,2007,2006,2005,2004,2003,2002,2001]
  searchYear: any;
  searchMonth: any;
  playerId: any;
  evaluate: any = {};
  configurePlayerList: any = [];
  evaluateForm: FormGroup;
  constructor(public service: MainService) { }

  ngOnInit() {
    this.userid = JSON.parse(localStorage.getItem('userDetailYala') );
    console.log("User--> ",this.userid)
    console.log("User Id--> ",this.userid._id);
    this.formValidation();
    this.membershipListApi();
    this.serviceMonth =[
    {name:'January',val:'01'},
    {name:'February',val:'02'},
    {name:'March',val:'03'},
    {name:'April',val:'04'},
    {name:'May',val:'05'}, 
    {name:'June',val:'06'},
    {name:'July',val:'07'}, 
    {name:'August',val:'08'},
    {name:'October',val:'09'},
    {name:'September',val:'10'},
    {name:'November',val:'11'},
    {name:'December',val:'12'}
    ]
    this.getReverseData()
  }
  getReverseData(){
    this.serviceYear.reverse()
  }
formValidation(){
  this.filterForm = new FormGroup({
    'membershipName':new FormControl('',Validators.required),
    'serviceName':new FormControl('',Validators.required),
    'playerName':new FormControl('',Validators.required),
    serviceDate:new FormControl('',Validators.required),
    'selectYear': new FormControl('',[Validators.required]),
    
  });
  this.evaluateForm = new FormGroup({
    'bad':new FormControl('',[Validators.pattern(/^(?=.*[1-9])\d+(\.\d{1,50})?$/)]),
    'pass':new FormControl('',[Validators.pattern(/^(?=.*[1-9])\d+(\.\d{1,50})?$/)]),
    'shooting':new FormControl('',[Validators.pattern(/^(?=.*[1-9])\d+(\.\d{1,50})?$/)]),
    'strenght':new FormControl('',[Validators.pattern(/^(?=.*[1-9])\d+(\.\d{1,50})?$/)]),
    'speed':new FormControl('',[Validators.pattern(/^(?=.*[1-9])\d+(\.\d{1,50})?$/)]),
    'flexibility':new FormControl('',[Validators.pattern(/^(?=.*[1-9])\d+(\.\d{1,50})?$/)]),
    'decision':new FormControl('',[Validators.pattern(/^(?=.*[1-9])\d+(\.\d{1,50})?$/)]),
    'offensive':new FormControl('',[Validators.pattern(/^(?=.*[1-9])\d+(\.\d{1,50})?$/)]),
    'concentration':new FormControl('',[Validators.pattern(/^(?=.*[1-9])\d+(\.\d{1,50})?$/)]),
    'competitivenedd':new FormControl('',[Validators.pattern(/^(?=.*[1-9])\d+(\.\d{1,50})?$/)]),
    'selfConfidence':new FormControl('',[Validators.pattern(/^(?=.*[1-9])\d+(\.\d{1,50})?$/)]),
    'avg':new FormControl('',[Validators.pattern(/^(?=.*[1-9])\d+(\.\d{1,50})?$/)])
  })
}
/*********** Get MemberShip List Api *********/
membershipListApi(){
  var url = `membership/selectMembership?organizerId=`+this.userid._id;
  this.service.getApi(url,1).subscribe(response => {
   if(response.responseCode == 200) {
   // console.log(JSON.stringify(response));
    this.memberList = response.result;
    console.log("Memember list--> ",this.memberList);
   } else if(response.responseCode == 402) {
     
   }
 });
}
/*********************** Get MembershipID ****************/
getMembershipId(event){
  console.log("MememberName-->  ",event.target.value);
 this.membershipname = event.target.value;
  for(var i= 0;i<this.memberList.length;i++){
    console.log("MememberName-->  ",this.membershipname);
    if(this.memberList[i].membershipName == this.membershipname){
      this.membershipId = this.memberList[i]._id;
      this.getServiceList(); //

      console.log("Membership Id--> ",this.membershipId);
      var url ="membership/getAMembership?organizerId="+this.userid._id+"&membershipId="+this.membershipId;
      this.service.getApi(url,1).subscribe(response => {
  console.log("Form Fields-----> ",JSON.stringify(response));
        if(response.responseCode == 200) {
          this.configurePlayerList = response.result.dynamicFormField;
        }
      })
    }
  }
}
/************************** Get List Of Service of Particular Membership *****************/
getServiceList(){
  var url = `membership/selectService?organizerId=`+this.userid._id+`&membershipId=`+this.membershipId;
  this.service.getApi(url,1).subscribe(response => {
   if(response.responseCode == 200) {
    console.log(JSON.stringify(response));
    this.servicelist = response.result;
    console.log("member list--> ",this.servicelist);
   } else if(response.responseCode == 402) {
     
   }
 });
}
/************************* Get Service Id *****************/
dynamicFormField:any
getServiceId(event){
  console.log("MememberName-->  ",event.target.value);
this.servicename = event.target.value;
  for(var i= 0;i<this.servicelist.length;i++){
    console.log("MememberName-->  ",this.servicename);
    if(this.servicelist[i].serviceName == this.servicename){
      this.serviceId = this.servicelist[i]._id;
      console.log("Membership Id--> ",this.serviceId);
    }
  }
  var url = `membership/getAService?organizerId=`+this.userid._id+`&serviceId=`+this.serviceId;
  this.service.getApi(url,1).subscribe(response => {
   if(response.responseCode == 200) {
     if(response.result.dynamicFormField){
       response.result.dynamicFormField.forEach((resp,index)=>{
         if(resp){
           resp['value']=''
         }
     this.dynamicFormField =response.result.dynamicFormField

       })
     }
    console.log(JSON.stringify(response));
    this.disable = false;
    console.log("Response---> ",response.result);
      this.onDateChange(response.result.startDate,response.result.endDate);   
   } else if(response.responseCode == 402) {
     
   }
 });
}
/******************Date Validation  between the given Dates**************/
public myDatePickerOptions: IMyDpOptions = { 
  dateFormat: 'yyyy-mm-dd', 
  editableDateField:false, 
  openSelectorOnInputClick:false,
  disableUntil:{year: 0, month: 0, day: 0},
  disableSince: {year: 0, month: 0, day: 0}
  };
  onDateChange(startdate,enddate) {
    let startD = new Date(startdate);
    let endD = new Date(enddate)
    startD.setDate(startD.getDate() -1 );
    let copy1 = this.getCopyOfOptions();
    copy1.disableUntil = {
    year: startD.getFullYear(),
    month: startD.getMonth() + 1,
    day: startD.getDate()
    };
    this.myDatePickerOptions = copy1 
    endD.setDate(endD.getDate() +1);
    let copy2 = this.getCopyOfOptions();
    copy2.disableSince = {
    year: endD.getFullYear(),
    month: endD.getMonth() + 1,
    day: endD.getDate()
    };
    this.myDatePickerOptions = copy2
    }
 //Returns copy of myDatePickerOptions
getCopyOfOptions(): IMyDpOptions {
  return JSON.parse(JSON.stringify(this.myDatePickerOptions));
  }
 /****************************Get Player List **************/
 returnMonth(m){
   if(m==='11'){
   return  JSON.parse(m)
  }
   if(m==='12'){
   return  JSON.parse(m)
  }
   if(m==='10'){
   return  JSON.parse(m)
  }
  else{
     let n = m.split('')
    return  JSON.parse(n[1])
  }
 }
 onChangeyear(event){
    this.searchYear =   event.target.value;
   this.onChangeDate()

 }
 onChangeMonth(event){
   this.searchMonth =  event.target.value;
   this.onChangeDate()
 }
 onChangeDate(){
   this.show = true;
   if(this.searchMonth &&this.searchYear ){
  var date =  this.searchYear+'-'+this.searchMonth+'-'+'01'+'T00:00:00.000'
    var apiDoc = {
      "date":date,
      "membershipId":this.membershipId,
      "serviceId":this.serviceId,
      "month": (this.returnMonth(this.searchMonth)-1).toString(),
      "bookingId":this.serviceId,
    }
   console.log("Api Doc===>>  ",apiDoc); 
   this.service.postApi(`membership/getListOfPlayersLeaderboard`,apiDoc,1).subscribe(response => {
    if(response.responseCode == 200) {
      console.log(JSON.stringify(response));
      this.evaluationList = response.result;
      this.dynamicFormField =this.evaluationList.dynamicFormField
    //   var evaluation = response.result
    // //this.leaderboardList = this.currentList.filter(x => x.leaderBoard.hasOwnProperty(this.currentYear))
    // this.evaluationList = evaluation.filter(x => x.evaluation.hasOwnProperty(this.searchYear))
    // console.log("Leader Array----> ",this.evaluationList);
    } else{
      
    }
  });
 }
 }
 getPlayerId(player){
   console.log("Id--->   Player--->",player.target.value);
   var playerName = player.target.value
   for(var i= 0;i<this.evaluationList.length;i++){
    console.log("PlayerName-->  ",playerName);
    if(this.evaluationList[i].playerId.firstName == playerName){
      this.playerId = this.evaluationList[i]._id;
      console.log("Player Id--> ",this.playerId);
    }
 }
}
getLeaderBoard(){
  console.log("PlayerId---> ",this.playerId);
  var url = `membership/getDetailOfPlayerEvaluation?bookingId=`+this.playerId;
  this.service.getApi(url,1).subscribe(response => {
   if(response.responseCode == 200) {
   console.log(JSON.stringify(response));

    var dataarray = response.result;
    console.log("Value of data--> ",JSON.stringify(dataarray));
    this.evaluate = dataarray.evaluation;
    console.log("Value of a--> ",JSON.stringify(this.evaluate));
    this.changeValue(this.evaluate)
    // this.evaluate = {'year':a[this.searchYear]}
    // console.log("Value of e--> ",JSON.stringify(this.evaluate));
    // var e1 = {'month' : e[this.searchMonth]};
    // console.log('Month',this.searchMonth);
    // console.log("Value of e1--> ",JSON.stringify(e1));
   } 
   else if(response.responseCode == 402) {
     
   }
 });
}
deleteAdded(data,index){
  this.dynamicFormField.splice(index,1)
  this.configDeletedSave()
}
deleteValueAdded(data,index){
  this.dynamicFormField.splice(index,1)
}

 configDeletedSave () {
    let dynamicPlayerArr = [];
      dynamicPlayerArr = this.configurePlayerList  
    
    let dynamicFieldsData = {  
      "membershipId": this.membershipId,
      "dynamicFormField": dynamicPlayerArr          
    } 
      this.service.postApi(`membership/dynamicFormField`, dynamicFieldsData,1).subscribe(response => {
      if(response.responseCode == 200) {
        this.service.toastrSucc(response.responseMessage)
       
       this.getplayerFields();
      } else {
        this.service.toastrErr(response.responseMessage)
      }
    })
    //******************* End ******************//
  }
changeValue(val) {
  var data = this.evaluateForm.value;
  var dateData = [this.searchYear , this.searchMonth];
  for (var i in val) {
  var x = val[i];
  for (var y in x) {
  if (dateData[0] == i && dateData[1] == y) {
  this.evaluateForm.patchValue({
  bad: x[y].bad,
  pass: x[y].pass,
  shooting: x[y].shooting,
  strenght: x[y].strenght,
  speed: x[y].speed,
  flexibility: x[y].flexibility,
  decision: x[y].decision,
  offensive: x[y].offensive,
  concentration: x[y].concentration,
  competitivenedd: x[y].competitivenedd,
  selfConfidence: x[y].selfConfidence,
  avg:x[y].avg,
 
  });
   this.dynamicFormField=x[y].dynamicFormField
  }
  }
  }
  }
  /********************* Update Functionality ******************/
  setvalue(data,index,event){
    console.log(data,index,event,'live')
    // data.value = event.target.value
    this.dynamicFormField.forEach((ips,inx)=>{
      if(ips){
        if(index==inx){
          ips.value =event.target.value
        }
      }
    })

  }


  update(formval){
    console.log("Form Value---> ",formval);
    var apiDoc = {
    "bookingId":this.playerId,
    "year":this.searchYear,
    "month":this.searchMonth,
    "bad":Number(formval.bad),
    "pass":Number(formval.pass),
    "shooting":Number(formval.shooting),
    "strenght":Number(formval.strenght),
    "speed":Number(formval.speed),
    "flexibility":Number(formval.flexibility),
    "decision":Number(formval.decision),
    "offensive":Number(formval.offensive),
    "concentration":Number(formval.concentration),
    "competitivenedd":Number(formval.competitivenedd),
    "selfConfidence":Number(formval.selfConfidence),
    "avg":Number(formval.avg),
    "dynamicFormField":this.dynamicFormField
    }
   console.log("Api Doc---> ",JSON.stringify(apiDoc)); 
   this.service.postApi(`membership/setEvaluation`,apiDoc,1).subscribe(response => {
    if(response.responseCode == 200) {
      console.log(JSON.stringify(response));
      this.service.toastrSucc('saved')
      
          } 
          else{
            console.log(JSON.stringify(response));
            this.service.toastrErr(response['responseMessage'])
    }
  });
  }

  addOtherPlayerField(){
    var dataFills = false;
    for(var i=0; i<this.configurePlayerArr.length; i++){
        if(this.configurePlayerArr[i].field=="" ){
            this.service.toastrErr('Please enter  Field.')
            dataFills = false;
            break;
        }else{ 
          dataFills = true;
        }
    }      
    if(dataFills == true){
        this.configurePlayerArr.push({field:"",fieldType:"",importance:"optional"});
    }        
  }

// ghjghjgjj
  /*************************************************/
  saveConfigPlayerFields (type) {
    let dynamicPlayerArr = [];

    if (type == "modal") {
      console.log("Check1 ===>> ",this.configurePlayerList);
      dynamicPlayerArr = this.configurePlayerArr.concat(this.configurePlayerList) 	
    } else {
      dynamicPlayerArr = this.configurePlayerList	
    }
    console.log("Check2 ===>> ",dynamicPlayerArr);
    //************** Configure Dynamic Player fields fields Api Integration *************//
    let dynamicFieldsData = {	
      "membershipId": this.membershipId,
      "dynamicFormField": dynamicPlayerArr					
    } 
  console.log("Dynamic Field Data ===>> ",JSON.stringify(dynamicFieldsData));
      this.service.postApi(`membership/dynamicFormField`, dynamicFieldsData,1).subscribe(response => {
      if(response.responseCode == 200) {
        this.service.toastrSucc(response.responseMessage)
        $('#add_player').modal('hide');
        if (type == "modal") {
          this.configurePlayerArr = [{field:"",fieldType:"",importance:"optional"}];
        }
       this.getplayerFields();
      } else {
        this.service.toastrErr(response.responseMessage)
      }
    })
    //******************* End ******************//
  }

  getplayerFields () {
   var url ="membership/getAMembership?organizerId="+this.userid._id+"&membershipId="+this.membershipId;
    this.service.getApi(url,1).subscribe(response => {
     console.log("Form Fields-----> ",JSON.stringify(response));
      if(response.responseCode == 200) {
        this.configurePlayerList = response.result.dynamicFormField;
      }
    })
  }
}
