<app-navbar-style-one></app-navbar-style-one>

<section class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <h1 title="Listed Venues">Listed Venues</h1>
        </div>
    </div>
</section>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box">
                    <div class="post-image">
                        <a routerLink="/venue-detail">
                            <img src="assets/img/main-blog-img1.jpg" alt="image">
                        </a>
                    </div>

                    <div class="post-content">
                        <ul class="post-meta">
                            <li>Melbourne</li>
                            <li>Soccer</li>
                        </ul>
                        <h3><a routerLink="/venue-detail">Melbourne ground</a></h3>
                        <a routerLink="/cart" class="read-more-btn">Book Now<i class="flaticon-null"></i></a>
                    </div>
                </div>
            </div>

           
        </div>
    </div>
</section>

<footer class="footer-area bg-1b060a ptb-70">
    <app-footer-style-one></app-footer-style-one>
</footer>