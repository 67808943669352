import { Component, OnInit } from '@angular/core';
import { MainService } from '../../../providers/main-service.service';

@Component({
  selector: 'app-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.scss']
})
export class SocialComponent implements OnInit {

  constructor(private service: MainService) { }

  userId: any;
  fbEnabled: boolean;
  fbUrl: String;
  igEnabled: boolean;
  igUrl: String;
  googleAnalyticsEnabled: boolean;
  googleAnalyticsUrl: String;
  twitterEnable: boolean;
  twitterUrl: String;
  sociIT:any=[];

  ngOnInit(): void {
    this.userId = this.service.getStorage('userId');
    this.service.getApi(`configuration_domain/getSocialWork?userId=${this.userId}`,0).subscribe(response => {
      console.log('socail links :: ',response);
      this.sociIT=response.getData;
      for(let i=0;i<this.sociIT.length;i++){
        switch(this.sociIT[i].socialName){
          case "FACEBOOK":
            this.fbEnabled = this.sociIT[i].status;
            this.fbUrl = this.sociIT[i].link;
            break;
          case "INSTAGRAM":
            this.igEnabled = this.sociIT[i].status;
            this.igUrl = this.sociIT[i].link;
            break;
          case "GOOGLEANALYTICS":
            this.googleAnalyticsEnabled = this.sociIT[i].status;
            this.googleAnalyticsUrl = this.sociIT[i].link;
            break;    
          case "TWITTER":
            this.twitterEnable = this.sociIT[i].status;
            this.twitterUrl = this.sociIT[i].link;
            break;  
        }
      }
      console.log('igEnabled',this.igUrl);
    });
  }

}
